const storage = window.localStorage;

function set(key, value) {
  storage.setItem(key, value);
}

function remove(key) {
  storage.removeItem(key);
}

function get(key) {
  return storage.getItem(key);
}

function clear() {
  storage.clear();
}

export default {
  clear,
  set,
  get,
  remove
};

/** Specific API calls */

export const getCampaign = () => get('campaign');

export const setCampaign = value => set('campaign', value);

export const getNewCampaign = () => get('newCampaign') || '';

export const setNewCampaign = value => set('newCampaign', value);
