import React from 'react';
import Theme from '../../../../../components/Theme';
import { themeSecondary } from '../../../../../styles/constants';
import IconTick from "../../ProviderBenefits/icon-tick.svg"

import {
    ProviderTickIcon,
    HCPServiceContentSection,
    ProviderToDos,
    HCPServiceText,
    HCPSupportButtonsSection,
    HCPServiceButtomSection
} from "../../../style";

import {
  Card,
  CardInfoText,
  CallLink,
} from '../../../../../apps/Providers/containers/styles'


 // Shared component for both desktop and mobile versions
const HCPServicesContent = () => (
    <>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Bathing, hygiene and grooming</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Nursing Care</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Podiatry and other therapies</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Meals and food preparation</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Help with impairments or continence</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Domestic assistance</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Home or garden maintenance</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Home modifications</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Aids to stay independent</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Social support</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Transport</b></HCPServiceText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <HCPServiceText>
            <b>Plus many more</b></HCPServiceText>
        </ProviderToDos>
    </>
  );

const SupportButtons = () => {
  return (
    <HCPSupportButtonsSection>
      <Card>
        <CallLink href="/">
          <CardInfoText><b>Search for supports now</b></CardInfoText>
        </CallLink>
      </Card>
    </HCPSupportButtonsSection>
  );
}

const HCPServices = () => {

    return (
        <Theme theme={themeSecondary}>
            <HCPServiceContentSection>
                <HCPServicesContent />
            </HCPServiceContentSection>
            <HCPServiceButtomSection>
              <SupportButtons />
            </HCPServiceButtomSection>
        </Theme>
    );
}   

export default HCPServices;