import React from 'react';
import { bindActionCreators } from 'redux';
import { connect, useSelector } from 'react-redux';
import { createSelector } from 'reselect';
import { RouteComponentProps } from 'react-router-dom';

import * as actions from './actions';
import { AdminActions } from './actions';
import * as selectors from './selectors';
import ComposedRoute from '../../components/ComposedRoute';
import WelcomeProvider, { WelcomeProviderProps } from './containers/welcome-provider';
import EditProvider, { EditProviderProps } from './containers/edit-provider';
import ListProviders from './containers/list-providers';
import ProviderReviews, { ProviderReviewsProps } from './containers/provider-reviews';
import Footer from '../../components/Footer';
import Login, { LoginProps } from './containers/login';
import Navigation from '../../components/Navigation';

import AdminDropdown from './components/AdminDropdown';
import Page from '../../components/Page';
import PreviewProvider, { PreviewProviderProps } from './containers/preview';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';

import { Provider, ProviderList, AuthSlice, Postcode, AuthError, Query } from '../../state/types';
import karistaApi, {
  useGetAuthStateQuery,
  useGetAllAgeGroupsQuery,
  useGetAllFundingTypesQuery,
  useGetOwnedProvidersQuery,
  useLogoutMutation,
} from '../../services/karista';
import { reduceQueries } from '../../util/rtk-query';
import { forceDefined } from '../../util/isNil';
import ResetPasswordConfirm from "./containers/password-reset-confirm";
import ResetPasswordRequest from "./containers/password-reset";
import { RootState } from '../../state';

interface AdminNavigationProps extends RouteComponentProps {
  onLogout: (...args: any[]) => any,
  loggedIn: boolean,
  providerList: Provider[],
};

const AdminNavigation: React.FC<AdminNavigationProps> = props => {
  const currentProvider = useSelector((state: RootState) => state.admin.currentProvider);
if (props.loggedIn && props.providerList.length > 0) {
    // There's no provider set when switching providers, so can be undefined here
    const providerId = currentProvider
      ? currentProvider.id
      : props.providerList[0].id;
    const providerSlug = currentProvider
      ? currentProvider.url_slug
      : props.providerList[0].url_slug;
    const showSwitchProviders = props.providerList.length > 1;

    return (
      <Navigation>
        <AdminDropdown
          {...props}
          providerId={providerId}
          providerSlug={providerSlug}
          showSwitchProviders={showSwitchProviders}
        />
      </Navigation>
    );
  }

  return <Navigation />;
};

const useInitialize = () => reduceQueries({
  authState: useGetAuthStateQuery(),
  ageGroups: useGetAllAgeGroupsQuery(),
  fundingTypes: useGetAllFundingTypesQuery(),
  services: karistaApi.useGetAllServicesQuery(),
  providerList: useGetOwnedProvidersQuery(),
});

interface AdminProps extends RouteComponentProps {
  auth: AuthSlice,
  authError: AuthError | null,
  isAuthenticated: boolean,
  postcodes: Postcode[] | null,
  id?: string,
  query: Query,
  providerList: ProviderList,
  actions: AdminActions,
};

interface InnerAdminProps extends Omit<AdminProps, 'services'> {
}

/* TODO eventually DRY up the apps */
// TODO: There are a couple of casts below that are needed to for components that require a provider.
const Admin: React.FC<InnerAdminProps> = props => {
  const { ageGroups, fundingTypes, services, providerList, isLoading } = useInitialize();
  const [logout] = useLogoutMutation();

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  if (isLoading) {
    // TODO: Previously we used "renderNothing" from Recompose. This should be equivalent. Of course, what we really
    // want is a loading screen.
    return <div />
  }

  const onLogout = (e) => {
    e.preventDefault();
    logout()
      .unwrap()
      .then(() => {
        props.history.push({
          pathname: '/admin/login'
        });
      })
      .catch(() => ({}));
  }

  // TODO: Using `forceDefined` below is needed until we start using
  // React Suspense.
  return (
    <Theme theme={themeSecondary}>
      <AdminNavigation
        {...props}
        providerList={(providerList && providerList.data) ? providerList.data.results : []}
        onLogout={onLogout}
        loggedIn={props.auth.isAuthenticated}
      />
      <Page>
        <ComposedRoute<LoginProps>
          exact
          path="/admin/login"
          component={Login}
          {...props}
        />
        <ComposedRoute<RouteComponentProps>
          exact
          path="/admin/password-reset"
          component={ResetPasswordRequest}
          {...props}
        />
        <ComposedRoute<RouteComponentProps>
          exact
          path="/admin/password-reset-confirm"
          component={ResetPasswordConfirm}
          {...props}
        />
        <ComposedRoute<WelcomeProviderProps>
          exact
          path="/admin/providers/welcome"
          component={WelcomeProvider}
          requiresAuth
          {...props}
        />
        <ComposedRoute<WelcomeProviderProps>
          exact
          path="/admin/providers/:slug/:id/welcome"
          component={WelcomeProvider}
          requiresAuth
          {...props}
        />
        <ComposedRoute<EditProviderProps>
          exact
          path="/admin/providers/:slug/:id/edit"
          component={EditProvider}
          requiresAuth
          ageGroups={forceDefined(ageGroups.data)}
          fundingTypes={forceDefined(fundingTypes.data)}
          services={forceDefined(services.data)}
          {...props}
        />
        <ComposedRoute<ProviderReviewsProps>
          exact
          path="/admin/providers/:slug/:id/reviews"
          component={ProviderReviews}
          requiresAuth
          {...props}
        />
        <ComposedRoute<PreviewProviderProps>
          exact
          path="/admin/providers/:slug/:id/preview"
          component={PreviewProvider}
          {...props}
          services={forceDefined(services.data)}
          fundingTypes={forceDefined(fundingTypes.data)}
        />
        <ComposedRoute<RouteComponentProps>
          exact
          path="/admin/providers/"
          component={ListProviders}
          requiresAuth
          {...props}
        />
      </Page>
      <Footer />
    </Theme>
  );
};

const mapStateToProps = createSelector(
  selectors.selectAuth,
  selectors.selectAuthError,
  selectors.selectIsAuthenticated,
  selectors.selectPostcodes,
  selectors.selectQuery,
  (
    auth,
    authError,
    isAuthenticated,
    postcodes,
    query,
  ) => ({
    auth,
    authError,
    isAuthenticated,
    postcodes,
    query,
  })
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Admin);
