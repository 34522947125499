import styled from 'styled-components';
import { colors } from '../../styles/constants';
import calculateRem from '../../styles/calculateRem';

interface StyledLabelProps {
  emphasis?: boolean,
}

export default styled.label<StyledLabelProps>`
  margin-bottom: ${calculateRem(6)};
  font-weight: ${props => (props.emphasis ? 500 : 300)};
  color: ${props => props.emphasis ? `${colors.darkPurple}` : ''};
  ${props => ({ ...props.theme })};
  background-color: transparent;
`;
