import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { xor, merge, union } from 'lodash';
import {ProviderId} from "../../state";

export type ProviderSearchOrder = "by_score" | "highly_reviewed";

export interface ProviderSearchParams {
  query?: string,
  page?: number,
  order?: ProviderSearchOrder,
  filters: {
    service_areas_list?: string,
    funding_types_list?: string,
    postcodes_list?: string,
    age_groups_list?: string,
    is_subscribed_for_onboarding?: string,
  },
  providersToQuote: ProviderId[],
}

export interface SetProviderSearchParams {
  query?: string,
  filters?: {
    service_areas_list?: string,
    funding_types_list?: string,
    postcodes_list?: string,
    age_groups_list?: string,
    is_subscribed_for_onboarding?: string,
  },
}

export interface ProvidersState {
  loading: boolean,
  providerSearchParams: ProviderSearchParams,
};

const initialState: ProvidersState = {
  loading: false,
  providerSearchParams: {
    filters: {},
    providersToQuote: [],
  },
};

export const providersSlice = createSlice({
  name: 'providers',
  initialState,
  reducers: {
    destroy: (state) => ({ ...initialState }),
    resetProviderSearchParams: (state) => {
      state.providerSearchParams = { ...initialState.providerSearchParams };
    },
    setProviderSearchParams: (state, action: PayloadAction<SetProviderSearchParams>) => {
      merge(state.providerSearchParams, action.payload);
    },
    clearProvidersToQuote: (state) => {
      state.providerSearchParams.providersToQuote = [];
    },
    setProvidersToQuote: (state, action: PayloadAction<ProviderId[]>) => {
      state.providerSearchParams.providersToQuote = action.payload || [];
    },
    mergeProvidersToQuote: (state, action: PayloadAction<ProviderId[]>) => {
      state.providerSearchParams.providersToQuote = union(
        state.providerSearchParams.providersToQuote,
        action.payload || [],
      );
    },
    toggleProviderToQuote: (state, action: PayloadAction<ProviderId>) => {
      state.providerSearchParams.providersToQuote = xor(state.providerSearchParams.providersToQuote, [action.payload]);
    },
    setProviderSearchOrder: (state, action: PayloadAction<ProviderSearchOrder>) => {
      state.providerSearchParams.order = action.payload;
    },
    setProviderSearchPage: (state, action: PayloadAction<number>) => {
      state.providerSearchParams.page = action.payload;
    },
    setLoading: (state, action) => {
      state.loading = action.payload;
    },
  },
});

export const {
  destroy,
  resetProviderSearchParams,
  setProviderSearchParams,
  clearProvidersToQuote,
  setProvidersToQuote,
  mergeProvidersToQuote,
  toggleProviderToQuote,
  setProviderSearchOrder,
  setProviderSearchPage,
  setLoading,
} = providersSlice.actions;

export type ProvidersActions = typeof providersSlice.actions;

export default providersSlice.reducer
