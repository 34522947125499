import React from 'react';

import styled from 'styled-components';

import Breakpoint from '../../../components/Breakpoint';
import { GridItem } from '../../../components/Grid';

const cardStackBreakpoint = '720px';

const FlexedGridItem = styled(GridItem)`
  align-self: stretch;
  margin-top: 2rem;
  margin-bottom: 3rem;
  * {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-content: center;
    align-items: center;
  }
`;
const Image = styled.img`
  width: 100%;
  height: auto;
  margin-bottom: 3rem;
`;

const NDISFundingImage = styled.img`
  width: 330px;
  height: 330px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

const HCPFundingImage = styled.img`
  width: 330px;
  height: 362px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: auto;
    height: auto;
    max-width: 100%;
    max-height: 100%;
  }
`;

interface ImageHideOnMobileProps {
  src: string,
  alt: string,
};

const ImageHideOnMobile: React.FC<ImageHideOnMobileProps> = ({ src, alt }) => (
  <Breakpoint>
    {breakpoint => {
      if (breakpoint === 'mobile') return null;
      return <Image src={src} alt={alt} width="100%" height="auto" />;
    }}
  </Breakpoint>
);

const ImageShowOnMobile: React.FC<ImageHideOnMobileProps> = ({ src, alt }) => (
  <Breakpoint>
    {breakpoint => {
      if (breakpoint === 'mobile') return <NDISFundingImage src={src} alt={alt} width="auto" height="auto" />;
      return <NDISFundingImage src={src} alt={alt} width="auto" height="auto" />;
    }}
  </Breakpoint>
);

const ImageShowOnMobileHCP: React.FC<ImageHideOnMobileProps> = ({ src, alt }) => (
  <Breakpoint>
    {breakpoint => {
      if (breakpoint === 'mobile') return <HCPFundingImage src={src} alt={alt} width="auto" height="auto" />;
      return <HCPFundingImage src={src} alt={alt} width="auto" height="auto" />;
    }}
  </Breakpoint>
);

export { FlexedGridItem, ImageHideOnMobile, ImageShowOnMobile, ImageShowOnMobileHCP };
