/* eslint-disable react/no-danger */
import React from 'react';
import { GammaHeading } from '../Typography';
import StyledLink from '../Link';
import Tag, { FundingTypeTag } from '../Tag';
import { WideLink } from '../ProfileHeader';

import {
  StyledList,
  ButtonsContainer,
  DetailsSection,
  TransparentButton
} from './styles';
import { FundingType, Service } from '../../state/types';

const ProviderContact = ({ phoneNumber = '' }) => (
  <StyledList>
    <li>{phoneNumber || null}</li>
  </StyledList>
);

ProviderContact.defaultProps = {
  phoneNumber: null
};

const ActionButtons = ({ providerId, providerSlug }) => (
  <ButtonsContainer>
    <StyledLink to={`/providers/${providerSlug}/${providerId}/review/`}>
      <TransparentButton>Review this provider</TransparentButton>
    </StyledLink>
    <StyledLink to={`/providers/${providerSlug}/${providerId}/request-quote/`}>
      <WideLink>Connect with the Provider</WideLink>
    </StyledLink>
  </ButtonsContainer>
);

interface ProviderContentProps {
  fundingTypes?: FundingType[],
  phoneNumber?: string | null,
  services: Service[],
  providerId: string | number,
  description: string | null,
  isActive?: boolean,
  providerSlug: string | null,
};

export const ProviderContent: React.FC<ProviderContentProps> = ({
  fundingTypes,
  phoneNumber,
  services,
  providerId,
  description,
  isActive,
  providerSlug
}) => (
  <div>
    {phoneNumber && <ProviderContact phoneNumber={phoneNumber} />}
    {isActive && <ActionButtons providerId={providerId as string} providerSlug={providerSlug} />}
    {description && (
      <DetailsSection>
        <GammaHeading>About </GammaHeading>
        <div dangerouslySetInnerHTML={{ __html: description }} />
      </DetailsSection>
    )}
    {fundingTypes && fundingTypes.length ? (
      <DetailsSection>
        <GammaHeading>Funding types</GammaHeading>
        {fundingTypes
          .filter(fundingType => fundingType.hcp_allowed === false)
          .map(filteredFundingType => (
            <FundingTypeTag
              label={filteredFundingType.name}
            />
          ))
        }
      </DetailsSection>
    ) : (
      ''
    )}
    {services && services.length ? (
      <DetailsSection>
        <GammaHeading>Services</GammaHeading>
        {services.map(
          s =>
            s.name ? <Tag key={`service-${s.name}`} label={s.name} /> : null
        )}
      </DetailsSection>
    ) : (
      ''
    )}
  </div>
);

ProviderContent.defaultProps = {
  services: [],
  fundingTypes: [],
  phoneNumber: null,
  description: null,
  isActive: true
};

export default ProviderContent;
