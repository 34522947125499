import styled from 'styled-components';
import { colors } from '../../../../styles/constants';

export const LoginHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const LoginContainer = styled.div`
  color: ${colors.darkPurple};
  max-width: 480px;
  margin: 0 auto;
  padding: 0 24px;
`;

export const CenterText = styled.div`
  text-align: center;
`;
