/* eslint-disable no-restricted-syntax */

import React from 'react';
import styled from 'styled-components';
import 'babel-polyfill';

import Pagination from '../../../components/Pagination';
import Provider from '../components/Provider';
import Section from '../../../components/Section';
import calculateRem from '../../../styles/calculateRem';
import { BetaHeading } from '../../../components/Typography';
import Hero from '../../../components/Hero';

import { RouteComponentProps } from 'react-router';
import { useGetOwnedProvidersQuery } from '../../../services/karista';
import {Waiting} from "./waiting";

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

const ListProviders: React.FC<RouteComponentProps> = props => {
  const {
    history,
    location: { search },
    match: { url },
  } = props;

  const page = parseInt((new URLSearchParams(search)).get('page') || '1');
  const { data, isLoading } = useGetOwnedProvidersQuery({ page, pageSize: 10 });
  const { results = [] } = data || {};

  function handleClick(page: number) {
    history.push({
      pathname: url,
      search: `?page=${page}`,
    });
  }

  if (isLoading) {
    return <Waiting />;
  }

  return (
    <div>
      <Hero>
        <BetaHeading>List all profile pages</BetaHeading>
      </Hero>
      <StyledSection>
        {results.map(elem => (
          <Provider key={elem.id} providerId={elem.id} {...elem} {...props} />
        ))}
        {data && data.totalPages > 1 && (
          <Pagination current={page} {...data} onClick={handleClick} />
        )}
      </StyledSection>
    </div>
  );
}

export default ListProviders;
