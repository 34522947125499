import React from 'react';
import styled from 'styled-components';

import { colors } from '../../styles/constants';
import calculateRem from '../../styles/calculateRem';

const Li = styled.li`
  display: inline-block;
  list-style-type: none;
  margin-top: ${calculateRem(24)};
  margin-bottom: ${calculateRem(6)};
`;

interface ButtonProps {
  selected?: boolean,
};

const Button = styled.button<ButtonProps>`
  background: ${({ selected }) => (selected ? colors.orange : colors.white)};
  border: 1px solid ${({ selected }) =>
    selected ? colors.orange : colors.darkPurple};
  border-radius: ${calculateRem(3)};
  color: ${colors.darkPurple}
  cursor: ${({ disabled }) => (disabled ? 'initial' : 'pointer')};
  font-size: ${calculateRem(18)};
  height: ${calculateRem(48)};
  line-height: ${calculateRem(24)};
  margin-left: ${calculateRem(3)};
  margin-right: ${calculateRem(3)};
  width: ${calculateRem(48)};
  opacity: ${({ disabled }) => (disabled ? 0.3 : 1)};
`;

const PaginationLink = ({ disabled, children, onClick, selected, page }) => (
  <Li>
    <Button
      disabled={disabled}
      onClick={() => onClick(page)}
      selected={selected}
    >
      {children}
    </Button>
  </Li>
);

PaginationLink.defaultProps = {
  disabled: false,
  selected: false
};

export default PaginationLink;
