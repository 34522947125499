import React from 'react';

import { Link } from 'react-router-dom';
import styled from 'styled-components';

import { colors } from '../../styles/constants';
import mediaQueries from '../../styles/mediaQueries';

const ColorInheritingLink = styled(Link)`
  color: inherit;
`;

const TabContainer = styled.div`
  ${props => ({ ...props.theme })};
  display: inline-flex;
  padding-left: 2rem;
  padding-right: 2rem;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  border-top-left-radius: 6px;
  border-top-right-radius: 6px;
  margin-left: 6px;
  white-space: nowrap;
  ${mediaQueries.mobile`
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  `};
`;

const InactiveTabContainer = styled(TabContainer)`
  background-color: ${colors.midLightPurple};
  color: ${colors.white};
`;

const ActiveTabContainer = styled(TabContainer)`
  background-color: ${colors.white};
  color: ${colors.midLightPurple};
`;

export const TabContents = ColorInheritingLink;

export const ActiveTab = props => (
  <ActiveTabContainer>
    <TabContents {...props} />
  </ActiveTabContainer>
);

export const InactiveTab = props => (
  <InactiveTabContainer>
    <TabContents {...props} />
  </InactiveTabContainer>
);
