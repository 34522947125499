import React from 'react';
import styled from 'styled-components';

import {
  StyledButton,
  LinkButton,
  orange,
  primary,
  transparent,
  transparentWhite
} from './styles';

const Button = ({
  disabled = false,
  spinning = false,
  fullWidth = false,
  shrink = false,
  strong = false,
  onClick = () => {},
  ...rest
}) => (
  <StyledButton
    disabled={disabled}
    spinning={spinning}
    fullWidth={fullWidth}
    shrink={shrink}
    strong={strong}
    onClick={onClick}
    {...rest}
  />
);

export default styled(Button)`
  ${primary};
`;
export { LinkButton };
export const ButtonOrange = styled(Button)`
  ${orange};
`;
export const ButtonTransparent = styled(Button)`
  ${transparent};
`;
export const ButtonTransparentWhite = styled(Button)`
  ${transparentWhite};
`;
