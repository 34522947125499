import { keyframes } from 'styled-components';

export const colors = {
  error: 'rgba(205, 92, 92, 1)',
  orange: 'rgba(255, 176, 0, 1)',
  lightOrange: 'rgba(255,231,178, 1)',
  lightPurple: 'rgba(204, 187, 211, 1)',
  midLightPurple: 'rgba(152, 65, 155, 1)',
  purple: 'rgba(101,26,116, 1)',
  darkPurple: 'rgba(62, 15, 72, 1)',
  white: 'rgba(255, 255, 255, 1)',
  black: 'rgba(0, 0, 0, 0.8)',
  lightGrey: 'rgba(244, 244, 244, 1)'
};

export const sizes = {
  desktop: 1025,
  mobile: 480,
  tablet: 960
};

export const spin = keyframes`
  from {
    transform: rotate(0deg);
  }

  to {
    transform: rotate(359deg);
  }
`;

export const themePrimary = {
  background: colors.purple,
  color: colors.white
};

export const themeSecondary = {
  background: colors.white,
  color: colors.darkPurple
};

export const themePrimaryTransparent = {
  background: 'transparent',
  color: colors.white
};

export const themeSecondaryTransparent = {
  background: 'transparent',
  color: colors.darkPurple
};

export const themeLightGrey = {
  background: colors.lightGrey,
  color: colors.purple
};
