import React from 'react';
import styled from 'styled-components';
import { RouteComponentProps } from 'react-router';
import { colors } from '../../../../styles/constants';

import DropdownButtons, { ProfileButton } from '../DropdownButtons';
import EditProfile from './edit-profile.svg';
import EditProfileActive from './edit-profile-active.svg';
import Profile from './profile.svg';
import ProfileActive from './profile-active.svg';
import ProviderAdmin from './provider-admin.svg';
import ProviderAdminActive from './provider-admin-active.svg';
import SwitchProvider from './switch-provider.svg';
import SwitchProviderActive from './switch-provider-active.svg';
import Logout from './logout.svg';
import LogoutActive from './logout-active.svg';

const DropdownContainer = styled.section`
  display: flex;
  align-items: center;
  &:hover {
    background-color: ${colors.darkPurple};
  }
`;

interface AdminDropdownProps extends RouteComponentProps {
  onLogout: (...args: any[]) => any,
  providerId: number,
  providerSlug: string,
  showSwitchProviders?: boolean,
};

interface AdminDropdownState {
  isHidden: boolean,
};

class AdminDropdown extends React.Component<AdminDropdownProps, AdminDropdownState> {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      isHidden: true
    };
  }

  toggleMenu() {
    const { isHidden } = this.state;
    this.setState({
      isHidden: !isHidden
    });
  }

  render() {
    const { onLogout, providerId, providerSlug, showSwitchProviders } = this.props;

    const profileLinks = showSwitchProviders ? [
      {
        link: '/admin/providers/',
        label: 'List all profile pages',
        icon: SwitchProvider,
        iconActive: SwitchProviderActive,
        show: showSwitchProviders
      }
    ] : [
        {
          link: `/admin/providers/${providerId}/${providerSlug}/preview`,
          label: 'View profile',
          icon: Profile,
          iconActive: ProfileActive,
          show: true
        },
        {
          link: `/admin/providers/${providerId}/${providerSlug}/edit`,
          label: 'Edit profile',
          icon: EditProfile,
          iconActive: EditProfileActive,
          show: true
        }
    ]

    const dropDownLinks = [
      {
        link: `/admin/providers/welcome`,
        label: 'Welcome',
        icon: ProviderAdmin,
        iconActive: ProviderAdminActive,
        show: true
      },
      ...profileLinks,
      {
        link: '/admin/logout',
        label: 'Logout',
        onClick: onLogout,
        icon: Logout,
        iconActive: LogoutActive,
        show: true
      }
    ];
    return (
      <DropdownContainer>
        <DropdownButtons
          isHidden={this.state.isHidden}
          {...this.props}
          dropDownLinks={dropDownLinks}
        />
        <ProfileButton
          handleClick={this.toggleMenu}
          showProfile={this.state.isHidden}
        />
      </DropdownContainer>
    );
  }
}

export default AdminDropdown;
