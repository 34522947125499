import React from 'react';
import styled from 'styled-components';

const Content = styled.div`
  margin-top: 24px;
`;

const StepContent = ({ children }) => <Content>{children}</Content>;

export default StepContent;
