import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import Theme from '../../components/Theme';
import { themeLightGrey, themeSecondary } from '../../styles/constants';
import ComplaintsAndFeedbackData from '../Static/components/ComplaintsAndFeedback';
import ComplaintsAndFeedbackForm from '../Static/components/ComplaintsAndFeedbackForm';

function ComplaintsAndFeedback() {
  return (
    <>
      <Theme theme={themeSecondary}>
        <Navigation />
        <Page>
          <ComplaintsAndFeedbackData />
          {window.innerWidth > 768 ? (
            <Theme theme={themeLightGrey}>
              <ComplaintsAndFeedbackForm />
            </Theme>
          ) : (
            <Theme theme={themeSecondary}>
              <ComplaintsAndFeedbackForm />
            </Theme>
          )}
        </Page>
        <Footer />
      </Theme>
    </>
  );
}

export default ComplaintsAndFeedback;
