/* global document */
import React from 'react';
import ReactDOM from 'react-dom';

import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';

import App from './App';

if (process.env.REACT_APP_SENTRY_DSN) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    environment: process.env.REACT_APP_SENTRY_ENV || 'local',

    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration/
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    // https://docs.sentry.io/platforms/javascript/guides/react/session-replay/configuration/
    replaysOnErrorSampleRate: 1.0,

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0,
    integrations: [
      new BrowserTracing(),
      ...(process.env.REACT_APP_SENTRY_ENV === 'staging'
        ? [
            new Sentry.Replay({
              maskAllText: false,
              blockAllMedia: false
            })
          ]
        : [])
    ]
  });
}

ReactDOM.render(<App />, document.getElementById('root'));
