import React from 'react';
import styled from 'styled-components';

import StyledLink from '../Link';
import StarRating from '../StarRating';
import calculateRem from '../../styles/calculateRem';
import {ProviderId} from "../../state";

export const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${calculateRem(24)};
  a {
    margin-left: ${calculateRem(10)};
  }
`;

const ZeroReviewContainer = styled.span`
  margin-left: 10px;
`;

interface ReviewOptionProps {
  id: ProviderId,
  hasReviews: boolean,
  review?: number,
  reviewsCount: number,
  providerSlug: string,
};

const ReviewOption: React.FC<ReviewOptionProps> = ({
  id,
  hasReviews,
  review,
  reviewsCount,
  providerSlug
}) => (
  <ReviewContainer>
    {!hasReviews && <StarRating review={review} />}
    {hasReviews &&
      id && (
        <StyledLink to={`/providers/${providerSlug}/${id}`}>
          View provider profile
        </StyledLink>
      )}
    {!hasReviews &&
      reviewsCount > 0 && (
        <StyledLink to={`/providers/${providerSlug}/${id}/reviews`}>
          {'View'} {reviewsCount} {reviewsCount > 1 ? 'reviews' : 'review'}
        </StyledLink>
      )}
    {!hasReviews &&
      reviewsCount === 0 && (
        <ZeroReviewContainer>0 reviews</ZeroReviewContainer>
      )}
  </ReviewContainer>
);

ReviewOption.defaultProps = {
  review: 0,
  reviewsCount: 0
};

export default ReviewOption;
