import React from 'react';
import Theme from '../../../../../components/Theme';
import { themeLightGrey } from '../../../../../styles/constants';
import HCPFAQsIcon from './elderly-woman-white-hair.svg';

import { colors } from '../../../../../styles/constants';
import {
    NDISOtherText,
    NDISFAQSection,
    FAQInfoText,
    SupportCategorySection,
    NDISFAQParagraph,
    HCPFAQIcon,
    HCPFAQIconContainer
} from "../../../style"

const HCPFAQs = () => {

  return (
    <>
    <Theme theme={themeLightGrey}>
        {window.innerWidth > 768 ? (
            <>
                <SupportCategorySection>
                    <NDISOtherText style={{ paddingBottom: '16px' }}>
                        <b>FAQs</b>
                    </NDISOtherText>
                </SupportCategorySection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>What if I need to change my HCP?</FAQInfoText>
                    <NDISFAQParagraph  style={{width: '575px'}}>Your needs may change after you start receiving home care services. 
                        You can be reassessed by My Aged Care at any time, and your provider may help with this. 
                        You can also change providers at any time </NDISFAQParagraph>
                </NDISFAQSection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>Do I need to contribute towards the cost of my care?</FAQInfoText>
                    <NDISFAQParagraph  style={{width: '575px'}}>It is expected that you will contribute to the cost of care where your personal circumstances allow.  
                        To estimate personal contributions, check out the Home Care Fee Estimator Tool available on the {" "}
                        <a href="https://www.myagedcare.gov.au/how-much-will-i-pay" target='_blank'>My Aged Care website.</a></NDISFAQParagraph>
                </NDISFAQSection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>What happens if I don’t use all of my funds?</FAQInfoText>
                    <NDISFAQParagraph  style={{width: '575px'}}>We recommend that you use all of the funding allocated to you to ensure you’re getting the consistent care and support that you need. 
                        Contact Karista if you need help.</NDISFAQParagraph>
                </NDISFAQSection>
                <HCPFAQIconContainer>
                    <HCPFAQIcon src={HCPFAQsIcon} alt={"Core Supports"} />
                </HCPFAQIconContainer>
            </>) :
            (<>
                <HCPFAQIconContainer>
                    <HCPFAQIcon src={HCPFAQsIcon} alt={"Core Supports"} />
                </HCPFAQIconContainer>
              <SupportCategorySection>
                    <NDISOtherText style={{ paddingBottom: '16px' }}>
                        <b>FAQs</b>
                    </NDISOtherText>
                </SupportCategorySection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>What if I need to change my HCP?</FAQInfoText>
                    <NDISFAQParagraph>Your needs may change after you start receiving home care services. 
                        You can be reassessed by My Aged Care at any time, and your provider may help with this. 
                        You can also change providers at any time </NDISFAQParagraph>
                </NDISFAQSection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>Do I need to contribute towards the cost of my care?</FAQInfoText>
                    <NDISFAQParagraph>It is expected that you will contribute to the cost of care where your personal circumstances allow. 
                        To estimate personal contributions, check out the Home Care Fee Estimator Tool available on the  {" "}
                        <a href="https://www.myagedcare.gov.au/how-much-will-i-pay">My Aged Care website.</a></NDISFAQParagraph>
                </NDISFAQSection>
                <NDISFAQSection>
                    <FAQInfoText style={{color: colors.purple}}>What happens if I don’t use all of my funds?</FAQInfoText>
                    <NDISFAQParagraph>We recommend that you use all of the funding allocated to you to ensure you’re getting the consistent care and support that you need. 
                        Contact Karista if you need help.</NDISFAQParagraph>
                </NDISFAQSection>
                
              </>)}
    </Theme>
    </>
  );
}

export default HCPFAQs;