import React from 'react';
import Theme from '../../../../components/Theme';
import { themePrimary } from '../../../../styles/constants';
import IconTick from "./icon-tick.svg"
import EnquireButton from '../EnquireButton';

import {
    ProviderTickIcon,
    ProviderBenefitSection,
    ProviderToDos,
    BenefitText
} from "../../style";


 // Shared component for both desktop and mobile versions
const ProviderBenefitContent = () => (
    <>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <BenefitText>
            <b>Referrals that suit you</b><br />
            We only send you fully triaged referrals that we know match your services and availability.</BenefitText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <BenefitText>
            <b>You're in control</b><br />
            Via your own Administration portal, you can create your business profile and manage reviews.</BenefitText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <BenefitText>
            <b>We do the admin</b><br />
             Karista will complete the onboarding paperwork on your behalf (with participant consent), 
             reducing your administration time and increasing participant conversion.</BenefitText>
        </ProviderToDos>
        <ProviderToDos>
            <ProviderTickIcon><img src={IconTick} alt={'Tick'} />
            </ProviderTickIcon>
            <BenefitText>
            <b>Outsource your marketing to us</b><br />
            Karista engages in Google advertising, social media, direct and event marketing; 
            and with LAC's and Support Coordinators, to ensure your services can be found via our platform.</BenefitText>
        </ProviderToDos>
    </>
  );


const ProviderBenefits = () => {

    return (
        <Theme theme={themePrimary}>
            <ProviderBenefitSection>
                <ProviderBenefitContent />
            </ProviderBenefitSection>
            <EnquireButton />
        </Theme>
    );
}   

export default ProviderBenefits;