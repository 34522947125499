import React from 'react';
import styled from 'styled-components';

import Step, { CompletedStep, ActiveStep } from '../Step';
import calculateRem from '../../styles/calculateRem';
import mediaQueries from '../../styles/mediaQueries';

const StyledList = styled.ul`
  background: transparent;
  display: flex;
  align-content: space-between;
  justify-content: space-between;
  margin: ${calculateRem(10)} auto;
  padding: 0;
  width: ${calculateRem(320)};
  ${mediaQueries.mobile`
    width: ${calculateRem(280)};
  `};
`;

const StepList = ({
  maxSteps = 5,
  currentStep = 0,
  handleNavigation,
  completedSteps = []
}) => (
  <StyledList>
    {Array.from({ length: maxSteps }, (v, i) => i).map(i => {
      const Completed =
        completedSteps[i] && completedSteps[i].completed ? CompletedStep : Step;
      const Component = currentStep === i ? ActiveStep : Completed;
      return (
        <Component
          key={`step-list-item-${i}`}
          handleClick={() => handleNavigation(i)}
        >
          {i + 1}
        </Component>
      );
    })}
  </StyledList>
);

StepList.defaultProps = {
  completedSteps: []
};

export default StepList;
