import React from 'react';
import styled from 'styled-components';

import mediaQueries from '../../../../styles/mediaQueries';
import { BetaHeading } from '../../../../components/Typography';
import { LogoVertical } from '../../../../components/Logo';

const StyledTitle = styled(BetaHeading)`
  max-width: 700px;
  margin-top: 24px;
  margin-bottom: 0;
  ${mediaQueries.mobile`
    line-height: 36px;
    font-size: 22px;
    margin-top: 12px;
  `};
`;

const Hero = () => {
  const defaultContent = 'Searching for disability or home care services? Let us help.';
  return (
    <div>
      <LogoVertical />
      <StyledTitle>{defaultContent}</StyledTitle>
    </div>
  );
}

export default Hero;
