import React from 'react';
import { useWindowSize } from '../../../../util/useWindowSize';

import Theme from '../../../../components/Theme';
import { themePrimary } from '../../../../styles/constants';
import VerticalLogo from '../../../../components/Logo/logo_vertical.png';

import {
  CampaignHeaderSection,
  VerticalLogoSection,
  StyledTitle,  
  CampaignIcon,
  MobileImageSection
} from "../../style";



interface HeroProps {
  content: string,
  heading_image: string,
  mobile_heading_image: string,
  slug: string,
};

const Hero: React.FC<HeroProps> = ({ content, heading_image, mobile_heading_image, slug }) => {
  const { width, height } = useWindowSize()

  const HeroDesktop = () => {
      return (
        <div>
          <CampaignHeaderSection>
              <VerticalLogoSection alt="Karista logo" src={VerticalLogo} />
              <StyledTitle><b>{content}</b></StyledTitle>
              <CampaignIcon>
                <img src={heading_image} alt={slug} />
              </CampaignIcon>
          </CampaignHeaderSection>
        </div>
      );
  }

  const HeroMobile = () => {
    return (
        <CampaignHeaderSection>
            <StyledTitle><b>{content}</b></StyledTitle>
            <MobileImageSection>
              <VerticalLogoSection alt="Karista logo" src={VerticalLogo} />
              <CampaignIcon>
                <img src={mobile_heading_image} alt={slug} />
              </CampaignIcon>
            </MobileImageSection>
        </CampaignHeaderSection>
    );
  }
  
  return (
    <Theme theme={themePrimary}>
      {width > 720 ? <HeroDesktop /> : <HeroMobile />}
    </Theme>
  );
}

export default Hero;
