import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import LeadshipIntro from './components/LeadershipIntro';
import NotJustADirectory from './components/NotJustADirectory';
import HowCanWeHelp from './components/HowCanWeHelp';
import CommericalModel from './components/CommericalModel';

const About = () => (
  <>
    <Helmet>
      <title>About Karista: Your Partner in Care</title>
      <meta
        name="description"
        content="Karista provides a free service connecting you with Disability and Aged Care services, therapies, and home care supports based on your personal needs."
      />
    </Helmet>
    <Theme theme={themeSecondary}>
      <Navigation>
        <OrangeLink border to="/">
          Back to search
        </OrangeLink>
      </Navigation>
      <LeadshipIntro />
      <NotJustADirectory />
      <HowCanWeHelp />
      <CommericalModel />
      <Footer />
    </Theme>
  </>
);

export default About;
