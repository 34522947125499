export enum ProviderTabs {
    welcome = 'welcome',
    edit = 'edit',
    reviews = 'reviews',
    list = 'list',
};

const PROVIDER_TABS_WELCOME = ProviderTabs.welcome;
const PROVIDER_TABS_EDIT = ProviderTabs.edit;
const PROVIDER_TABS_REVIEWS = ProviderTabs.reviews;
const PROVIDER_TABS_LIST = ProviderTabs.list;
const PROVIDER_TABS = [
  PROVIDER_TABS_WELCOME,
  PROVIDER_TABS_EDIT,
  PROVIDER_TABS_REVIEWS
];


export {
  PROVIDER_TABS_WELCOME,
  PROVIDER_TABS_EDIT,
  PROVIDER_TABS_REVIEWS,
  PROVIDER_TABS,
  PROVIDER_TABS_LIST
};

export const reviewsPerPage = 5;
