import React from 'react';
import Theme from '../../../../components/Theme';
import { themeLightGrey, themePrimary} from '../../../../styles/constants';
import IconClientServiceCircle from "./icon-client-services-circle.svg"
import api from '../../../../services/karista';
import { reduceQueries } from '../../../../util/rtk-query';
import Button from '../../../../components/Button';
import Breakpoint from '../../../../components/Breakpoint';
import { useDispatch } from 'react-redux';
import { change } from 'redux-form';
import { setNewCampaign } from '../../../../util/local-storage';

import {
    Card,
    CardSpacer,
    CardsSection,
    CardInfoText,
    ClientServiceCircleIcon,
    StyledLink,
    ButtonSection,
    PurpleHeaderHeading,
    HelpHeaderSection,
    ButtonBetaText,
    ButtonPlacementSection
} from "../../style"

const HowCanWeHelp = () => {
    const dispatch = useDispatch();

    const useInitialize = () => reduceQueries({
        serviceAreas: api.useGetAllServiceAreasQuery()
    });
    
    const { serviceAreas, isLoading } = useInitialize();

    if (!serviceAreas || isLoading) {
        return <div>Loading ...</div>;
    }
    
    const serviceAreaData = serviceAreas.data || [];

    // Calculate the number of columns
    const numColumns = 3;

    // Calculate the number of cards in each column
    const cardsPerColumn = Math.floor(serviceAreaData.length / numColumns);

    // Calculate the number of columns that will have an extra card
    const columnsWithExtraCard = serviceAreaData.length % numColumns

    // Split services into columns
    const columns = Array.from({ length: numColumns }, (_, columnIndex) => {
        // Checking extra card in case of 1 extra card in 3 columns structure
        const extraCard = (columnsWithExtraCard && columnIndex) === 1 ? 1 : 0;
        const lastColumnCard = (columnsWithExtraCard && columnIndex === numColumns - 1) ? 1: 0
        // Checking extra card in case of 2 extra cards in 3 columns structure
        const multipleExtraCards = (lastColumnCard && columnsWithExtraCard > 1) ? 1 : 0
        const startIndex = columnIndex * cardsPerColumn + lastColumnCard;
        const endIndex = ((columnIndex + 1) * cardsPerColumn) + extraCard + lastColumnCard + multipleExtraCards;
        return serviceAreaData.slice(startIndex, endIndex);
      });

    // Find the length of the longest text
    const longestTextLength = Math.max(...serviceAreaData.map(serviceArea => serviceArea.name.length));

    // Set the width of the CardSpacer and Card components based on the longest text length
    const cardWidth = `${longestTextLength * 8}px`;

    const handleClick = (serviceArea) => {
        // Scroll to the top of the page
        window.scrollTo(0, 0);
        // Populate service area with text within the card
        setNewCampaign('')
        dispatch(change('advanced_search', 'service_areas_list', serviceArea.name));
    }

    const SupportButton = ({ theme }) => {
        return (
            <Theme theme={theme}>
                <ButtonPlacementSection>
                    <ButtonSection>
                        <Button>
                            <StyledLink onClick={handleClick}  to={`/`}>
                                <ButtonBetaText>
                                    Search for supports now
                                </ButtonBetaText>
                            </StyledLink>
                        </Button>
                    </ButtonSection>
                </ButtonPlacementSection>
            </Theme>
        );
    }
      
    return (
        <Theme theme={themeLightGrey}>
            {serviceAreaData.length > 0 && (
            <HelpHeaderSection>
                <div>
                    <ClientServiceCircleIcon>
                        <img src={IconClientServiceCircle} alt={'Circle'} />
                    </ClientServiceCircleIcon>
                    <PurpleHeaderHeading>How can we help?</PurpleHeaderHeading>
                    <CardsSection>
                        {Array.from({ length: cardsPerColumn + 1 }, (_, cardIndex) => (
                            <div style={{ display: 'flex', justifyContent: 'space-between' }} key={cardIndex}>
                                {columns.map((column, columnIndex) => {
                                    const serviceArea = column[cardIndex];
                                    if (!serviceArea) return null;

                                    const cardCount = columnIndex ? cardsPerColumn + 1 : cardsPerColumn;

                                    if (cardIndex <= cardCount - 1) {
                                        return (
                                            <CardSpacer key={serviceArea.name}>
                                               <StyledLink onClick={() => {
                                                        handleClick(serviceArea);
                                                    }} to={serviceArea.slug ? `/services/${serviceArea.slug}` : '/'}>
                                                    <Card style={{ width: cardWidth }}>
                                                        <CardInfoText>
                                                            <b>{serviceArea.name}</b>
                                                        </CardInfoText>
                                                    </Card>
                                                </StyledLink>
                                            </CardSpacer>
                                        );
                                    } else {
                                        return ;
                                    }
                                })}
                            </div>
                        ))}
                    </CardsSection>
                </div>
            </HelpHeaderSection> )}
            <Breakpoint>
                {breakpoint => {
                    if (breakpoint === 'desktop' || breakpoint === 'tablet') return <SupportButton theme={themeLightGrey}  />
                    return <SupportButton theme={themePrimary} />;
                    }}
            </Breakpoint>
        </Theme>
    );     
}

export default HowCanWeHelp;