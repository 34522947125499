import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import Pagination from '../../../components/Pagination';
import ProviderReview from '../../../components/ProviderReview';
import StyledLink from '../../../components/Link';
import { BetaHeading, DeltaHeading } from '../../../components/Typography';

import ProfileHeader, { WideLink } from '../../../components/ProfileHeader';
import ReviewsTitleContainer from '../../../components/ReviewsTitleContainer';
import calculateRem from '../../../styles/calculateRem';
import { createReviewsPaginationSubheading } from '../../../util/pagination';
import { PageNotFoundInner } from '../../Static/PageNotFound';
import {Query} from '../../../state';
import { ProvidersActions } from '../actions';
import { useGetProviderDetailsQuery, useGetProviderDetailsUsingSlugQuery, useGetProviderReviewsQuery } from '../../../services/karista';
import {get} from "lodash";
import {stringify} from "qs";
import Button from '../../../components/Button';
import { colors } from '../../../styles/constants';
import {
  ButtonsContainer,
  TransparentButton
} from '../../../components/ProviderDetails/styles';

export const NewWideLink = styled(Button)`
  margin-bottom: ${calculateRem(2)};
  margin-left: ${calculateRem(4)};
  width: auto;
`;

export const NewTransparentButton = styled(NewWideLink)`
  background-color: transparent;
  border-color: ${colors.darkPurple};
`;

const ReviewsFlexWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: ${calculateRem(24)};
`;
const NoResults = () => <div>No reviews available yet</div>;

export interface ReviewsListProps extends RouteComponentProps<{id?: string, slug?: string}> {
  query: Query,
  actions: ProvidersActions,
};

const ReviewsList: React.FC<ReviewsListProps> = (props) => {
  const providerId = parseInt(props.match.params.id as string);
  const providerSlug = props.match.params.slug as string;

  const page = Number(get(props.query, 'page', 1));

  const pageSize = 5;
  const {data: providerData, isLoading: providerIsLoading, isSuccess: providerIsSuccess} = useGetProviderDetailsUsingSlugQuery(providerSlug);
  const {data: reviewsData, isLoading, isSuccess, isError} = useGetProviderReviewsQuery({providerId, page, pageSize});

  if (providerIsLoading || isLoading) {
    return <div />;
  }

  if (!providerIsSuccess || !isSuccess) {
    return <div />;
  }
  

  const subheading = createReviewsPaginationSubheading(page, pageSize, reviewsData && reviewsData.count);

  const handleClick = page => {
    const providerId = parseInt(props.match.params.id as string);
    const providerSlug = props.match.params.slug as string;
    const updatedPathname = `/providers/${providerSlug}/${providerId}/reviews`;
    props.history.push({
      pathname: updatedPathname,
      search: stringify({
        ...props.query,
        page: page,
      })
    });
  }

  return (
    <>
      <Helmet>
        <title>{`${providerData.name} - Customer Reviews on Karista`}</title>
        <meta name="description" content={`Read reviews of individuals how have experienced services of ${providerData.name}. Explore how ${providerData.name} has helped individuals achieve their goals.`} />
      </Helmet>
    <ProfileHeader
      logoUrl={providerData.logo_url}
      providerId={providerId}
      name={providerData.name}
      hasReviews
      purple
      isActive={providerData.is_active}
      providerSlug={providerData.url_slug}
      {...props}
    >
      <>
        <ButtonsContainer>
            <StyledLink to={`/providers/${providerSlug}/${providerId}/review/`}>
              <TransparentButton>Review this provider</TransparentButton>
            </StyledLink>
            <StyledLink to={`/providers/${providerSlug}/${providerId}/request-quote/`}>
              <WideLink>Connect with the Provider</WideLink>
            </StyledLink>
          </ButtonsContainer>
        <ReviewsFlexWrapper>
          <ReviewsTitleContainer>
            <BetaHeading>Reviews</BetaHeading>
            <DeltaHeading>{subheading}</DeltaHeading>
          </ReviewsTitleContainer>
        </ReviewsFlexWrapper>
      </>
      
      {reviewsData.results && reviewsData.results.length > 0 ?
        reviewsData.results.map((review, i) => (
          <ProviderReview
            key={`provider-${providerId}-review-${i}`} // eslint-disable-line
            {...review}
          />
        )) : <NoResults />}
      {reviewsData.totalPages > 1 && (
        <Pagination current={page} {...reviewsData} onClick={handleClick} />
      )}
    </ProfileHeader>
    </>
  )
};

export default ReviewsList;
