import styled from 'styled-components';
import mediaQueries from '../../../styles/mediaQueries';
import { colors } from '../../../styles/constants';
import { Centered } from '../../../components/Layout';
import Label from '../../../components/Label';
import Link from '../../../components/Link';
import Input from '../../../components/Input';

export const CenteredWithGutters = styled(Centered)`
  max-width: 720px;
  margin: 16px auto;
  text-align: left;
`;

export const StyledLabel = styled(Label)`
  font-weight: 400;
  margin: 0;
`;

export const SubLabel = styled.p`
  line-height: 24px;
  margin-top: -6px;
  margin-bottom: 6px;
`;

export const BtnContainer = styled.div`
  margin: 0 auto;
`;

export const BottomLink = styled(Link)`
  color: ${colors.darkPurple};
  text-decoration: underline;
`;

export const HalfInput = styled(Input)`
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`;

export const CallLink = styled.a`
  text-decoration: none;
`;

export const Card = styled.div`
  color: ${colors.orange};
  background: ${colors.orange};
  height: fit-content;
  display: flex;
  flex-direction: row;
  align-items: center;
  text-align: center;
  padding: 5px;
  border-radius: 5px;
  width: fit-content;
  cursor: pointer;

  &:hover {
    border: 1px solid ${colors.black};
  }
`;
export const InfoText = styled.span`
  line-height: 26px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const CardInfoText = styled(BlackInfoText)`
  padding: 5px;
  white-space: nowrap;
  font-size: 18px;
`;

export const ModalSection = styled.div`
  font-size: 18px;
  color: darkPurple;
  margin-top: 12px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
`;

export const Anchor = styled.a`
  color: ${colors.white};
  padding: 0.75rem 0;
  margin: 0 0.75rem;
  text-decoration: underline;
  cursor: pointer;
`;

export const CloseButton = styled.button`
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: white;
  font-size: 20px;
  cursor: pointer;
`;

export const modalStyles = {
  overlay: {
    backgroundColor: 'rgba(0, 0, 0, 0)',
    filter: 'none'
  },
  content: {
    width: '30%',
    maxWidth: '700px',
    height: '360px',
    left: '67%',
    marginTop: '130px',
    backgroundColor: 'purple',
    color: 'white',
    padding: '20px',
    borderRadius: '8px',
    fontSize: '48px'
  }
};

if (window.innerWidth < 821) {
  modalStyles.content.height = '440px';
  modalStyles.content.fontSize = '28px';
  modalStyles.content.left = '20%';
  modalStyles.content.width = '55%';
}
