import React from 'react';

import { StyledGridItem } from './styles';

interface GridItemProps {
  columns?: number,
  mobile?: number | null,
  tablet?: number | null,
  desktop?: number | null,
  spacing?: boolean,
  spacingWidth?: number,
  children?: React.ReactNode,
};

const GridItem: React.FC<GridItemProps> = props => {
  const {
    columns = 12,
    desktop: desktopProp = null,
    tablet: tabletProp = null,
    mobile: mobileProp = null,
    children,
    spacing = true,
    spacingWidth = 1,
    ...other
  } = props;

  const mobile = mobileProp || columns;
  const tablet = tabletProp || mobile;
  const desktop = desktopProp || tablet;
  const mobilePercent = roundDecimals(mobile / columns * 100, 2);
  const tabletPercent = roundDecimals(tablet / columns * 100, 2);
  const desktopPercent = roundDecimals(desktop / columns * 100, 2);

  const gridItemProps = {
    ...other,
    columns,
    desktop,
    desktopPercent,
    mobile,
    mobilePercent,
    tablet,
    tabletPercent,
    spacing,
    spacingWidth,
    spacingUnit: 'rem',
  };

  return (
    <StyledGridItem {...gridItemProps}>
      <div>{children}</div>
    </StyledGridItem>
  );
}

/**
 * @param num: float value to be rounded
 * @param pos: integer number of decimal places to round to
 */
function roundDecimals(num: number, pos: number) {
  return Math.round(num * 10 ** pos) / 10 ** pos;
}

export default GridItem;
