import { chunk, max, min, nth } from 'lodash';

export function getPage(data, pageSize, page) {
  return nth(chunk(data, pageSize), page);
}

/**
 * Creates the `pagination` object when given an array of reviews. This is,
 * slightly confusingly, the equivalent of the `reviews.data` in the redux
 * store.
 *
 * Note that this gives a correct pagination object only for reviews. The
 * pagination object for providers has additional attributes that are not
 * returned here (at time of writing: nextUrl, previousUrl, page, current).
 */
export function createReviewsPagination(arrayOfReviews, reviewsPerPage) {
  const reviewsCount = arrayOfReviews.length ? arrayOfReviews.length : 0;
  const totalPages = reviewsPerPage
    ? Math.ceil(reviewsCount / reviewsPerPage)
    : 0;
  const pagination = {
    reviewsPerPage,
    reviewsCount,
    totalPages
  };

  return pagination;
}

/**
 * Creates a string of the form:
 *
 * X - Y of Z results
 *
 * Where X and Y are the first and last indexes on the current page and Z is
 * the total number of results. Requires a pagination object that has its
 * current page (not page index) set.
 */
export function createProviderListPaginationSubheading(page: number | undefined, itemsPerPage: number | undefined, itemsCount: number | undefined) {
  if (!page || !itemsPerPage || !itemsCount) {
    return '';
  }

  const ceiling = min([page * itemsPerPage, itemsCount]);
  const floor = max([(page - 1) * itemsPerPage + 1, 1]);
  const totalReviews = Number(itemsCount).toLocaleString();
  const subheading = `${floor} - ${ceiling} of ${totalReviews} providers`;

  return subheading;
}

export function createReviewsPaginationSubheading(page: number | undefined, itemsPerPage: number | undefined, itemsCount: number | undefined) {
  if (!page || !itemsPerPage || !itemsCount) {
    return '';
  }

  const ceiling = min([page * itemsPerPage, itemsCount]);
  const floor = max([(page - 1) * itemsPerPage + 1, 1]);
  const totalReviews = Number(itemsCount).toLocaleString();
  const subheading = `${floor} - ${ceiling} of ${totalReviews} reviews`;

  return subheading;
}
