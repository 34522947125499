import GoogleAnalytics from 'react-ga4';
import { eventCategories } from '../../../util/analytics'

const PAGE_NOT_FOUND_EVENT = "Page Not Found Rendered";

export async function trackPageNotFound() {
  GoogleAnalytics.event({ category: eventCategories.ERRORS, action: PAGE_NOT_FOUND_EVENT, nonInteraction: true });
  const { analytics } = (window as any);
  return analytics.track(`${eventCategories.ERRORS} - ${PAGE_NOT_FOUND_EVENT}`);
}
