import styled from 'styled-components';

import Label from '../Label';
import { Centered } from '../Layout';
import Button from '../Button';
import calculateRem from '../../styles/calculateRem';
import mediaQueries from '../../styles/mediaQueries';
import FormItem from '../FormItem';

interface FormFieldProps {
  last?: boolean,
}

export const HalfFormField = styled(FormItem)<FormFieldProps>`
  width: ${props => (props.last ? '50%' : 'calc(50% - 1rem)')};
  margin-right: ${props => (props.last ? 0 : '1rem')};
  input {
    padding: 0;
  }
`;

export const QuarterFormField = styled(FormItem)<FormFieldProps>`
  width: ${props => (props.last ? '25%' : 'calc(25% - 1rem)')};
  margin-right: ${props => (props.last ? 0 : '1rem')};
  input {
    padding: 0;
  }
`;

export const Group = styled.div`
  display: flex;
`;

export const WrappingGroup = styled(Group)`
  flex-wrap: nowrap;
  align-items: center;
  justify-content: center;
`;

export const StyledButton = styled(Button)`
  min-width: ${calculateRem(132)};
`;

export const StyledLabel = styled(Label)`
  ${mediaQueries.mobile`
    display: none;
  `} ${props => ({ ...props.theme })};
`;

export const StyledFieldLabel = styled(Label)`
  font-weight: 500;
  text-decoration: underline;
  ${props => ({ ...props.theme })};
`;

export const LightweightLabel = styled.span`
  font-weight: 200;
  text-decoration: none;
`;

export const HeavyweightLabel = styled.span`
  font-weight: 500;
  text-decoration: underline;
`;

export const Divider = styled(Centered)`
  margin-top: 2rem;
  margin-bottom: 2rem;
`;

export const FormContainer = styled.div`
  margin-bottom: 2rem;
`;
