import * as React from 'react';
import { map } from 'lodash';

import karistaApi, {useGetOwnedProvidersQuery} from '../../services/karista';
import { useAppDispatch } from '../../state/hooks';
import { setCurrentProvider } from './actions';

type UseGetProviderDetailsQueryP = Parameters<typeof karistaApi.useGetProviderDetailsQuery>;
type UseGetProviderDetailsQueryR = ReturnType<typeof karistaApi.useGetProviderDetailsQuery>;

// Fetch the provider identified by `providerId`, and ensure the `currentProvider` state is updated. This is used to
// keep the navigation dropdown up to date while navigating the admin.
//
// Use this anywhere in the admin you'd normally use `useGetProviderDetailsQuery`.
export const useCurrentProviderQuery = (...args: UseGetProviderDetailsQueryP): UseGetProviderDetailsQueryR => {
  const { data, isLoading, ...rest } = karistaApi.useGetProviderDetailsPrivilegedQuery(...args);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const currentProvider = (!isLoading && data) ? data : null;
    dispatch(setCurrentProvider(currentProvider));
  }, [isLoading]);
  return { data, isLoading, ...rest };
}

type UseGetProviderDetailsUsingSlugQueryP = Parameters<typeof karistaApi.useGetProviderDetailsUsingSlugQuery>;
type UseGetProviderDetailsUsingSlugQueryR = ReturnType<typeof karistaApi.useGetProviderDetailsUsingSlugQuery>;

// Fetch the provider identified by `providerId`, and ensure the `currentProvider` state is updated. This is used to
// keep the navigation dropdown up to date while navigating the admin.
//
// Use this anywhere in the admin you'd normally use `useGetProviderDetailsQuery`.
export const useCurrentProviderUsingSlugQuery = (...args: UseGetProviderDetailsUsingSlugQueryP): UseGetProviderDetailsUsingSlugQueryR => {
  const { data, isLoading, ...rest } = karistaApi.useGetProviderDetailsPrivilegedUsingSlugQuery(...args);
  const dispatch = useAppDispatch();
  React.useEffect(() => {
    const currentProvider = (!isLoading && data) ? data : null;
    dispatch(setCurrentProvider(currentProvider));
  }, [isLoading]);
  return { data, isLoading, ...rest };
}

type UseGetOwnedProvidersQueryP = Parameters<typeof useGetOwnedProvidersQuery>;
type UseGetOwnedProvidersQueryR = ReturnType<typeof useGetOwnedProvidersQuery>;

// Fetch the IDs of providers accessible to the current user.
//
// This utilises `useGetOwnedProvidersQuery` to fetch the complete list of accessible providers, and then maps the
// provider objects to their respective IDs. This is, of course, somewhat inefficient.
export const useGetAuthorizedProviderIdsQuery = (...args: UseGetOwnedProvidersQueryP): UseGetOwnedProvidersQueryR => {
  const { data, isLoading, ...rest } = useGetOwnedProvidersQuery(...args);
  return {
    data: isLoading ? [] : map(data.results, 'id'),
    isLoading,
    ...rest,
  }
}
