/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable consistent-return */

import React, {useState} from 'react';
import { useForm} from "react-hook-form";
import { RouteComponentProps } from 'react-router';
import { concat } from 'lodash';
import Button from '../Button';
import Input from '../Input';
import Select from '../Select';
import MultiSelect from '../MutliSelect';
import Checkbox from '../Checkbox';
import CheckboxGroup from '../CheckboxGroup';
import ValidationError from '../ValidationError';
import FormItem from '../FormItem';
import {
  CenteredWithGutters,
  StyledLabel,
  BtnContainer,
  SubLabel,
  RowContainer
} from './styles';
import {ServiceArea, HCPServiceArea, Diagnosis} from "../../state";
import {WhiteAnchor} from "../Link";

interface QuoteAdditionalFormProps {
  onSubmit: (data: QuoteAdittionalQuestionsSubmitData) => Promise<void>,
  serviceAreas?: ServiceArea[]
  hcpServiceAreas?: HCPServiceArea[],
  diagnosis?: Diagnosis[]
}

// These types are for ease of managing form state, final
// data types for submission are below.
type FormValues = {
  participant_first_name: string
  participant_last_name: string
  participant_relation: string
  diagnosis: number[]
  hcp_service_areas: number[]
  additional_services: number[]
  terms: boolean
  automate_referral: boolean
  hcp_referral: boolean
  preferencesCheckbox: boolean
  gender_preference: string
  language_preference: string
  day_preference: string[]
  time_preference: string[]
  additional_preference: string
}

// This is different from the form state values.
// The transformation happens on delegation to the submit handler.
export type QuoteAdittionalQuestionsSubmitData = {
  participant_first_name: string
  participant_last_name: string
  participant_relation: string
  diagnosis: number[]
  hcp_service_areas: number[]
  additional_services: number[]
  preferencesCheckbox: boolean
  gender_preference: string
  language_preference: string
  day_preference: string[]
  time_preference: string[]
  terms: boolean
  automate_referral: boolean
  hcp_referral: boolean
  additional_preference: string
}

export interface AdditionalQuoteQuestionProps extends RouteComponentProps {
    serviceAreas: ServiceArea[] | null,
    hcpServiceAreas: HCPServiceArea[] | null,
    diagnosis: Diagnosis[] | null
  }
  

const AdditionalQuestionsForm = (props: QuoteAdditionalFormProps) => {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    setError,
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      participant_first_name: "",
      participant_last_name: "",
      participant_relation: "",
      diagnosis: [],
      hcp_service_areas: [],
      additional_services: [],
      preferencesCheckbox: false,
      gender_preference: "",
      language_preference: "",
      day_preference: [],
      time_preference: [],
      terms: false,
      automate_referral: false,
      hcp_referral: true,
      additional_preference: ""
    }
  });

  const {errors} = formState;

  const submitForm = async (data: FormValues) => {
    try {
      await props.onSubmit({...data, diagnosis: data.diagnosis, additional_services: data.additional_services,
        hcp_service_areas: data.hcp_service_areas, gender_preference: data.gender_preference,
        language_preference: data.language_preference, automate_referral: data.hcp_service_areas.length > 0 ? true : false,
        time_preference: data.time_preference, additional_preference: data.additional_preference, hcp_referral: true});
      reset({}, {keepValues: true});
    }
    catch (error) {
      console.error('Unexpected occurred:', error);
      setError('root', {message: 'Unexpected error'});
    }
  }

  const handlePreferencesCheckboxChange = () => {
    const preferencesCheckboxValue = watch('preferencesCheckbox');
  };

  const [showOtherGenderInput, setShowOtherGenderInput] = useState(false);

  const handleGenderPreferenceChange = (selectedValue: string) => {
    if (selectedValue === 'Other') {
      setShowOtherGenderInput(true);
      setValue('gender_preference', '');
    } else {
      setShowOtherGenderInput(false);
    }
  };

  return (
    <CenteredWithGutters>
      <form onSubmit={handleSubmit(submitForm)}>
      <StyledLabel>Name of Home Care Package recipient</StyledLabel>
      <br/>
      <br/>
      <RowContainer>
        <FormItem style={{ marginRight: '24px' }}>
          <SubLabel>First Name</SubLabel>
          <Input
            input={{placeholder:" Type here", type: "text", ...register("participant_first_name", {required: true})}}
            meta={{submitFailed: !!errors.participant_first_name, error: "Participant first name is required"}}
          />
        </FormItem>
        <FormItem style={{ marginRight: '24px' }}>
          <SubLabel>Last Name</SubLabel>
          <Input
            input={{placeholder:" Type here", type: "text", ...register("participant_last_name", {required: false})}}
          />
        </FormItem>
      </RowContainer>
        <FormItem>
              <div>
                <StyledLabel>What is your relationship to the recipient?</StyledLabel>
                <Select
                    fieldOptions={concat(
                      { label: "This request is for myself", value: 'This request is for myself' },
                      { label: "I am their parent", value: 'I am their parent' },
                      { label: "I am their child", value: 'I am their child' },
                      { label: "I am their spouse/partner", value: 'I am their spouse/partner' },
                      { label: "I am their unpaid carer", value: 'I am their unpaid carer' },
                      { label: "I am their paid carer or case worker", value: 'I am their paid carer or case worker' },
                      { label: "Other", value: 'Other' },
                    )}
                    input={{placeholder: "Select from list", type: "text", ...register("participant_relation", {required: true})}}
                    meta={{submitFailed: !!errors.participant_relation, error: "Please select your relationship to the participant"}}
                />
              </div>
          </FormItem>
        <FormItem>
            {props.diagnosis && props.diagnosis.length > 0 && (
              <div>
                <StyledLabel>Is there any diagnosis?</StyledLabel>
                <MultiSelect
                    fieldOptions={props.diagnosis.map(diag => ({
                      label: diag.name,
                      value: diag.name
                    }))}
                    input={{placeholder: "Select from list", type: "array", ...register("diagnosis", {required: false})}}
                    setValue={setValue} 
                />
              </div>
            )}
          </FormItem>
        <FormItem>
            {props.hcpServiceAreas && props.hcpServiceAreas.length > 0 && (
              <div>
                <StyledLabel>What services does the recipient require?</StyledLabel>
                <MultiSelect
                    fieldOptions={props.hcpServiceAreas.map(hcpServiceArea => ({
                      label: hcpServiceArea.name,
                      value: hcpServiceArea.name
                    }))}
                    input={{placeholder: "Select from list", type: "array", ...register("hcp_service_areas", {required: false})}}
                    setValue={setValue}
                    meta={{submitFailed: !!errors.hcp_service_areas, error: "Please select a service"}}
                />
              </div>
            )}
          </FormItem>
        <FormItem>
            {props.serviceAreas && props.serviceAreas.length > 0 && (
              <div>
                <StyledLabel>Does the recipient require any allied health services?</StyledLabel>
                <MultiSelect
                    fieldOptions={props.serviceAreas.filter(serviceArea => serviceArea.valid_for_hcp).map(serviceArea => ({
                      label: serviceArea.name,
                      value: serviceArea.name
                    }))}
                    input={{placeholder: "Select from list", type: "array", ...register("additional_services", {required: false})}}
                    setValue={setValue}
                />
              </div>
            )}
          </FormItem>
        <StyledLabel>Optional Preferences:</StyledLabel>
        <FormItem>
          <Checkbox
            input={{ type: "checkbox", ...register("preferencesCheckbox") }}
            meta={{ submitFailed: !!errors.preferencesCheckbox, error: "Please check the box if recipient has preferences" }}
            label="Does the recipient have preferences regarding gender, language, time, or other?"
            onChange={handlePreferencesCheckboxChange}
          />
        </FormItem>
        {/* Conditionally render additional fields based on the checkbox value */}
        {watch("preferencesCheckbox") && (
          <>
            <FormItem>
              <StyledLabel>Gender Preference</StyledLabel>
              <Select
                fieldOptions={[
                  { label: "Male", value: "Male" },
                  { label: "Female", value: "Female" },
                  { label: "Other", value: "Other" },
                  { label: "No Preference", value: "No preference" },
                ]}
                input={{
                  placeholder: "Select from list",
                  type: "text",
                  ...register("gender_preference", { required: false }),
                  onChange: (e: React.ChangeEvent<HTMLSelectElement>) => {
                    setValue("gender_preference", e.target.value);
                    handleGenderPreferenceChange(e.target.value);
                  },
                }}
                meta={{ submitFailed: !!errors.gender_preference, error: "Please select a gender" }}
              />
            </FormItem>
            {showOtherGenderInput && (
            <FormItem>
              <Input
                input={{
                  placeholder: "Please type your gender preference",
                  type: "text",
                  ...register("gender_preference", { required: false }),
                }}
                meta={{ submitFailed: !!errors.gender_preference, error: "Please specify other gender preference" }}
              />
            </FormItem>
            )}
            <FormItem>
              <StyledLabel>Language Preference</StyledLabel>
              <Input
                input={{ placeholder: "Type here", type: "text", ...register("language_preference", { required: false }) }}
                meta={{ submitFailed: !!errors.language_preference, error: "Please enter a language" }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>Days participant requires services</StyledLabel>
              <CheckboxGroup
                fieldOptions={[
                  { label: "Monday", value: "Monday" },
                  { label: "Tuesday", value: "Tuesday" },
                  { label: "Wednesday", value: "Wednesday" },
                  { label: "Thursday", value: "Thursday" },
                  { label: "Friday", value: "Friday" },
                  { label: "Saturday", value: "Saturday" },
                  { label: "Sunday", value: "Sunday" },
                  { label: "No Preference", value: "No preference" },
                ]}
                control={control}
                name="day_preference"
                meta={{ submitFailed: !!errors.day_preference, error: "Please select at least one day" }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>Time of the Day</StyledLabel>
              <CheckboxGroup
                fieldOptions={[
                  { label: "Morning", value: "Morning" },
                  { label: "Afternoon", value: "Afternoon" },
                  { label: "Evening", value: "Evening" },
                  { label: "No Preference", value: "No preference" },
                ]}
                control={control}
                name="time_preference"
                meta={{ submitFailed: !!errors.time_preference, error: "Please select at least one time" }}
              />
            </FormItem>
            <FormItem>
              <StyledLabel>Additional Preferences</StyledLabel>
              <Input
                input={{ placeholder: "Please type here any additional preferences", type: "text", ...register("additional_preference", { required: false }) }}
              />
            </FormItem>
          </>
        )}
        <div>
          <CenteredWithGutters>
            <BtnContainer>
              <Button disabled={formState.isSubmitting || formState.isSubmitSuccessful} fullWidth type="submit">
                Submit
              </Button>
            </BtnContainer>
          </CenteredWithGutters>
        </div>
        {errors.root && (
          <CenteredWithGutters>
              <ValidationError meta={{submitFailed: true, error: "Sorry, there has been a system error."}} >
                &nbsp;If this error persists, please <WhiteAnchor href="mailto:info@karista.com.au?Subject=I can't submit my request">contact us</WhiteAnchor>.
              </ValidationError>
          </CenteredWithGutters>
        )}
      </form>
    </CenteredWithGutters>
  );
}

export default AdditionalQuestionsForm;
