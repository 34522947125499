import React from 'react';

import { ActiveTab, InactiveTab } from './styles';

const NavTab = ({ active, to, children }) =>
  active ? (
    <ActiveTab to={to}>{children}</ActiveTab>
  ) : (
    <InactiveTab to={to}>{children}</InactiveTab>
  );

export default NavTab;
