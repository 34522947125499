import GoogleAnalytics from 'react-ga4';
import { eventCategories } from '../../util/analytics';

const DOWNLOAD_EVENT = "Download"

export async function trackResourceDownload({label, filename}) {
  GoogleAnalytics.event({ category: eventCategories.ADMIN, label, action: DOWNLOAD_EVENT });
  const { analytics } = (window as any);
  const data = { label, filename };
  return analytics.track(`${eventCategories.ADMIN} - ${DOWNLOAD_EVENT}`, data);
}
