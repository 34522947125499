import React from 'react';
import styled from 'styled-components';

import PaginationLink from '../PaginationLink';

import './style.css';

const Li = styled.li`
  display: inline;
  list-style-type: none;
`;

const Nav = styled.nav`
  text-align: center;
`;

const Ul = styled.ul`
  padding-left: 0;
`;

interface PaginationProps {
  current: number,
  onClick: (...args: any[]) => any,
  totalPages: number,
};

const Pagination: React.FC<PaginationProps> = props => {
  const {
    current = 1,
    totalPages,
    onClick,
  } = props;

  const first = 1;
  const total = totalPages;
  const disableFirst = current === first;
  const disableLast = current === total;
  const pages = pageBuffer(current, total);
  const showNext = !pages.includes(total);
  const showPrevious = !pages.includes(first);

  return (
    <Nav className="pagination">
      <Ul>
        <PaginationLink onClick={onClick} disabled={disableFirst} page={first}>
          ←
        </PaginationLink>
        {showPrevious && (
          <span>
            <PaginationLink
              onClick={onClick}
              selected={current === first}
              page={first}
            >
              {first}
            </PaginationLink>
            <Li>
              <span className="pagination-ellipsis">&hellip;</span>
            </Li>
          </span>
        )}
        {pages.map(elem => (
          <PaginationLink
            key={elem}
            onClick={onClick}
            selected={current === elem}
            page={elem}
          >
            {elem}
          </PaginationLink>
        ))}
        {showNext && (
          <span>
            <Li>
              <span>&hellip;</span>
            </Li>
            <PaginationLink
              onClick={onClick}
              selected={current === total}
              page={total}
            >
              {total}
            </PaginationLink>
          </span>
        )}
        <PaginationLink disabled={disableLast} onClick={onClick} page={total}>
          →
        </PaginationLink>
      </Ul>
    </Nav>
  );
}

export const pageBuffer = (current: number, totalPages: number): number[] => {
  const buffer = 5;
  const firstPage = 1;
  const isFirstBuffer = current - buffer < firstPage;
  const isLastBuffer = current + buffer > totalPages;

  if (totalPages <= buffer) {
    return Array(totalPages)
      .fill(undefined)
      .map((_, i) => i + 1);
  }
  if (isFirstBuffer) {
    const ceiling = firstPage + buffer + 1;
    const floor = firstPage;
    return Array(ceiling - floor)
      .fill(undefined)
      .map((_, i) => i + floor);
  }
  if (isLastBuffer) {
    const ceiling = totalPages;
    const floor = totalPages - buffer - 1;
    return Array(ceiling - floor)
      .fill(undefined)
      .map((_, i) => 1 + i + floor);
  }

  const ceiling = current + Math.ceil(buffer / 2);
  const floor = current - Math.floor(buffer / 2);
  return Array(ceiling - floor)
    .fill(undefined)
    .map((_, i) => i + floor);
};

export default Pagination;
