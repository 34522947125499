import React from 'react';
import styled from 'styled-components';
import { Field } from 'redux-form';

import calculateRem from '../../styles/calculateRem';

import { BetaHeading } from '../Typography';
import { ButtonTransparent } from '../Button';

const PolarHeading = styled(BetaHeading)`
  margin-bottom: ${calculateRem(24)};
`;

const PolarQuestion = ({ question, options, handleClick, ...rest }) => (
  <StyledFieldSet>
    <PolarHeading>{question}</PolarHeading>
    <StyledList>
      {options.map(opt => (
        <li key={`polar-option-${opt.label}`}>
          <PolarQuestionOption
            handleClick={() => handleClick(opt.value)}
            {...opt}
            {...rest}
          />
        </li>
      ))}
    </StyledList>
  </StyledFieldSet>
);

const StyledFieldSet = styled.fieldset`
  border: none;
`;

const StyledPolarQuestionOption = styled(ButtonTransparent)`
  width: 10em;
  font-weight: bold;
  padding: 0;
  margin: ${calculateRem(16)} auto;
  margin-bottom: ${calculateRem(32)};
  flex: 1 1 0px;
  &:first-of-type {
    margin-top: 0;
  }
`;

const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

const PolarQuestionOption = ({ label, name, handleClick, ...rest }) => (
  <Field
    {...rest}
    component={props => <StyledPolarQuestionOption {...props} />}
    name={name}
    onClick={e => {
      e.preventDefault();
      handleClick();
    }}
  >
    {label}
  </Field>
);

PolarQuestionOption.defaultProps = {};

PolarQuestion.defaultProps = {};

export default PolarQuestion;
