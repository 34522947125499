import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

const StyledSpan = styled.span`
  background: ${colors.error};
  display: block;
  margin-bottom: ${calculateRem(12)};
  border-radius: 8px;
  color: ${colors.white};
  padding-left: ${calculateRem(12)};
  padding-right: ${calculateRem(12)};
`;

// TODO: It looks like there are some inconsistencies in how this component is used. Sometimes `meta` is passed in to
// provider error strings, and sometimes the error string is given explicitly.
interface ValidationErrorProps {
  error?: string,
  meta?: {
    submitFailed: boolean,
    error: string,
  },
};

const ValidationError: React.FC<ValidationErrorProps> = props => {
  const error = props.error || (props.meta || {}).error;
  const submitFailed = (props.meta || {}).submitFailed;
  if (error && submitFailed) {
    return <StyledSpan>{error || props.error}{props.children}</StyledSpan>;
  }
  return null;
}

export default ValidationError;
