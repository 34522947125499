import styled from "styled-components";
import Section from "../../../../components/Section";
import { AlphaHeading } from "../../../../components/Typography";
import mediaQueries from "../../../../styles/mediaQueries";
import {colors} from "../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const HeaderSection = styled(Section)`
  text-align: center;
  ${mediaQueries.mobile`
    width: auto;
  `};
`;

export const HeaderHeading = styled(AlphaHeading)`
  margin-bottom: 12px;
  margin-left: 50px;
`;

export const Card = styled.div`
  color: ${colors.black};
  background: ${colors.white};
  width: 140px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 20px 40px;
    width: 260px;
  }
`;

export const CardSpacer = styled.div`
  max-width: 480px;
  min-width: 200px;
  flex-grow: 1;
  flex-basis: 0;
  @media (max-width: ${cardStackBreakpoint}) {
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const CardsSection = styled(Section)`
  display: flex;
  justify-content: space-between;
  
  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    align-items: center;
  }
`;