import React from 'react';
import isProductionMode from '../../util/helper';

export const QuoteSuccessPixel = () =>
  isProductionMode() ? (
    <img
      src="https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp=10048102&url=www.karista.com.au%2Fkaristayahooconv&js=no"
      alt="yahoo_gemini"
      width="1"
      height="1"
    />
  ) : null;

export const LandingPixel = () =>
  isProductionMode() ? (
    <img
      src="https://sp.analytics.yahoo.com/spp.pl?a=10000&.yp=10048102&url=www.karista.com.au%2Fkaristayahoortg&js=no"
      alt="yahoo_gemini"
      width="1"
      height="1"
    />
  ) : null;
