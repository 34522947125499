import React from 'react';
import Theme from '../../../../../components/Theme';
import { themeSecondary } from '../../../../../styles/constants';
import HCPLevelIcon from './elderly-man-beard.svg';
import HCPTable from './hcp-levels-table.svg';
import HCPTableMobile from './hcp-levels-table-mobile.svg';

import {
    NDISOtherText,
    NDISFAQSection,
    FAQInfoText,
    SupportCategorySection,
    NDISFAQParagraph,
    HCPLevelTable,
    HCPLevelTableSection,
    HCPLevelSection,
    HCPLevelsSection,
    HCPTableIcon,
    HCPTableIconContainer
} from "../../../style"

const HCPLevels = () => {

  return (
    <Theme theme={themeSecondary}>
        <HCPLevelSection>
            <HCPLevelsSection>
                <HCPTableIconContainer>
                    <HCPTableIcon src={HCPLevelIcon} alt={"Core Supports"} />
                </HCPTableIconContainer>
                <div>
                <SupportCategorySection>
                    <NDISOtherText style={{ paddingBottom: '16px' }}>
                        <b>Home Care Package levels</b>
                    </NDISOtherText>
                </SupportCategorySection>
                <NDISFAQSection>
                    <FAQInfoText>There are four levels of home care packages to meet different levels of care needs.</FAQInfoText>
                    <NDISFAQParagraph>The more support you need, the more aged care funding you receive to access the services you require. 
                        Home Care Packages are designed to be flexible and adaptable to your needs at any given time. </NDISFAQParagraph>
                </NDISFAQSection>
                </div>
            </HCPLevelsSection>
            <HCPLevelTableSection>
                {window.innerWidth > 768 ? (
                    <HCPLevelTable  src={HCPTable} alt="Karista can connect you to providers with availability" />
                ) : (<HCPLevelTable  src={HCPTableMobile} alt="Karista can connect you to providers with availability" />)}
            </HCPLevelTableSection>
        </HCPLevelSection>
    </Theme>
  );
}

export default HCPLevels;