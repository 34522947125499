/* eslint-disable no-confusing-arrow */

import React, { useState } from 'react';
import SocialLink from '../SocialLink';

import {
  StyledFooter,
  SubStyledFooter,
  PolicySection,
  StyledSpan,
  FooterNavLink,
  Anchor,
  Separator,
  ContactUs,
  ContactDropdownContainer,
  ContactDropdown,
  ListItem,
  ResourcesSection,
  ResourceHeader,
  ResourcesLinkSection,
  ResourceSubHeader
 } from './style'

const Footer = ({ children }) => {
  const [showContactDropdown, setShowContactDropdown] = useState(false);

  const toggleContactDropdown = () => {
    setShowContactDropdown(!showContactDropdown);
  };

  const openChat = e => {
    e.preventDefault();
    var chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  return (
    <>
      <StyledFooter>
        {children}
        <ResourcesSection>
          <ResourcesLinkSection>
              <ResourceHeader><b>Resources</b></ResourceHeader>
              <Anchor
                key={`footer-link-${"/about"}`}
                href={"/about"}
                target={"_self"}
              >
                <StyledSpan key={`footer-content-${"About Us"}`}>
                  {"About Us"}
                </StyledSpan>
              </Anchor>
              <Anchor
                  key={`footer-link-${"https://info.karista.com.au/blog/"}`}
                  href={"https://info.karista.com.au/blog/"}
                  target={"_self"}
                >
                  <StyledSpan key={`footer-content-${"Blog"}`}>
                    {"Blog"}
                  </StyledSpan>
                </Anchor>
                <Anchor
                    key={`footer-link-${"/complaints-and-feedback"}`}
                    href={"/complaints-and-feedback"}
                    target={"_self"}
                  >
                    <StyledSpan key={`footer-content-${"Make a complaint"}`}>
                      {"Make a complaint"}
                    </StyledSpan>
                </Anchor>
          </ResourcesLinkSection>
          <ResourcesLinkSection>
            <ResourceHeader><b>NDIS Participants</b></ResourceHeader>
            <Anchor
              key={`footer-link-${"/funding-information/national-disability-insurance-scheme"}`}
              href={"/funding-information/national-disability-insurance-scheme"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Funding Information"}`}>
                {"Funding Information"}
              </StyledSpan>
            </Anchor>
            <br />
            <ResourceSubHeader><b>Popular NDIS searches:</b></ResourceSubHeader>
            <Anchor
              key={`footer-link-${"/services/behaviour-support"}`}
              href={"/services/behaviour-support"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Behaviour Support"}`}>
                {"Behaviour Support"}
              </StyledSpan>
            </Anchor>
            <Anchor
              key={`footer-link-${"/services/occupational-therapy"}`}
              href={"/services/occupational-therapy"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Occupational Therapy"}`}>
                {"Occupational Therapy"}
              </StyledSpan>
            </Anchor>
            <Anchor
              key={`footer-link-${"/services/speech-therapy"}`}
              href={"/services/speech-therapy"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Speech Therapy"}`}>
                {"Speech Therapy"}
              </StyledSpan>
            </Anchor>
            <Anchor
              key={`footer-link-${"/services/psychology"}`}
              href={"/services/psychology"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Psychology"}`}>
                {"Psychology"}
              </StyledSpan>
            </Anchor>
            <Anchor
              key={`footer-link-${"/services/counselling"}`}
              href={"/services/counselling"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Counselling"}`}>
                {"Counselling"}
              </StyledSpan>
            </Anchor>
          </ResourcesLinkSection>
          <ResourcesLinkSection>
            <ResourceHeader><b>HCP Recipients</b></ResourceHeader>
            <Anchor
              key={`footer-link-${"/funding-information/home-care-package-funding"}`}
              href={"/funding-information/home-care-package-funding"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Funding Information"}`}>
                {"Funding Information"}
              </StyledSpan>
            </Anchor>
          </ResourcesLinkSection>
          <ResourcesLinkSection>
            <ResourceHeader><b>Providers</b></ResourceHeader>
            <Anchor
              key={`footer-link-${"/admin/login"}`}
              href={"/admin/login"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Provider Login"}`}>
                {"Provider Login"}
              </StyledSpan>
            </Anchor>
            <Anchor
              key={`footer-link-${"/list-your-organisation/enquire-now"}`}
              href={"/list-your-organisation/enquire-now"}
              target={"_self"}
            >
              <StyledSpan key={`footer-content-${"Enquire"}`}>
                {"Enquire"}
              </StyledSpan>
            </Anchor>
          </ResourcesLinkSection>
        </ResourcesSection>
        <SocialLink />
      </StyledFooter>
      <SubStyledFooter>
        <PolicySection>
        {window.innerWidth > 768 ? (
            <>
              <StyledSpan block>
              Copyright &#169; 2024 Karista Pty Ltd. All rights reserved. ABN 92614763076
              <FooterNavLink>
                  <Anchor
                    key={`footer-link-${"/terms-and-conditions"}`}
                    href={"/terms-and-conditions"}
                    target={"_self"}
                  >
                  <StyledSpan key={`footer-content-${"Terms and Conditions"}`}>
                    {"Terms and Conditions"}
                  </StyledSpan>
                  </Anchor>
                  <Separator>|</Separator>
                  <Anchor
                      key={`footer-link-${"/privacy-policy"}`}
                      href={"/privacy-policy"}
                      target={"_self"}
                      style={{marginLeft: '8px'}}
                    >
                    <StyledSpan key={`footer-content-${"Privacy Policy"}`}>
                      {"Privacy Policy"}
                    </StyledSpan>
                  </Anchor>
                  <Separator>|</Separator>
                  <Anchor
                      key={`footer-link-${"/moderation-policy"}`}
                      href={"/moderation-policy"}
                      target={"_self"}
                      style={{marginLeft: '8px'}}
                    >
                    <StyledSpan key={`footer-content-${"Moderation Policy"}`}>
                      {"Moderation Policy"}
                    </StyledSpan>
                  </Anchor>
                </FooterNavLink>
              </StyledSpan>
              <ContactUs onClick={toggleContactDropdown}>
                <b>Contact Us</b>
              </ContactUs>
            </>) :
            (<>
                <ContactUs onClick={toggleContactDropdown}>
                  <b>Contact Us</b>
                </ContactUs>
                <br />
                <StyledSpan block>
                <FooterNavLink>
                  <Anchor
                    key={`footer-link-${"/terms-and-conditions"}`}
                    href={"/terms-and-conditions"}
                    target={"_self"}
                  >
                  <StyledSpan key={`footer-content-${"Terms and Conditions"}`}>
                    {"Terms and Conditions"}
                  </StyledSpan>
                  </Anchor>
                  <Anchor
                      key={`footer-link-${"/privacy-policy"}`}
                      href={"/privacy-policy"}
                      target={"_self"}
                    >
                    <StyledSpan key={`footer-content-${"Privacy Policy"}`}>
                      {"Privacy Policy"}
                    </StyledSpan>
                  </Anchor>
                  <Anchor
                      key={`footer-link-${"/moderation-policy"}`}
                      href={"/moderation-policy"}
                      target={"_self"}
                    >
                    <StyledSpan key={`footer-content-${"Moderation Policy"}`}>
                      {"Moderation Policy"}
                    </StyledSpan>
                  </Anchor>
                  <br />
                  <p>Copyright &#169; 2024 Karista Pty Ltd</p>
                  <p>All rights reserved</p>
                  <p>ABN 92614763076 </p>
                </FooterNavLink>
              </StyledSpan>
            </>
        )}  
        {showContactDropdown && (
          <ContactDropdownContainer>
            <ContactDropdown>
              <ul style={{ listStyleType: 'disc', paddingLeft: '20px' }}>
                <ListItem>
                  <StyledSpan block>
                    <Anchor href="tel:+61485972676"> Call us on 0485 972 676</Anchor>
                  </StyledSpan>
                </ListItem>
                <ListItem>
                  <StyledSpan block>
                    <Anchor href="mailto:info@karista.com.au?Subject=Contact%20from%20karista.com.au">
                      Email us
                    </Anchor>
                  </StyledSpan>
                </ListItem>
                <ListItem>
                  <StyledSpan block>
                    <Anchor href="" onClick={openChat}>
                      Start a Live chat here
                    </Anchor>
                  </StyledSpan>
                </ListItem>
              </ul>
            </ContactDropdown>
          </ContactDropdownContainer>
        )}
        </PolicySection>
      </SubStyledFooter>
    </>
  );
};

Footer.defaultProps = {
  children: null
};

export default Footer;
