import React from 'react';
import styled from 'styled-components';

import { Centered } from '../../../components/Layout';
import calculateRem from '../../../styles/calculateRem';
import mediaQueries from '../../../styles/mediaQueries';
import { DeltaHeading } from '../../../components/Typography';
import Link from '../../../components/Link';
import Section from '../../../components/Section';
import { PageNotFoundInner } from '../../Static/PageNotFound';
import { RouteComponentProps } from 'react-router';
import { useGetProviderDetailsUsingSlugQuery } from '../../../services/karista';
import {skipToken} from "@reduxjs/toolkit/query/react";

const StyledSection = styled(Section)`
  margin: 0;
  padding: 0;
  max-width: 100%;
`;

const CenteredWithGutters = styled(Centered)`
  max-width: ${calculateRem(800)};
  ${mediaQueries.mobile`
    width: 100%;
    margin: 0;
  `};
`;

const LeftAligned = styled(Centered)`
  text-align: left;
`;

const StyledSubmittedHeading = styled(DeltaHeading)`
  padding-top: ${calculateRem(56)};
  text-align: left;
`;

const StyledSubmittedSection = styled(StyledSection)`
  margin: 0;
  padding: 0;
  max-width: 100%;
  padding-top: ${calculateRem(32)};
  padding-bottom: ${calculateRem(48)};
`;

const BackToProvider = styled(Link)`
  background: rgba(255, 176, 0, 1);
  color: #3e0f48;
  text-decoration: none;
  font-weight: 500;
  font-size: ${calculateRem(18)};
  padding: 13px 25px;
  border-radius: 4px;
`;

interface ReviewConfirmedProps extends RouteComponentProps<{id?: string, slug?: string}> {
};

const ReviewConfirmed: React.FC<ReviewConfirmedProps> = props => {
  const {
    match: {
      params: { id: providerId, slug: providerSlug },
    },
  } = props;

  // TODO: Should be ensuring we actually have an ID.
  const {isError} = useGetProviderDetailsUsingSlugQuery(providerSlug as string);

  if (!!isError) {
    return <PageNotFoundInner />;
  }

  return (
    <CenteredWithGutters>
      <LeftAligned>
        <StyledSubmittedHeading>
          Thanks for confirming your email address.
        </StyledSubmittedHeading>
        <StyledSubmittedSection>
          <p>
            If you agreed to make your review publicly viewable, it will appear on
            Karista once it has been reviewed by our review team.
          </p>
        </StyledSubmittedSection>
      </LeftAligned>
      <StyledSection>
        <BackToProvider
          to={{
            pathname: `/providers/${providerSlug}/${providerId}`,
            state: { previousUrl: `/providers/${providerSlug}/${providerId}/review` }
          }}
        >
          Back to Provider
        </BackToProvider>
      </StyledSection>
    </CenteredWithGutters>
  );
}

export default ReviewConfirmed;
