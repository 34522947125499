/* eslint-disable */

import React from 'react';
import styled from 'styled-components';

import ValidationError from '../ValidationError';
import { sizes } from '../../styles/constants';
import { isEmpty } from 'lodash';

import down from './chevron-down.svg';

import styles from './style.module.css';

const SelectContainer = styled.article`
  position: relative;
`;

const Chevron = styled.img`
  position: absolute;
  pointer-events: none;
  margin: auto;
  top: 0;
  bottom: 0;
  right: 0;
  padding-right: 24px;
`;

Chevron.defaultProps = {
  alt: 'Open the select dropdown'
};

const placeholder =
  window.innerWidth > sizes.mobile ? (
    <option disabled={false} selected value="" label="Choose" />
  ) : (
    <option disabled={false} selected value="" label="Tap to choose" />
  );

const Select = ({ input, fieldOptions, ...props }) => (
  <div>
    {!isEmpty(fieldOptions) && (
      <div>
        <div>
          <SelectContainer>
            <select {...props} {...input} className={styles.select}>
              {placeholder}
              {fieldOptions.map(option => (
                <option key={option.value} value={option.value}>
                  {option.label}
                </option>
              ))}
            </select>
            <Chevron src={down} />
          </SelectContainer>
          <ValidationError {...props} />
        </div>
      </div>
    )}
  </div>
);

export default Select;
