import styled, { css } from 'styled-components';
import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

export const TagContainer = styled.div`
  line-height: ${calculateRem(36)};
  font-size: ${calculateRem(18)};
  background: ${colors.purple};
  color: ${colors.white};
  border-radius: 6px;
  padding: 0 ${calculateRem(12)};
  margin-bottom: ${calculateRem(12)};
  margin-right: ${calculateRem(12)};
  max-width: 100%;
  display: inline-flex;
  justify-content: space-between;
  align-items: center;
`;

interface SpanIconProps {
  isRemoveable?: boolean,
};

export const SpanIcon = styled.div<SpanIconProps>`
  display: flex;
  justify-content: center;
  align-items: center;
  width: 18px;
  margin-left: ${calculateRem(12)};
  cursor: pointer;
  img {
    height: 18px;
    width: 18px;
  }
  ${props =>
    !props.isRemoveable
      ? css`
          display: none;
        `
      : ''};
`;

export const TagContent = styled.span`
  flex: 1;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`;
