import React from 'react';

import Theme from '../../../../components/Theme';
import { themeLightGrey, themeSecondary } from '../../../../styles/constants';
import { useCreateComplaintAndFeedbackMutation } from '../../../../services/karista';
import ComplaintsAndFeedbackQuestions from '../ComplaintsAndFeddbackQuestions';

import {
    DataSection
} from "../ComplaintsAndFeedback/style"

import {
    HeaderTextPurple,
    HeaderText,
    FormSection,
    HeaderSection,
    BetaHeaderHeading
} from './style'

const ComplaintsAndFeedbackForm = props => {
    const [createComplaintAndFeedback] = useCreateComplaintAndFeedbackMutation();
    
    function onSubmit(formData) {
        const submitData = Object.assign(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
            {},
            { ...formData },
        );
        createComplaintAndFeedback(submitData)
            .unwrap()
            .then(async (res) => {
                console.log(res)
                return res;
              })
            .then(() => {
                window.location.href = "/complaints-and-feedback-submission-success"
            });
        }

  return (
    <>
    {window.innerWidth > 768 ? (
        <Theme theme={themeLightGrey}>
            <DataSection>
                <HeaderSection>
                    <BetaHeaderHeading id="FeedbackAndComplaintForm">Feedback and Complaints Form</BetaHeaderHeading>
                    <FormSection>
                        <HeaderTextPurple>
                            <b>Complete this form</b>
                        </HeaderTextPurple>
                        <HeaderText>
                            Please answer these questions so we can work with you to resolve any issues.
                        </HeaderText>
                        <ComplaintsAndFeedbackQuestions 
                            onSubmit={onSubmit}
                        />
                    </FormSection>
                </HeaderSection>
            </DataSection>
        </Theme>
    ) :(
        <Theme theme={themeSecondary}>
            <DataSection>
                <HeaderSection>
                    <BetaHeaderHeading>Feedback and Complaints Form</BetaHeaderHeading>
                    <FormSection>
                        <HeaderTextPurple id="FeedbackAndComplaintForm">
                            <b>Complete this form</b>
                        </HeaderTextPurple>
                        <HeaderText>
                            Please answer these questions so we can work with you to resolve any issues.
                        </HeaderText>
                        <ComplaintsAndFeedbackQuestions
                            onSubmit={onSubmit}
                        />
                    </FormSection>
                </HeaderSection>
            </DataSection>
        </Theme>
    )}
    </>
  );
}

export default ComplaintsAndFeedbackForm;