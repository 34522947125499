import React from 'react';
import { Switch, RouteComponentProps } from 'react-router-dom';
import { bindActionCreators } from 'redux';
import { connect } from 'react-redux';
import { createSelector } from 'reselect';

import * as actions from './actions';
import { ProvidersActions } from './actions';
import * as selectors from './selectors';
import ComposedRoute from '../../components/ComposedRoute';
import Detail, { DetailProps } from './containers/detail';
import Footer from '../../components/Footer';
import List, { ListProps } from './containers/list';
import Navigation from '../../components/Navigation';
import Page from '../../components/Page';
import ProviderReviews, { ReviewsListProps } from './containers/reviews';
import QuoteSuccess from './containers/quote_success';
import RequestQuote, { RequestQuoteProps } from './containers/request_quote';
import RequestQuoteMultiple, { RequestQuoteMultipleProps } from './containers/request_quote_multiple';
import Review, { ReviewProps } from './containers/review';
import ReviewConfirmed from './containers/review_comfirmed';
import AdditionalQuestions, { AdditionalQuoteQuestionProps } from './containers/additional_questions'
import Theme from '../../components/Theme';
import { OrangeLink } from '../../components/Link';
import { themeSecondary } from '../../styles/constants';

import api from '../../services/karista';
import { reduceQueries } from '../../util/rtk-query';

import {
  Postcode,
  ApiError,
  RequestQuoteResult,
  Query,
} from '../../state/types';
import {QuoteDetail, QuoteDetailProps} from "./containers/quote_detail";

interface ProvidersProps extends RouteComponentProps {
  loading: boolean,
  postcodesDetail?: Postcode,
  requestQuoteResult: RequestQuoteResult,
  actions: ProvidersActions,
  providerId: string,  // TODO: What is this?
  providerSlug: string,
  providerReviewError: ApiError | boolean | null,
  query: Query,
};

const useInitialize = () => reduceQueries({
  ageGroups: api.useGetAllAgeGroupsQuery(),
  fundingTypes: api.useGetAllFundingTypesQuery(),
  services: api.useGetAllServicesQuery(),
  serviceAreas: api.useGetAllServiceAreasQuery(),
  hcpServiceAreas: api.useGetAllHCPServiceAreasQuery(),
  diagnosis: api.useGetAllDiagnosisQuery()
});

/* TODO eventually DRY up the apps */
const Providers: React.FC<ProvidersProps> = props => {
  const { ageGroups, fundingTypes, services, serviceAreas, hcpServiceAreas, diagnosis, isLoading } = useInitialize();
  // TODO: Here is where we should be showing a placeholder while
  // we're loading.
  React.useEffect(() => {
    return () => { props.actions.destroy() };
  }, []);

  React.useEffect(() => {
    window.scrollTo(0, 0);
  }, [props.location.pathname]);

  if (isLoading || !services.data || !serviceAreas.data || !hcpServiceAreas.data || !diagnosis.data) {
    return (
        <Theme theme={themeSecondary}>
          <Navigation>
            <OrangeLink border to="/">
              Back to search
            </OrangeLink>
          </Navigation>
          <Page />
        </Theme>
    )
  };
  return (
    <Theme theme={themeSecondary}>
      <Navigation>
        <OrangeLink border to="/">
          Back to search
        </OrangeLink>
      </Navigation>
      <Page>
        <ComposedRoute<ListProps>
          exact
          path="/providers"
          component={List}
          ageGroups={ageGroups.data}
          fundingTypes={fundingTypes.data}
          services={services.data}
          {...props}
        />
        <Switch>
          <ComposedRoute<RequestQuoteMultipleProps>
            exact
            path="/providers/request-quote"
            component={RequestQuoteMultiple}
            serviceAreas={serviceAreas.data}
            {...props}
          />
          <ComposedRoute<QuoteDetailProps>
            exact
            path="/providers/quote-detail/:id"
            component={QuoteDetail}
            {...props}
          />
          <ComposedRoute<AdditionalQuoteQuestionProps>
            exact
            path="/providers/quote-detail/additional-questions/:id"
            component={AdditionalQuestions}
            serviceAreas={serviceAreas.data}
            hcpServiceAreas={hcpServiceAreas.data}
            diagnosis={diagnosis.data}
            {...props}
          />
          <ComposedRoute
            exact
            path="/providers/quote-success"
            component={QuoteSuccess}
            {...props}
          />
          <ComposedRoute<DetailProps>
            exact
            path="/providers/:slug/:id"
            component={Detail}
            ageGroups={ageGroups.data}
            fundingTypes={fundingTypes.data}
            services={services.data}
            {...props}
          />
        </Switch>
        <ComposedRoute<ReviewProps>
          exact
          path="/providers/:slug/:id/review"
          component={Review}
          {...props}
        />
        <ComposedRoute
          exact
          path="/providers/:slug/:id/review/confirmed"
          component={ReviewConfirmed}
          {...props}
        />
        <ComposedRoute<RequestQuoteProps>
          exact
          path="/providers/:slug/:id/request-quote"
          component={RequestQuote}
          services={services.data}
          serviceAreas={serviceAreas.data}
          {...props}
        />
        <ComposedRoute<ReviewsListProps>
          exact
          path="/providers/:slug/:id/reviews"
          component={ProviderReviews}
          {...props}
        />
      </Page>
      <Footer />
    </Theme>
  );
}

const mapStateToProps = createSelector(
  selectors.selectLoading,
  selectors.selectQuery,
  (
    loading,
    query,
  ) => ({
    loading,
    query,
  })
);

const mapDispatchToProps = dispatch => ({
  actions: bindActionCreators(actions, dispatch)
});

export default connect(mapStateToProps, mapDispatchToProps)(Providers);
