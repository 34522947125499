import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';

// eslint-disable-next-line
export const Centered = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  padding: 0;
  text-align: center;
`;

export const CenteredContainer = styled.section`
  max-width: ${calculateRem(720)};
  margin: 0 auto;
`;

export const CenteredWithGutters = styled(Centered)`
  max-width: ${calculateRem(720)};
  margin: ${calculateRem(48)} auto ${calculateRem(12)};
  padding: 0 ${calculateRem(16)};
  text-align: left;
  align-items: center;
`;
