import styled from 'styled-components';
import { colors, sizes } from '../../styles/constants';

const gutter = 24;

const InfoCard = styled.div`
  background: ${colors.lightGrey};
  border-radius: 6px 6px 6px 6px;
  max-width: ${sizes.tablet}px;
  margin-bottom: ${gutter}px;
  padding: ${gutter}px;
  color: ${colors.darkPurple};
`;

export const Header = styled.div`
  color: ${colors.purple};
  font-size: 17px;
  text-align: center;
  margin-bottom: 10px;
`;

export const Body = styled.div`
  color: ${colors.lightPurple};
  font-size: 15px;
  text-align: center;
  padding: 3px;
`;

export const BodyLink = styled.div`
  color: ${colors.purple};
  font-size: 15px;
  padding: 3px;
`;

export const BodyContainer = styled.div`
  display: flex;
  text-align: center;
  justify-content: center;
`;

export default InfoCard;
