import React from 'react';
import DOMPurify from 'dompurify';

import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import ComplaintsAndFeedbackImage from './participants-web.svg';
import KaristaLogo from './karista_logo.svg'
import NDISLogo from './ndis-logo.svg'
import AgeCareLogo from './myagedcare-logo.svg'

import {
    ComplaintsAndFeedbackImageSection,
    HeaderSection,
    DataSection,
    CardsSection,
    CardSpacer,
    Card,
    CardInternalOthers,
    CardInternalNDIS,
    CardPurpleText,
    HeaderText,
    InformationText,
    CardIcon,
    CardIconContainer,
    CardText,
    ButtonLink,
    NDISCard,
    ButtonSection,
    BetaHeaderHeading
} from "./style"

const ComplaintButtons = ({ text, link }) => {
    const handleClick = () => {
        if (!link) {
            let formLoc = document.getElementById("FeedbackAndComplaintForm")
            formLoc && formLoc.scrollIntoView({
                behavior: 'smooth',
                block: 'start',
                inline: 'nearest'
            });
        }
    };

    return link ? (
        <ButtonLink as="a" href={link} target="_blank">
            <b>{text}</b>
        </ButtonLink>
    ) : (
        <ButtonLink as="a" onClick={handleClick}>
            <b>{text}</b>
        </ButtonLink>
    );
}

const ComplaintsAndFeedback = () => {
  return (
    <>
    <Theme theme={themeSecondary}>
        <DataSection>
            <HeaderSection>
                <BetaHeaderHeading>Feedback and Complaints</BetaHeaderHeading>
                <HeaderText>
                    <b>Your feedback is important to us! It helps us improve our services and ensure you’re getting the support you need.</b>
                </HeaderText>
                <HeaderText>
                    <b>If you have any concerns or would like to share your experience, we're here to listen.</b>
                </HeaderText>
                <HeaderText>
                    You can submit feedback or make a complaint directly to Karista, and we'll work with you to resolve any issues.
                </HeaderText>
                <HeaderText>
                    You can also reach out to the NDIS or My Aged Care if needed.
                </HeaderText>
            </HeaderSection>
            <ComplaintsAndFeedbackImageSection src={ComplaintsAndFeedbackImage} alt={"Disability Providers and Home Care Package"} />
        </DataSection>
        <DataSection>
        {window.innerWidth > 768 ? (
            <>
                <CardsSection>
                <CardSpacer>
                    <NDISCard>
                        <CardIconContainer>
                            <CardIcon src={NDISLogo} alt={"NDIS participants"} />
                        </CardIconContainer>
                        <CardInternalNDIS>
                            <CardPurpleText>{"NDIS participants"}</CardPurpleText>
                            <CardText 
                                dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
                                'You can contact the NDIS commission to provide feedback or make a complaint <a href="https://forms.business.gov.au/smartforms/servlet/SmartForm.html?formCode=PRD00-OCF" target="_blank">here</a>.',
                                { ADD_ATTR: ["target"] }) }} />
                            <CardText>
                                <InformationText><strong>Important information</strong></InformationText>
                                <InformationText>
                                    1. Karista is not an NDIS Provider and does not charge fees to NDIS Participants, 
                                    Karista charges the NDIS Provider a subscription and referral fee.
                                </InformationText>
                                <InformationText>
                                    2. Karista acknowledge the NDIS Worker Screening Clearance as the only formally recognised background screening check for the NDIS. 
                                    All NDIS Providers must comply with the NDIS Worker Screening Clearance and engage with this formal background check.
                                    </InformationText>
                                <InformationText> 
                                    3. If you have a complaint about an NDIS Provider, it is your responsibility to contact the NDIS Commission to make a complaint.
                                </InformationText>
                            </CardText>
                        </CardInternalNDIS>
                        <ComplaintButtons text="Make a complaint to the NDIS Commission" link="https://forms.business.gov.au/smartforms/servlet/SmartForm.html?formCode=PRD00-OCF" />
                    </NDISCard>
                </CardSpacer>
                <div>
                    <CardSpacer>
                        <Card>
                            <CardIconContainer>
                                <CardIcon src={AgeCareLogo} alt={"Home Care Package recipients"} />
                            </CardIconContainer>
                            <CardInternalOthers>
                                <CardPurpleText>{"Home Care Package recipients"}</CardPurpleText>
                                <CardText 
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
                                    'If you are a Home Care Package client, you can submit feedback or a complaint through <a href="https://www.myagedcare.gov.au/contact-us/complaints" target="_blank">My Aged Care</a>.',
                                    { ADD_ATTR: ["target"] }) }} />
                            </CardInternalOthers>
                            <ComplaintButtons text="Make a complaint to My Aged Care" link="https://www.myagedcare.gov.au/contact-us/complaints" />
                        </Card>
                    </CardSpacer>
                    <CardSpacer>
                        <Card>
                            <CardIconContainer>
                                <CardIcon src={KaristaLogo} alt={"Share your experience with Karista"} />
                            </CardIconContainer>
                            <CardInternalOthers>
                                <CardPurpleText>{"Share your experience with us"}</CardPurpleText>
                                <CardText> Complete the form below outlining your experience, and we’ll work with you to resolve any issues. </CardText>
                            </CardInternalOthers>
                            <ComplaintButtons 
                                text="Fill out form" 
                                link=""
                            />
                        </Card>
                    </CardSpacer>
                </div>
            </CardsSection>
            </>) :
            (<>
                <ButtonSection>
                    <ComplaintButtons text="Make a complaint to the NDIS Commission" link="https://forms.business.gov.au/smartforms/servlet/SmartForm.html?formCode=PRD00-OCF" />
                    <ComplaintButtons text="Make a complaint to the Aged Care" link="https://www.myagedcare.gov.au/contact-us/complaints" />
                    <ComplaintButtons text="Share an expereince with Karista" link="" />
                </ButtonSection>
                <CardsSection>
                    <CardSpacer>
                        <NDISCard>
                            <CardIconContainer>
                                <CardIcon src={NDISLogo} alt={"NDIS participants"} />
                            </CardIconContainer>
                            <CardInternalOthers>
                                <CardPurpleText>{"NDIS participants"}</CardPurpleText>
                                <CardText 
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
                                    'You can contact the NDIS commission to provide feedback or make a complaint <a href="https://forms.business.gov.au/smartforms/servlet/SmartForm.html?formCode=PRD00-OCF" target="_blank">here</a>.',
                                    { ADD_ATTR: ["target"] }) }} />
                                <CardText>
                                    <InformationText><strong>Important information</strong></InformationText>
                                    <InformationText>
                                        1. Karista is not an NDIS Provider and does not charge fees to NDIS Participants, 
                                        Karista charges the NDIS Provider a subscription and referral fee.
                                    </InformationText>
                                    <InformationText>
                                        2. Karista acknowledge the NDIS Worker Screening Clearance as the only formally recognised background screening check for the NDIS. 
                                        All NDIS Providers must comply with the NDIS Worker Screening Clearance and engage with this formal background check.
                                        </InformationText>
                                    <InformationText> 
                                        3. If you have a complaint about an NDIS Provider, it is your responsibility to contact the NDIS Commission to make a complaint.
                                    </InformationText>
                                </CardText>
                            </CardInternalOthers>
                            <ComplaintButtons text="Make a complaint to the NDIS Commission" link="https://forms.business.gov.au/smartforms/servlet/SmartForm.html?formCode=PRD00-OCF" />
                        </NDISCard>
                    </CardSpacer>
                    <CardSpacer>
                        <Card>
                            <CardIconContainer>
                                <CardIcon src={AgeCareLogo} alt={"Home Care Package recipients"} />
                            </CardIconContainer>
                            <CardInternalOthers>
                                <CardPurpleText>{"Home Care Package recipients"}</CardPurpleText>
                                <CardText 
                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(
                                    'If you are a Home Care Package client, you can submit feedback or a complaint through <a href="https://www.myagedcare.gov.au/contact-us/complaints" target="_blank">My Aged Care</a>.',
                                    { ADD_ATTR: ["target"] }) }} />
                            </CardInternalOthers>
                            <ComplaintButtons text="Make a complaint to the Aged Care" link="https://www.myagedcare.gov.au/contact-us/complaints" />
                        </Card>
                    </CardSpacer>
                    <CardSpacer>
                        <Card>
                            <CardIconContainer>
                                <CardIcon src={KaristaLogo} alt={"Share your experience with Karista"} />
                            </CardIconContainer>
                            <CardInternalOthers>
                                <CardPurpleText>{"Share your experience with us"}</CardPurpleText>
                                <CardText> Complete the form below outlining your experience, and we’ll work with you to resolve any issues. </CardText>
                            </CardInternalOthers>
                        </Card>
                    </CardSpacer>
                </CardsSection>
            </>
        )}
        </DataSection>
    </Theme>
    </>
  );
}

export default ComplaintsAndFeedback;