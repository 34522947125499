import React from 'react';
import { createGlobalStyle } from 'styled-components';

import Page from '../../components/Page';
import { colors } from '../../styles/constants';
import Theme from '../../components/Theme';
import { BetaHeading } from '../../components/Typography';
import FormAssemblyForm from '../../components/FormAssemblyForm';
import {
  Banner,
  CallUsSection,
  CenteredSection,
  CallUsPhoneNumberBlack,
  CallUsHeading,
  ParagraphBlack
} from './style';

const OverrideStyle = createGlobalStyle`
body {
  background: none;
  padding-top: 0;
}
`;

export const themeCareSquared = {
  background: colors.white,
  color: '#467A86'
};

const C2CContact = () => (
  <CallUsSection>
    <CallUsPhoneNumberBlack>0485 863 035</CallUsPhoneNumberBlack>
    <CallUsHeading>
      Call us Monday to Friday <br />
      9am to 5pm AEST
    </CallUsHeading>
  </CallUsSection>
);

const C2CForm = () => (
  <Theme theme={themeCareSquared}>
    <OverrideStyle />
    <Banner
      src="/static/c2c/connectheader.png"
      alt="Care Squared Connect Program"
    />
    <Page>
      <CenteredSection>
        <BetaHeading>
          Thank you for your interest in Care Squared Connect's 2024 NDIS
          Program
        </BetaHeading>
        <ParagraphBlack>
          {`Please complete the enquiry form and a member from our Client Services team will be
          in touch to discuss which program, date and time works for you.`}
        </ParagraphBlack>
        <FormAssemblyForm
          formId="5101871"
          intercomMessage={`I'd like more information about the C2 Connect Program`}
          successUrl="/c2c/success"
        />
      </CenteredSection>
    </Page>
    <C2CContact />
  </Theme>
);

export const C2CFormSuccess = () => (
  <Theme theme={themeCareSquared}>
    <OverrideStyle />
    <Banner
      src="/static/c2c/connectheader.png"
      alt="Care Squared Connect Program"
    />
    <Page>
      <CenteredSection>
        <BetaHeading>Thank you</BetaHeading>
        <ParagraphBlack>
          A member from our Client Services team will be in touch shortly.
        </ParagraphBlack>
      </CenteredSection>
    </Page>
    <C2CContact />
  </Theme>
);

export default C2CForm;
