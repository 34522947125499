import React from 'react';
import { RouteComponentProps } from 'react-router';
import styled from 'styled-components';

import StyledLink from '../../../components/Link';
import Section from '../../../components/Section';
import { BetaHeading } from '../../../components/Typography';
import calculateRem from '../../../styles/calculateRem';
import { resolveProviderServices, resolveProviderFundingTypes } from '../../../util/transforms';
import ProviderDetails from '../../../components/ProviderDetails';

import { Provider, Service, FundingType } from '../../../state/types';
import { useCurrentProviderQuery, useCurrentProviderUsingSlugQuery } from '../hooks';
import ReviewOption from '../../../components/ReviewOption';
import ProviderLogo from '../../../components/ProviderLogo';
import {colors} from "../../../styles/constants";


const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${calculateRem(10)};
`;

const HeaderSection = styled(Section)`
  padding-top: ${calculateRem(24)};
`;

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(24)};
`;

export const ProviderName = styled(BetaHeading)`
  color: ${colors.purple};
  margin-bottom: 0;
`;

const RightAligned = styled.div`
  margin: 0;
  text-align: right;
`;

const BackToResults = styled(StyledLink)`
  color: purple;
`;

export interface PreviewProviderProps extends RouteComponentProps<{id?: string, slug?: string}> {
  previousUrl?: string,
  services: Service[],
  fundingTypes: {[k: string]: FundingType},
}

interface InnerPreviewProviderProps extends PreviewProviderProps {
  provider: Omit<Provider, 'services'> & {services: Service[]},
  isLoading: boolean,
};

const usePreviewProviderProps = (props: PreviewProviderProps): InnerPreviewProviderProps => {
  const {
    services,
    match: {
      params: { id, slug },
    },
    fundingTypes,
  } = props;

  const {data: provider, isLoading} = useCurrentProviderQuery(Number(id));
  // const {data: provider, isLoading} = useCurrentProviderUsingSlugQuery(String(slug));
  return {
    ...props,
    isLoading,
    provider: resolveProviderServices(services, provider) as (Omit<Provider, 'services'> & {services: Service[]}),
    fundingTypes: resolveProviderFundingTypes(fundingTypes, provider || {}),
  };
}

const PreviewProvider: React.FC<PreviewProviderProps> = props => {
  const { provider, isLoading, services, fundingTypes, previousUrl } = usePreviewProviderProps(props);
  // TODO: This can be removed when we move to Suspense.
  if (isLoading || !(services && Object.keys(services).length > 0)) {
    return null;
  }

  return (
    <div>
      <HeaderSection>
        <RightAligned>
          <BackToResults
            to={{
              pathname: `/admin/providers/${provider.url_slug}/${provider.id}/edit`
            }}
          >
            back to edit profile
          </BackToResults>
        </RightAligned>
        <HeadingContainer>
          {provider.logo_url && <ProviderLogo src={provider.logo_url} />}
          <div>
            <ProviderName>{provider.name}</ProviderName>
            <ReviewOption
                id={provider.id}
                hasReviews={false}
                reviewsCount={provider.reviews_count as number}
                providerSlug={provider.url_slug}
                review={provider.overall_review}
                {...props}
              />
          </div>
        </HeadingContainer>
      </HeaderSection>
      <StyledSection>
        <ProviderDetails
          providerId={provider.id}
          services={provider.services}
          description={provider.description}
          isActive={provider.is_active}
          fundingTypes={Array.isArray(fundingTypes.funding_types)
            ? fundingTypes.funding_types
            : [fundingTypes.funding_types]}
          providerSlug={provider.url_slug}
        />
      </StyledSection>
    </div>
  );
}

export default PreviewProvider;
