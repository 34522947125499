import React from 'react';
import styled from 'styled-components';

import mediaQueries from '../../../../styles/mediaQueries';
import { BetaHeading } from '../../../../components/Typography';
import {colors} from "../../../../styles/constants";
import Section from '../../../../components/Section';
import TickIcon from './tick_icon.png'

const StyledTitle = styled(BetaHeading)`
  max-width: 700px;
  margin: 0;
  font-size: 16px;
  background-color: #EBCCF0;
  color: ${colors.darkPurple};

  ${mediaQueries.mobile`
    line-height: 36px;
    margin-top: 12px;
  `};
`;

// const ValuesSection = styled(Section)`
//   max-width: 500px;
//   height: fit-content;
//   margin-top: 12px;
//   margin-bottom: 4px;
//   display: flex;
//   flex-direction: row;
//   background-color: #EBCCF0;
//   border-radius: 18px;

//   ${mediaQueries.mobile`
//     max-width: 300px;
//     width: auto;
//     margin-top: 12px;
//     flex-direction: column;
//     align-items: center;
//     text-align: center;
//   `};
// `;
const ValuesSection = styled(Section)`
  max-width: 450px;
  margin-top: 36px;
  margin-bottom: 0px;
  display: flex;
  align-items: center;
  padding: 10px 16px;
  background-color: #EBCCF0;
  border-radius: 18px;

  ${mediaQueries.mobile`
    max-width: 300px;
    flex-direction: column;
    align-items: center;
    text-align: center;
  `};
`;

export const Small = styled.img`
  height: 30px;
  width: 30px;
  margin-right: 10px;

  ${mediaQueries.mobile`
    margin-top: 2px;
    margin-bottom: 8px;
  `};
`;
export const LogoIcon = () => (
  <Small alt="Karista Tick icon" src={TickIcon} />
);

const Values = () => {
  const defaultContent = 'FREE TO NDIS PARTICIPANTS AND HCP RECIPIENTS';
  return (
    <ValuesSection>
      <LogoIcon />
      <StyledTitle><b>{defaultContent}</b></StyledTitle>
    </ValuesSection>
  );
}

export default Values;
