import React from 'react';

import Footer from '../../../components/Footer';
import Navigation from '../../../components/Navigation';
import Page from '../../../components/Page';
import Section from '../../../components/Section';
import Theme from '../../../components/Theme';
import { themeSecondary } from '../../../styles/constants';
import { BetaHeading } from '../../../components/Typography';
import { ButtonLink } from '../../../components/Link';
import { Grid } from '../../../components/Grid';
import { Paragraph } from '../style';
import { FlexedGridItem, ImageHideOnMobile } from './style';
import { trackPageNotFound } from './tracking';

function PageNotFoundInner() {
  React.useEffect(() => {
    try {
      trackPageNotFound();
    } catch (e) {
      // do nothing
    }
  }, []);

  const openChat = e => {
    e.preventDefault();
    var chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  return (
    <Section style={{ textAlign: 'center' }}>
      <BetaHeading>Sorry, we couldn&apos;t find that page</BetaHeading>
      <Grid wrap="wrap" alignItems="flex-end">
        <FlexedGridItem mobile={12} tablet={6}>
          <Paragraph>
            {`Are you looking for a service provider in your area? `}
          </Paragraph>
          <ImageHideOnMobile
            src="https://res.cloudinary.com/karista/image/upload/c_pad,h_1502,w_1978/v1635122972/Website%20Assets/looking-search_2x.png"
            alt="A group of people with disabilities standing together"
          />
          <ButtonLink to={{ pathname: `/` }}>Find a provider</ButtonLink>
        </FlexedGridItem>
        <FlexedGridItem mobile={12} tablet={6}>
          <Paragraph>
            {`Do you want to be listed on the Karista website? `}
          </Paragraph>
          <ImageHideOnMobile
            src="https://res.cloudinary.com/karista/image/upload/v1635122972/Website%20Assets/listed-enquire_2x.png"
            alt="Person sitting at desk looking at computer screen with a smile"
          />
          <ButtonLink to={{ pathname: `/list-your-organisation` }}>
            Enquire here
          </ButtonLink>
        </FlexedGridItem>
      </Grid>
      <FlexedGridItem>
        <Paragraph>Is there something else we can help you with? </Paragraph>
        <ButtonLink to="/" onClick={openChat}>
          Get in touch
        </ButtonLink>
      </FlexedGridItem>
    </Section>
  );
}

function PageNotFound() {
  return (
    <Theme theme={themeSecondary}>
      <Navigation />
      <Page>
        <PageNotFoundInner />
      </Page>
      <Footer />
    </Theme>
  );
}

export default PageNotFound;
export { PageNotFoundInner };
