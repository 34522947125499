import React from 'react';
import { RouteComponentProps } from 'react-router';

import AdvancedSearchForm, { AdvancedSearchFormData } from '../../../../components/SearchForm/AdvancedSearchForm';
import BasicSearchForm, { BasicSearchFormData } from '../../../../components/SearchForm/BasicSearchForm';
import Section from '../../../../components/Section';

import karistaApi, { useGetAllAgeGroupsQuery, useGetAllFundingTypesQuery } from '../../../../services/karista';
import { reduceQueries } from '../../../../util/rtk-query';
import { useAppDispatch } from '../../../../state/hooks';
import { resetProviderSearchParams, setProviderSearchParams } from '../../../Providers/providersSlice';

const useInitialize = () => reduceQueries({
  ageGroups: useGetAllAgeGroupsQuery(),
  fundingTypes: useGetAllFundingTypesQuery(),
  serviceAreas: karistaApi.useGetAllServiceAreasQuery(),
});

interface SearchProps extends RouteComponentProps {
};

const Search: React.FC<SearchProps> = props => {
  const { history } = props;

  const { ageGroups, fundingTypes, serviceAreas, isLoading } = useInitialize();
  const dispatch = useAppDispatch();

  React.useEffect(() => {
    dispatch(resetProviderSearchParams());
  }, []);

  function handleSubmit(data: AdvancedSearchFormData | BasicSearchFormData) {
    const { query, ...filters } = data;
    dispatch(setProviderSearchParams({ query, filters }));
    history.push('/providers');
  }

  return (
    <Section>
      <AdvancedSearchForm
        ageGroups={ageGroups.data}
        fundingTypes={fundingTypes.data}
        serviceAreas={serviceAreas.data || null}
        onSubmit={handleSubmit}
      />
      <BasicSearchForm onSubmit={handleSubmit} />
    </Section>
  );
}

export default Search;
