/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable react/no-array-index-key */

import React, { useState } from 'react';
import styled from 'styled-components';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { each } from 'lodash';
import { isEmail, isEmpty } from 'validator';
import { withRouter, RouteComponentProps } from 'react-router';

import Button from '../../../../components/Button';
import Checkbox from '../../../../components/Checkbox';
import FormItem from '../../../../components/FormItem';
import Input from '../../../../components/Input';
import Label from '../../../../components/Label';
import Link from '../../../../components/Link';
import PolarQuestion from '../../../../components/PolarQuestion';
import Section, { SuccessText, SuccessTitle, SubLabel } from './styles';
import SteppedNavigation from '../../../../components/SteppedNavigation';
import Textarea from '../../../../components/Textarea';
import calculateRem from '../../../../styles/calculateRem';
import mediaQueries from '../../../../styles/mediaQueries';
import ValidationError from '../../../../components/ValidationError';
import { BetaHeading } from '../../../../components/Typography';
import { Centered } from '../../../../components/Layout';
import { useGetProviderDetailsQuery, useGetProviderDetailsUsingSlugQuery } from '../../../../services/karista';

const questionOptions = [
  { label: 'Yes', value: 1 },
  { label: 'Maybe', value: 0.5 },
  { label: 'No', value: 0 }
];

const CenteredWithGutters = styled(Centered)`
  max-width: ${calculateRem(500)};
  ${mediaQueries.mobile`
    width: 100%;
    margin: 0;
  `};
`;

const LeftAligned = styled(Centered)`
  text-align: left;
`;

const renderCheckbox = ({ ...props }) => (
  <Checkbox {...props}>
    I agree to the Karista{' '}
    <a href="/terms-and-conditions" target="_blank">
      Terms and Conditions
    </a>{' '}
    and{' '}
    <a href="/privacy-policy" target="_blank">
      Privacy Policy
    </a>
  </Checkbox>
);

export const AdditionalComments = ({
  handleSubmit,
  submitForm,
  submitting = false
}) => (
  <CenteredWithGutters>
    <LeftAligned>
      <BetaHeading>Complete review</BetaHeading>
      <FormItem>
        <Label htmlFor="comment" emphasis>
          Additional comments (optional)
        </Label>
        <Field
          component={Textarea}
          name="comment"
          placeholder="Type here"
          rows={3}
        />
      </FormItem>
      <FormItem>
        <ValidationError />
        <Field name="terms" component={renderCheckbox} />
      </FormItem>
      <FormItem>
        <Label htmlFor="email" emphasis>
          Your email address:
        </Label>
        <SubLabel>A validation email will be sent to this address.</SubLabel>
        <Field
          component={Input}
          name="email"
          placeholder="example@email.com"
          type="text"
        />
      </FormItem>
      <FormItem>
        <Button
          fullWidth
          onClick={handleSubmit(data => submitForm(data))}
          spinning={submitting}
        >
          Submit
        </Button>
      </FormItem>
    </LeftAligned>
  </CenteredWithGutters>
);

export const Success = ({ providerId, providerSlug }) => (
  <CenteredWithGutters>
    <LeftAligned>
      <SuccessTitle>Thank you for your feedback</SuccessTitle>
      <SuccessText>
        You will receive a confirmation email shortly to your specified email
        address. Please follow the steps in the email to complete your review.
      </SuccessText>
      <SuccessText>
        If you can’t find the email we sent, please check your junk mail folder.
      </SuccessText>
    </LeftAligned>
    <Link to={`/providers/${providerSlug}/${providerId}`}>
      <Button>Return to Provider</Button>
    </Link>
  </CenteredWithGutters>
);

const steps = [
  {
    field: 'service_delivery',
    completed: false,
    navigatableStep: true,
    props: {
      question: 'Was this service delivered in a timely manner?',
      options: questionOptions,
      name: 'service_delivery'
    },
    Component: PolarQuestion
  },
  {
    field: 'service_safety',
    completed: false,
    navigatableStep: true,
    props: {
      question: 'Was the service delivered in a safe and friendly manner?',
      options: questionOptions,
      name: 'service_safety'
    },
    Component: PolarQuestion
  },
  {
    field: 'communicated_effectively',
    completed: false,
    navigatableStep: true,
    props: {
      question: 'Do you feel the provider communicated with you effectively?',
      options: questionOptions,
      name: 'communicated_effectively'
    },
    Component: PolarQuestion
  },
  {
    field: 'good_value',
    completed: false,
    navigatableStep: true,
    props: {
      question: 'Was the service good value for money?',
      options: questionOptions,
      name: 'good_value'
    },
    Component: PolarQuestion
  },
  {
    field: 'would_recommend',
    completed: false,
    navigatableStep: true,
    props: {
      question: 'Would you recommend this service to others?',
      options: questionOptions,
      name: 'would_recommend'
    },
    Component: PolarQuestion
  },
  {
    Component: AdditionalComments
  },
  {
    Component: Success
  }
];

const initCompletedSteps = [
  { completed: false },
  { completed: false },
  { completed: false },
  { completed: false },
  { completed: false },
  { completed: false }
];

const toDecimal = (value, places = 1) =>
  Number.parseFloat(value).toFixed(places);
const maxSteps = () => steps.filter(s => s.navigatableStep).length;
const allSteps = () => steps.length;
const fetchProviderId = props => props.match.params.id;
const fetchProviderSlug = props => props.match.params.slug;

interface ReviewProviderFormProps {
  providerId: string,
  providerSlug:string,
  createProviderReview: (...args: any[]) => any,
  createProviderReviewStatus: {
    success?: boolean,
    message?: string,
  },
};

interface ReviewProviderFormState {
  currentStep: number,
  completedSteps: any[],
  hideCancelReview?: boolean,
};

const ReviewProviderForm = (props: InjectedFormProps<{}, ReviewProviderFormProps> & ReviewProviderFormProps & RouteComponentProps) => {
  const {
    handleSubmit,
    createProviderReviewStatus,
    createProviderReview,
  } = props;

  const [currentStep, setCurrentStep] = useState(0);
  const [completedSteps, setCompletedSteps] = useState(initCompletedSteps);
  const [hideCancelReview, setHideCancelReview] = useState(false);

  const submitForm = (data, createProviderReview) => {
    const slug = fetchProviderSlug(props);
    const id = fetchProviderId(props);
    const reviews = [
      'service_safety',
      'service_delivery',
      'communicated_effectively',
      'good_value',
      'would_recommend'
    ];

    // Convert the reviews to decimals. Note that we should not mutate
    // Redux data outside of a reducer, so we need to copy the data.
    const mutableData = { ...data };
    each(reviews, k => {
      mutableData[k] = toDecimal(mutableData[k]); // eslint-disable-line
    });

    return createProviderReview({ provider: id, ...mutableData })
      .unwrap()
      .then(() => completeFormSubmission());
  };

  const completeFormSubmission = () => {
    // show the success page
    // jump to the final step
    setHideCancelReview(true);
    setCurrentStep(allSteps() - 1);
  };

  const completeStep = (stepKey, field, value) => {
    const updatedCompletedSteps = [...completedSteps];
    updatedCompletedSteps[stepKey] = { completed: true };
    props.change(field, value);
    setCompletedSteps(updatedCompletedSteps);
    setCurrentStep(currentStep < steps.length ? currentStep + 1 : currentStep);
  };

  const handleNavigatePrevious = (nextStep) => {
    if (nextStep < currentStep) {
      const updatedCompletedSteps = [...completedSteps];
      for (let i = maxSteps(); nextStep < i; i -= 1) {
        updatedCompletedSteps[i] = { completed: false };
      }
      setCompletedSteps(updatedCompletedSteps);
      setCurrentStep(nextStep);
      setHideCancelReview(false);
    }
  };

  const id = fetchProviderId(props);
  const slug = fetchProviderSlug(props);
  const {data: providerData} = useGetProviderDetailsUsingSlugQuery(slug as string);


  return (
    <Section>
      <form>
        <Centered>
          <SteppedNavigation
            title={`Review ${providerData ? providerData.name : 'this provider'}`}
            completedSteps={completedSteps}
            currentStep={currentStep}
            maxSteps={maxSteps()}
            handleNavigatePrevious={(nextStep) => handleNavigatePrevious(nextStep)}
          >
            {steps.map((q, i) => {
              const { Component, props } = q;
              const finalProps = Object.assign({}, props, {
                handleClick: value => completeStep(i, q.field, value),
                submitForm: data =>
                  submitForm(data, createProviderReview),
                handleSubmit,
                providerId: id,
                providerSlug: slug,
              });
              return (
                <Component key={`navigation-step-${i}`} {...finalProps} />
              );
            })}
          </SteppedNavigation>
          <Centered>
          {createProviderReviewStatus && !createProviderReviewStatus.success && (
            <ValidationError
              meta={{
                submitFailed: !createProviderReviewStatus.success,
                error: createProviderReviewStatus.message || ""
              }}
            />)}
          </Centered>
          {!hideCancelReview ? (
            <CancelReview providerId={id} providerSlug={slug} />
          ) : null}
        </Centered>
      </form>
    </Section>
  );
};

const CancelReview = ({ providerId, providerSlug }) => (
  <Link to={`/providers/${providerSlug}/${providerId}`}>
    <div>cancel review</div>
  </Link>
);

export const validate = (values: any = {}) => {
  const errors: any = {};
  if (!isEmail(values.email || '')) {
    errors.email = 'Please enter a valid email below';
  }
  if (isEmpty(values.email || '')) {
    errors.email = 'Your email is required below';
  }
  if (!values.terms) {
    errors.terms = 'Please agree to the terms and conditions before proceeding';
  }
  return errors;
};

const formConfig = {
  fields: [
    'service_delivery',
    'service_safety',
    'communicated_effectively',
    'good_value',
    'would_recommend',
    'comment',
    'email',
    'provider'
  ],
  form: 'provider_review',
  validate
};

export default reduxForm<{}, ReviewProviderFormProps>(formConfig)(withRouter(ReviewProviderForm));
