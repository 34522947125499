import React from 'react';
import styled from 'styled-components';

import Hr from '../../../../components/Hr';
import Section from '../../../../components/Section';
import { StyledExternalLink } from '../../../../components/Link';

const Ul = styled.ul`
  margin-inline-start: 1em;
`;

const P = styled.p`
  margin-top: 1em;
`;

const Empty = () => (
  <Section>
    <P>We’ve been unable to find providers or services matching your search.</P>
    <P>
      If you did not select a funding type, we are unable to assist you. We are
      a service that supports people with government funding (NDIS and HCP).
    </P>
    <P>For further information, please contact one of the following:</P>
    <Ul>
      <li>
        My Aged Care -{' '}
        <StyledExternalLink href="tel:1800200422">
          1800 200 422
        </StyledExternalLink>
      </li>
      <li>
        {' '}
        NDIS -{' '}
        <StyledExternalLink href="tel:1800800110">
          1800 800 110
        </StyledExternalLink>
      </li>
      <li> Your GP</li>
    </Ul>
    <P>We apologise for the inconvenience. Good luck with your search.</P>
    <Hr>Or</Hr>
  </Section>
);

export default Empty;
