import React from 'react';
import {reduxForm, InjectedFormProps, Field} from 'redux-form';

import {
  Group,
  WrappingGroup,
  LightweightLabel,
  HeavyweightLabel,
  Divider,
  FormContainer
} from './styles';

import { LinkButton } from '../Button';
import {ProviderSearchBox} from "../SearchBox";
import Input from "../Input";

interface BasicSearchFormProps {
  onSubmit: (...args: any[]) => void,
};

export interface BasicSearchFormData {
  query: string,
};

type InnerBasicSearchFormProps = InjectedFormProps<BasicSearchFormData, BasicSearchFormProps> & BasicSearchFormProps;

const BasicSearchForm: React.FC<InnerBasicSearchFormProps> = ({
  handleSubmit,
  onSubmit,
  submitting,
  change,
}) => {
  const [showForm, setShowForm] = React.useState(false);
  const toggle = () => setShowForm(!showForm);

  return (
    <div>
      <Divider>
        <WrappingGroup>
          <LightweightLabel>Or &nbsp;</LightweightLabel>
          <HeavyweightLabel>
            <LinkButton onClick={toggle} type="button">
              Search for a provider to leave a review
            </LinkButton>
          </HeavyweightLabel>
        </WrappingGroup>
      </Divider>
      <FormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          {showForm && (
            <Group>
              <ProviderSearchBox
                handleClick={onSubmit}
                inputComponent={({ ...rest }) => (
                  <Field component={Input} {...rest} name="query" type="search" />
                )}
                onSuggestionSelected={(e, { suggestionValue }) =>
                  change("query", suggestionValue)
                }
                placeholder="Type provider name here"
                spinning={submitting}
              />
            </Group>
          )}
        </form>
      </FormContainer>
    </div>
  );
}

const formConfig = {
  fields: ['query'],
  form: 'basic_search',
  destroyOnUnmount: false
};

export default reduxForm<BasicSearchFormData, BasicSearchFormProps>(formConfig)(BasicSearchForm);
