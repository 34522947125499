import React from 'react';

import {
    StyledLink,
    ButtonSection,
    ButtonPlacementSection,
    OrangeStyledButton
} from "../../style"

export default (props) => {
    return(
        <ButtonPlacementSection>
            <ButtonSection>
                <OrangeStyledButton>
                    <StyledLink to={`/list-your-organisation/enquire-now`}>
                        <b>Enquire Now</b>
                    </StyledLink>
                </OrangeStyledButton>
            </ButtonSection>
        </ButtonPlacementSection>
)};
