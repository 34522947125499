import styled from 'styled-components';
import mediaQueries from '../../styles/mediaQueries';
import { colors } from '../../styles/constants';
import { Centered } from '../Layout';
import Label from '../Label';
import Link from '../Link';
import Input from '../Input';

export const CenteredWithGutters = styled(Centered)`
  max-width: 720px;
  margin: 16px auto;
  text-align: left;
`;

export const StyledLabel = styled(Label)`
  font-weight: 400;
  margin: 0;
`;

export const SubLabel = styled.p`
  line-height: 24px;
  margin-top: -6px;
  margin-bottom: 6px;
`;

export const BtnContainer = styled.div`
  margin: 0 auto;
`;

export const BottomLink = styled(Link)`
  color: ${colors.darkPurple};
  text-decoration: underline;
`;

export const HalfInput = styled(Input)`
  width: 50%;
  ${mediaQueries.mobile`
    width: 100%;
  `};
`;
