import React from 'react';
import Checkbox from '../Checkbox';

interface CheckboxOption {
  label: string;
  value: string;
}

interface CheckboxGroupProps {
  fieldOptions: CheckboxOption[];
  control: any; // react-hook-form control prop
  name: string;
  meta: {
    submitFailed: boolean;
    error: string;
  };
}

const CheckboxGroup: React.FC<CheckboxGroupProps> = ({ fieldOptions, control, name, meta }) => {
  return (
    <div>
      {fieldOptions.map(option => (
        <Checkbox
          key={option.value}
          label={option.label}
          input={{
            type: 'checkbox',
            ...control.register(name, { required: false }), // Use required: false to prevent default "on" value
            value: option.value, // Set the value of each checkbox
          }}
        />
      ))}
      {meta.submitFailed && meta.error && <span style={{ color: 'red' }}>{meta.error}</span>}
    </div>
  );
};

export default CheckboxGroup;
