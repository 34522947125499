import React from 'react';

import { useSelector } from 'react-redux';
import { filter, get, map, min } from 'lodash';
import { stringify } from 'qs';

import AdminProviderHeading from '../../../components/AdminProviderHeading';
import AdminProviderReviewTitle from '../../../components/AdminProviderReviewTitle';
import { CenteredContainer } from '../../../components/Layout';
import Pagination from '../../../components/Pagination';
import ProviderReview from '../../../components/ProviderReview';
import ReviewsTitleContainer from '../../../components/ReviewsTitleContainer';
import { DeltaHeading } from '../../../components/Typography';
import { createReviewsPaginationSubheading } from '../../../util/pagination';
import StyledLink from '../../../components/Link';
import ListInfoCard from '../../../components/ListInfoCard';

import { Query, Review } from '../../../state/types';
import { AdminActions } from '../actions';
import { RouteComponentProps } from 'react-router';
import {
  useCreateReviewResponseMutation,
  useDeleteReviewResponseMutation,
  useGetProviderReviewsQuery,
} from '../../../services/karista';
import { useCurrentProviderQuery } from '../hooks';
import { selectShowOnlyUnrespondedReviews } from '../selectors';
import { reviewsPerPage } from '../constants';

const NoResults = () => <div>No reviews pending response</div>;

const ResponsePublishedCard = ({ onClick }) => {
  // TODO: Replace link with a button that looks like a link,
  // as it doesn't redirect to a different page
  const link = (
    <StyledLink to="#" onClick={onClick}>
      Show all reviews.
    </StyledLink>
  );
  return (
    <ListInfoCard
      headerText="Your response has been published."
      bodyText="Currently only showing reviews pending response."
      bodyLink={link}
    />
  );
};

export interface ProviderReviewsProps extends RouteComponentProps<{id?: string}> {
  query: Query,
  actions: AdminActions,
}

const ProviderReviews = (props: ProviderReviewsProps) => {
  const [createReviewResponse] = useCreateReviewResponseMutation();
  const [deleteReviewResponse] = useDeleteReviewResponseMutation();
  const {
    query,
    match: {
      params: { id },
    },
  } = props;

  const [showResponsePublishedCard, setShowResponsePublishedCard] = React.useState(false);
  const providerId = parseInt(props.match.params.id as string);
  const {data: provider, isLoading: isProviderLoading, isSuccess: isProviderLoadSuccess} = useCurrentProviderQuery(Number(id));
  const showOnlyUnrespondedReviews = useSelector(selectShowOnlyUnrespondedReviews);
  const page = Number(get(query, 'page', 1));
  // Cap index in case we deleted the last item on the last page
  const { data, isLoading, isSuccess } = useGetProviderReviewsQuery(
      {providerId, page, pageSize: reviewsPerPage, onlyUnresponded: showOnlyUnrespondedReviews});

  if (isProviderLoading || isLoading) {
    return <div />;
  }

  if (!isProviderLoadSuccess || !isSuccess) {
    return <div>Unexpected error</div>;
  }
  const {
    name: providerName,
    logo_url: logoUrl,
    overall_review: overallReview,
    is_active: providerIsActive,
    url_slug: url_slug
  } = provider;

  const subheading = createReviewsPaginationSubheading(
    page,
    reviewsPerPage,
    data.count
  );

  // TODO: Find out why this isn't being stored as a
  // number in the first place, and possible fix that
  const totalScore = Number(overallReview);

  const handleChangePage = (page: number) => {
    props.history.push({
      pathname: props.match.url,
      search: stringify({
        ...query,
        page: page,
      })
    });
  };

  const toggleShowOnlyUnrespondedReviews = () => {
    props.actions.toggleShowOnlyUnrespondedReviews();
    setShowResponsePublishedCard(false);
  };

  const createResponseAndShowCard = reviewResponse => {
    if (showOnlyUnrespondedReviews) {
      setShowResponsePublishedCard(false);
    }
    return createReviewResponse(reviewResponse);
  };

  const renderToggleShowResponsesButton = () => {
    const message = showOnlyUnrespondedReviews
      ? 'Show all reviews'
      : 'Show only reviews pending responses';
    // TODO: Replace link with a button that looks like a link,
    // as it doesn't redirect to a different page
    return (
      <StyledLink to="#" onClick={toggleShowOnlyUnrespondedReviews}>
        {message}
      </StyledLink>
    );
  };

  return (
    <div>
      <AdminProviderHeading
        currentPage="reviews"
        providerName={providerName}
        providerId={providerId}
        providerLogoUrl={logoUrl}
        providerRating={totalScore}
        providerIsActive={providerIsActive}
        providerSlug={url_slug}
        fromReviews={true}
      />
      <CenteredContainer>
        <ReviewsTitleContainer>
          <AdminProviderReviewTitle
            allReviews={!showOnlyUnrespondedReviews}
            reviewCount={data.count}
          >
            {renderToggleShowResponsesButton()}
          </AdminProviderReviewTitle>
          <DeltaHeading>{subheading}</DeltaHeading>
        </ReviewsTitleContainer>
        {showResponsePublishedCard && (
          <ResponsePublishedCard
            onClick={toggleShowOnlyUnrespondedReviews}
          />
        )}
        {map(data.results, review => (
          <ProviderReview
            key={review.id}
            canRespond
            createReviewResponse={createResponseAndShowCard}
            deleteReviewResponse={deleteReviewResponse}
            {...review}
          />
        ))}
        {data.count === 0 && <NoResults />}
        {data.totalPages > 1 && (
          <Pagination current={page} {...data} onClick={handleChangePage} />
        )}
      </CenteredContainer>
    </div>
  );
}

export default ProviderReviews;
