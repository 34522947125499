import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import FormAssemblyForm from '../../components/FormAssemblyForm';

import { EnquireFormParagraph, EnquireFormHeading } from './style';

const EnquireForm = () => (
  <Theme theme={themeSecondary}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>
    <Page>
      <Section>
        <EnquireFormHeading>Enquire now</EnquireFormHeading>
        <EnquireFormParagraph>
          {`If you would like to be listed on the Karista platform, fill in our
          provider enquiry form and we'll be in touch to discuss your options
          and our prices.`}
        </EnquireFormParagraph>

        <FormAssemblyForm
          formId="4937615"
          intercomMessage={`I'd like to be listed on the Karista platform.

Tell us more about your provider 
  - What services do you offer?
  - What locations do you cover?`}
          successUrl="/list-your-organisation/success"
        />
      </Section>
    </Page>
    <Footer />
  </Theme>
);

export default EnquireForm;
