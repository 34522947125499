import RequestsBooklet from "./thumbnails/cover-requests-referrals.png"
import ReviewUs from "./thumbnails/2-review-us-image.png"
import Social from "./thumbnails/3-social-image.png"
import ReviewUsSignature from "./thumbnails/4-review-us-image.png"

export const downloads = [
    { title: "Requests and referrals booklet", url: "/static/downloads/KARISTA-Requests-and-Referrals-OCT22.pdf", preview: RequestsBooklet },
    { title: "Printable review form", url: "/static/downloads/KARISTA-Review-Us-Printable.pdf", preview: ReviewUs },
    { title: "Social media image", url: "/static/downloads/KARISTA-Social-Image.png", preview: Social },
    { title: "Signature file image", url: "/static/downloads/KARISTA-Review-Us-Email-Image.png", preview: ReviewUsSignature },
    ]