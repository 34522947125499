import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import ResetPasswordConfirmForm, { SubmitState } from "../components/ResetPasswordConfirmForm";
import { useResetPasswordConfirmMutation } from '../../../services/karista';

export default (props: RouteComponentProps) => {
  const params = new URLSearchParams(props.location.search);
  const token = params.get("token") || "";
  const uid = params.get("uid") || "";

  const [submitState, setSubmitState] = useState(SubmitState.Unsubmitted);
  const [responseErrors, setResponseErrors] = useState<string[]>([]);
  const [resetPasswordConfirm] = useResetPasswordConfirmMutation();

  const handleOnSubmit = (data) => {
    if (submitState === SubmitState.Submitting) {
      return;
    }
    setSubmitState(SubmitState.Submitting);
    resetPasswordConfirm({ ...data, token, uid })
      .unwrap()
      .then(() => {
        setSubmitState(SubmitState.Success);
      })
      .catch((err) => {
        setSubmitState(SubmitState.Failure);
        const genericMsg = "This password reset link is not valid or has expired.";
        if (err.status !== 400) {
          setResponseErrors([genericMsg]);
          return;
        }

        // Django validates password if too short etc.
        let errors = err.data.new_password2;
        if (!errors) {
          errors = [genericMsg]
        }

        setResponseErrors(errors)
      });
  }

  return (
    <ResetPasswordConfirmForm
      onSubmit={handleOnSubmit}
      submitState={submitState}
      responseErrors={responseErrors}
      {...props}
    />
  );
}
