import React, { useState } from 'react';
import { RouteComponentProps } from 'react-router';

import ResetPasswordForm, { SubmitState } from "../components/ResetPasswordForm";
import { useResetPasswordRequestMutation } from '../../../services/karista';

export default (props: RouteComponentProps) => {
  const [submitState, setSubmitState] = useState(SubmitState.Unsubmitted);
  const [resetPasswordRequest] = useResetPasswordRequestMutation();

  const handleOnSubmit = async (data) => {
    if (submitState === SubmitState.Submitting) {
      return;
    }
    setSubmitState(SubmitState.Submitting);
    resetPasswordRequest(data.email)
      .unwrap()
      .then(() => {
        setSubmitState(SubmitState.Success);
      })
      .catch(() => setSubmitState(SubmitState.Failure));
  }

  return <ResetPasswordForm onSubmit={handleOnSubmit} submitState={submitState} {...props} />;
}
