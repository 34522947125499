import React from 'react';
import styled from 'styled-components';

import Contact from '../Contact';
import Empty from '../Empty';
import Section from '../../../../components/Section';
import calculateRem from '../../../../styles/calculateRem';
import { ProvidersActions } from '../../actions';

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

export const Success = () => (
  <Section>
    <p>
      Thanks for letting us know what you&apos;re looking for. We&apos;ll be in
      touch shortly via email in order to assist you.
    </p>
  </Section>
);

interface NoResultsProps {
  actions: ProvidersActions,
};

class NoResults extends React.Component<NoResultsProps> {
  state = {
    submitted: false
  };

  setSubmitted(submitted) {
    this.setState({ submitted });
  }

  render() {
    return (
      <StyledSection>
        {this.state.submitted ? (
          <Success />
        ) : (
          <div>
            <Empty />
            <Contact
              setSubmitted={submitted => this.setSubmitted(submitted)}
              {...this.props}
            />
          </div>
        )}
      </StyledSection>
    );
  }
}

export default NoResults;
