/* eslint-disable indent */
import styled from 'styled-components';
import calculateEm from '../../styles/calculateEm';
import mediaQueries from '../../styles/mediaQueries';
import { sizes } from '../../styles/constants';

export interface StyledGridProps {
  columns: number,
  spacingWidth: number,
  spacingUnit: string,
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit' | 'initial' |  'unset',
  alignItems?: 'normal' |'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' |
    'self-start' | 'self-end' | 'left' | 'right' | 'baseline' | 'first baseline'| 'last baseline' |
    'safe center' | 'unsafe center' | 'inherit' | 'initial' | 'unset',
};

export const StyledGrid = styled.div<StyledGridProps>`
  display: flex;
  margin: -${props => props.spacingWidth / 2} ${props => props.spacingUnit};
  flex-wrap: ${props => props.wrap ? props.wrap : ''};
  align-items: ${props => props.alignItems ? props.alignItems : ''};
`;

export interface StyledGridItemProps {
  columns: number,
  mobile: number | null,
  tablet: number | null,
  desktop: number | null,
  spacing: boolean,
  spacingWidth: number,
  spacingUnit: string,
  desktopPercent: number,
};

// TODO: Carrying out typing on the props returned from mediaQueries
// functions is very tricky. There may be a way to do it cleanly, but
// I'm not going to invest time to figure it out just yet, as we'll
// probably be reworking media queries and breakpoints anyhow.
export const StyledGridItem = styled.div<StyledGridItemProps>`
  ${props => props.spacing ? `padding: ${props.spacingWidth / 2}${props.spacingUnit};` : ''}

  @media (min-width: ${calculateEm(sizes.tablet + 1)}) {
    width: ${props =>
      props.spacing
        ? `calc(${props.desktopPercent}% - ${props.spacingWidth}${
            props.spacingUnit
          });`
        : `${props.desktopPercent}%`};
  }

  ${mediaQueries.tablet`
    width: ${(props: any) =>
      props.spacing
        ? `calc(${props.tabletPercent}% - ${props.spacingWidth}${
            props.spacingUnit
          });`
        : `${props.tabletPercent}%`};
  `}

  ${mediaQueries.mobile`
    width: ${(props: any) =>
      props.spacing
        ? `calc(${props.mobilePercent}% - ${props.spacingWidth}${
            props.spacingUnit
          });`
        : `${props.mobilePercent}%`};
  `}

`;
