import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';
import tick from './tick.png';

const StyledSuccessMessage = styled.div`
  font-size: ${calculateRem(24)};
  color: ${colors.darkPurple};
  font-weight: normal;
  display: flex;
  align-items: center;
`;

const SuccessMessage = ({ message }) => (
  <StyledSuccessMessage>
    <img src={tick} alt="tick icon" />&nbsp;{message}
  </StyledSuccessMessage>
);

export default SuccessMessage;
