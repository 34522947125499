import React from 'react';
import { ThemeProvider } from 'styled-components';
import '../../styles/global';

const Theme = ({ children, theme }) => (
  <ThemeProvider theme={theme}>
    <section style={{ background: theme.background }}>{children}</section>
  </ThemeProvider>
);

export default Theme;
