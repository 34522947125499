import React from 'react';
import { isEmail } from 'validator';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { BetaHeading } from '../../../../components/Typography';
import Hero from '../../../../components/Hero';
import {PurpleExternalLink, WhiteLink} from '../../../../components/Link';
import Label from '../../../../components/Label';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import FormItem from '../../../../components/FormItem';

import ValidationError from '../../../../components/ValidationError';

import {
    CenterText,
    ResetPasswordConfirmFormOuterContainer,
    ResetPasswordFormContainer,
    ResetPasswordFormHeader,
    SuccessContainer
} from './styles';
import SuccessMessage from "../../../../components/SuccessMessage";

export enum SubmitState {
  Unsubmitted,
  Submitting,
  Success,
  Failure
}

interface ResetPasswordFormProps {
  onSubmit: (...args: any[]) => any,
  submitState: SubmitState
}

const ResetPasswordForm: React.FC<InjectedFormProps<{}, ResetPasswordFormProps> & ResetPasswordFormProps> = ({ handleSubmit, onSubmit, submitState }) => {
  const isSubmitting = submitState === SubmitState.Submitting;
  const isSuccess = submitState === SubmitState.Success;
  const isFailure = submitState === SubmitState.Failure;

  const form = (
      <ResetPasswordFormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Label htmlFor="email">Registered email address</Label>
            <Field component={Input} name="email" />
          </FormItem>
          <FormItem>
            <ValidationError
              meta={{
                submitFailed: isFailure,
                error: "Sorry, there has been an unexpected error."
              }}
            />
          </FormItem>
          <Button disabled={isSubmitting} fullWidth>Reset password</Button>
        </form>
        <CenterText>
          Forgot your registered email address?&nbsp;
          <PurpleExternalLink href="mailto:info@karista.com.au?Subject=Provider account email lost">Contact us</PurpleExternalLink>.
        </CenterText>
      </ResetPasswordFormContainer>
  );

  const successMessage = (
      <SuccessContainer>
        <SuccessMessage message="Your password reset request has been received" />
        <br />
        <p>You'll receive an email with instructions shortly.</p>
      </SuccessContainer>
  )
  let body;
  if (isSuccess) {
    body = successMessage;
  }
  else {
    body = form;
  }

  return (
    <div>
      <Hero>
        <ResetPasswordFormHeader>
          <BetaHeading>Reset password</BetaHeading>
          <WhiteLink to="/admin/login" caretLeft>
            back
          </WhiteLink>
        </ResetPasswordFormHeader>
      </Hero>
      <ResetPasswordConfirmFormOuterContainer>
        {body}
      </ResetPasswordConfirmFormOuterContainer>
    </div>
  );
};

ResetPasswordForm.defaultProps = {
  submitState: SubmitState.Unsubmitted
};

interface ResetPasswordFormSubmissionData {
    email: string
}

export const validate = (values: ResetPasswordFormSubmissionData) => {
  const errors: any = {};
  if (!isEmail(values.email || '')) {
    errors.email = 'Please enter a valid email';
  }
  return errors;
};

const formConfig = {
  fields: ['email'],
  form: 'admin-password-reset',
  validate
};

export default reduxForm<{}, ResetPasswordFormProps>(formConfig)(ResetPasswordForm);
