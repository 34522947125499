import React from 'react';

import AdminNavTabs from '../AdminNavTabs';
import Hero, { HeroRow, HeroImage, HeroColumn } from './styles';
import Breakpoint from '../Breakpoint';
import { BetaHeading } from '../Typography';
import { themeLightGrey } from '../../styles/constants';
import Theme from '../Theme';
import ProviderHeader, { ProviderHeaderProps } from '../ProviderHeader';
import { ProviderTabs } from '../../apps/Admin/constants';

const AdminProviderHeading = ({
  providerName,
  providerId,
  currentPage,
  providerRating,
  providerLogoUrl,
  providerIsActive,
  providerSlug,
  fromReviews
}: AdminProviderProps) => (
  <Theme theme={themeLightGrey}>
    <Hero>
      <HeroColumn>
        <BetaHeading>Provider admin</BetaHeading>
        {providerId && providerName && (
          <ProviderHeader
            providerName={providerName}
            providerId={providerId}
            providerRating={providerRating}
            providerLogoUrl={providerLogoUrl}
            providerIsActive={providerIsActive}
            providerSlug={providerSlug}
            fromReviews={fromReviews}
          />
          )}
      </HeroColumn>
      <HeroRow>
      {!(providerId || providerName) && (
        <Breakpoint>
        {breakpoint => (
        breakpoint === "mobile" ? null : <HeroImage src="https://res.cloudinary.com/karista/image/upload/c_scale,q_auto:best,w_700/v1660704972/Website%20Assets/portal-keyboard-image-final.png" alt="Hands typing on keyboard" />
        )}
        </Breakpoint>
      )}
      <AdminNavTabs currentPage={currentPage} providerId={providerId} providerSlug={providerSlug}/>
      </HeroRow>
    </Hero>
  </Theme>
);

interface AdminProviderProps extends Omit<ProviderHeaderProps, 'providerId' | 'providerName'| 'providerSlug' | 'providerRating'> {
  currentPage: keyof typeof ProviderTabs;
  providerId?: ProviderHeaderProps['providerId'];
  providerName?: ProviderHeaderProps['providerName'];
  providerSlug?: ProviderHeaderProps['providerSlug'];
  providerRating?: ProviderHeaderProps['providerRating'];
  fromReviews?: boolean;
}

export default AdminProviderHeading;
