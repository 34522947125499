import { isNull, isEmpty, isUndefined, omitBy, Dictionary } from 'lodash';

// Equivalent to `isNull | isEmpty`, but takes into consideration
// TypeScript type predicates. `lodash` is still figuring out type
// predicates at the moment, but once it's done we can remove this.
export const isNil = <T>(value: T | {} | null | undefined): value is {} | undefined | null => {
  return isEmpty(value) || isNull(value) || value === undefined;
}

// The negative of `isNil`. This is useful to force TypeScript to
// recognise variables as the types they are.
export const isPopulated = <T>(value: T | {} | null | undefined): value is T => {
  return !isNil(value);
}

// A helper to remove undefined values from objects, while also
// inferring types correctly.
export const omitUndefined = <T>(object: Dictionary<T | undefined>): Dictionary<T> => {
  return omitBy(object, isUndefined) as Dictionary<T>;
}

// Remove `undefined` from a value when we know it is defined.
export function forceDefined<T>(value: T | undefined): T {
  return value as T;
}
