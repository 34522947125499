import { isNull } from 'lodash';

function get(key) {
  // Get name followed by anything except a semicolon
  const cookiestring = RegExp(`${key}[^;]+`).exec(document.cookie);
  // Return everything after the equal sign, or an empty string if the cookie name not found
  return decodeURIComponent(
    !isNull(cookiestring) ? cookiestring.toString().replace(/^[^=]+./, '') : ''
  );
}

export default {
  get
};
