import { createSelector } from '@reduxjs/toolkit';
import { RouteComponentProps } from 'react-router';
import { formValueSelector } from 'redux-form';

import { ProvidersState } from './providersSlice';
import {parse} from "qs";

type PartialRootState = { providers: ProvidersState };

const selectLocation = (state: PartialRootState, { location }: RouteComponentProps) => ({ ...location });
const selectProvidersState = ({ providers }: PartialRootState): ProvidersState => ({ ...providers });

const selectLoading = createSelector(
  selectProvidersState,
  state => state.loading,
);
const selectQuery = createSelector(selectLocation, ({ search }) =>
  parse(search, { ignoreQueryPrefix: true })
);

const formSelector = formValueSelector('advanced_search');
const selectedValue = (state, field) => formSelector(state, field);

export interface AdvancedSearchParams {
  service_areas_list: string,
  funding_types_list: string[] | string,
  postcodes_list: string[],
  age_groups_list: string[],
}

const selectAdvancedSearchParams: (s?) => AdvancedSearchParams = (state?) => {
  const fields = [
    'service_areas_list',
    'funding_types_list',
    'postcodes_list',
    'age_groups_list'
  ];
  return fields.reduce((obj, field) => {
    obj[field] = selectedValue(state, field); // eslint-disable-line no-param-reassign
    return obj;
  }, {}) as AdvancedSearchParams;
};

export {
  selectAdvancedSearchParams,
  selectLoading,
  selectQuery,
  selectLocation,
  selectProvidersState,
};
