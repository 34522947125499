/* eslint-disable */
// TODO fix: jsx-a11y/anchor-is-valid jsx-a11y/click-events-have-key-events

import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

export const ItemCard = styled.div`
  background-color: ${colors.lightPurple};
  align-items: center;
  padding-left: ${calculateRem(24)};
  padding-right: ${calculateRem(24)};
  overflow: hidden;
`;
const FlexCardContent = styled.div`
  display: flex;
  justify-content: space-between;

  > p {
    margin: 5px;
  }
`;
const InlineCardContent = styled.div`
  display: inline-block;
`;
const AlignCenter = styled.div`
  text-align: center;
`;
const StyledClickableIcon = styled.span`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const makeLightPurple = {
  backgroundColor: `${colors.lightPurple}`
};

const makeWhite = {
  backgroundColor: '#fff'
};

const SelectableListItem = ({
  item,
  listItemIcon,
  handleClick,
  evenItem,
  stateName
}) => (
  <div>
    <ListItem
      label={item.label}
      isHeader={item.isHeader}
      listItemIcon={listItemIcon}
      handleClick={handleClick}
      evenItem={evenItem}
      item={item}
      stateName={stateName}
    />
  </div>
);

const renderListItemIcon = (Icon, item) => {
  const additionalProps = item && item.active ? { checked: item.active } : {};
  const Component = Icon ? React.cloneElement(Icon, additionalProps) : null;
  return Component;
};

interface ListItemProps {
  label: string,
  isHeader?: boolean,
  handleClick: () => void,
  evenItem: number,
  listItemIcon?: React.ReactNode,
  item: any,
  stateName: string,
};

interface ListItemState {
  checked: boolean,
  showConfirmation: boolean,
};

class ListItem extends React.Component<ListItemProps, ListItemState> {
  static defaultProps = {
    listItemIcon: null,
    item: null
  };

  constructor(props) {
    super(props);
    this.alterItem = this.alterItem.bind(this);
    this.clearRegionPostcodes = this.clearRegionPostcodes.bind(this);
    this.cancelClearRegion = this.cancelClearRegion.bind(this);
    this.state = {
      checked: props.item.active,
      showConfirmation: false
    };
  }

  alterItem(item, stateName, handleClick) {
    this.setState({ checked: !this.state.checked });
    if (this.state.checked === true && stateName === 'regions') {
      this.setState({ showConfirmation: !this.state.showConfirmation });
    } else {
      handleClick(item, stateName);
    }
  }

  clearRegionPostcodes(item, stateName, handleClick) {
    handleClick(item, stateName);
    this.setState({ showConfirmation: false });
  }

  cancelClearRegion() {
    this.setState({ showConfirmation: false });
  }
  render() {
    const {
      label,
      isHeader,
      handleClick,
      evenItem,
      listItemIcon,
      item,
      stateName
    } = this.props;
    const regionIcons =
      this.state.checked === true ? (
        <div style={{ color: 'red' }}>remove</div>
      ) : (
        <div>add</div>
      );

    return (
      <ItemCard style={evenItem === 0 ? makeLightPurple : makeWhite}>
        {isHeader !== true &&
          stateName === 'regions' &&
          this.state.showConfirmation !== true && (
            <FlexCardContent>
              <p>{label}</p>
              <StyledClickableIcon
                onClick={() => this.alterItem(item, stateName, handleClick)}
              >
                {regionIcons}
              </StyledClickableIcon>
            </FlexCardContent>
          )}

        {isHeader !== true &&
          stateName === 'regions' &&
          this.state.showConfirmation === true && (
            <InlineCardContent>
              <p>{label}</p>
              <AlignCenter>
                <p>Clear all postcodes from area?</p>
                <StyledClickableIcon style={{ justifyContent: 'space-around' }}>
                  <a
                    role="button"
                    tabIndex={-1}
                    onClick={() =>
                      this.clearRegionPostcodes(item, stateName, handleClick)
                    }
                  >
                    Confirm
                  </a>
                  <a
                    role="button"
                    tabIndex={-1}
                    onClick={this.cancelClearRegion}
                  >
                    Cancel
                  </a>
                </StyledClickableIcon>
              </AlignCenter>
            </InlineCardContent>
          )}

        {isHeader !== true &&
          stateName !== 'regions' && (
            <FlexCardContent>
              <p>{label}</p>
              <StyledClickableIcon
                onClick={() => this.alterItem(item, stateName, handleClick)}
              >
                {renderListItemIcon(listItemIcon, item)}
              </StyledClickableIcon>
            </FlexCardContent>
          )}
      </ItemCard>
    );
  }
}

SelectableListItem.defaultProps = {
  handleClick: null,
  listItemIcon: null,
  stateName: '',
  item: null
};

export default SelectableListItem;
