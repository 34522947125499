import React from 'react';
import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';

import fullstar from './fullstar.png';
import emptystar from './emptystar.png';
import halfstar from './halfstar.png';
import emptygreystar from './emptygreystar.png';

export const FullStar = () => <img src={fullstar} alt="fullstar" />;
export const EmptyStar = () => <img src={emptystar} alt="emptystar" />;
export const HalfStar = () => <img src={halfstar} alt="halfstar" />;
export const NoneRatingStar = () => (
  <img src={emptygreystar} alt="nonereviewstar" />
);

function roundToNearestHalf(review) {
  return (Math.round(review * 2) / 2).toFixed(1);
}

// TODO: should test this
export function generateStarData(review, range = 5) {
  if (!review) return null;
  // returns an array of the values for each star
  // ie [1, 1, 0.5, 0, 0] = [full star, full star, half star, empty star...]
  let value = Number(review);
  // TODO: The following line is hacky; it capitalises on JS being sneaky with string/number conversions.
  value = (value > 0 ? roundToNearestHalf(value) : 0) as number;
  // fill an array with 1's
  const arr = Array(range).fill(0);
  // map the values to review values
  // for now assume we only decrement by 1
  return arr.map(() => {
    value -= 1;
    if (Number(value) >= 0) return 1;
    if (Number(value) > -1) return 0.5;
    return 0;
  });
}

function resolveIcon(value) {
  let Component = EmptyStar();
  switch (value) {
    case 1:
      Component = FullStar();
      break;
    case 0.5:
      Component = HalfStar();
      break;
    default:
      break;
  }
  return Component;
}

const StyledStar = styled.div`
  flex: 1 1 0px;
  padding: 0;
`;

const StyledStarRating = styled.div`
  display: flex;
  width: ${calculateRem(105)};
`;

const Star = StyledStar;

const generateEmptyStars = range => {
  const starList: React.ReactNode[] = [];
  for (let i = 0; i < range; i += 1) {
    starList.push(
      <Star key={`empty-star-${i}`}>
        <NoneRatingStar />
      </Star>
    );
  }
  return starList;
};

const StarRating = ({ review, range }) => {
  // make sure it is a number
  const stars = generateStarData(review, range);
  return (
    <StyledStarRating>
      {stars
        ? stars.map((star, i) => (
            // TODO: make generic function to generate random keys
            // eslint-disable-next-line
            <Star key={`star-${i}-${star}`}>{resolveIcon(star)}</Star>
          ))
        : generateEmptyStars(range)}
    </StyledStarRating>
  );
};

StarRating.defaultProps = {
  range: 5 // default to 5 stars
};

export default StarRating;
