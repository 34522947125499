import React from 'react';
import styled, { css } from 'styled-components';
import FaCheck from 'react-icons/lib/fa/check';

import { colors } from '../../styles/constants';

const StepCircle = styled.li`
  list-style-type: none;
  height: 32px;
  width: 32px;
  border-radius: 100%;
  border: solid 4px ${colors.white};
  display: flex;
  justify-content: center;
  align-items: center;
  font-weight: bold;
  color: ${colors.lightPurple};
  background: ${colors.white};
  font-size: 18px;
`;

const StepContent = styled.span`
  padding: 0;
  margin: 0;
`;

const active = css`
  background-color: ${colors.orange};
  color: ${colors.purple};
`;

const Step = ({ children, handleClick, ...props }) => (
  <StepCircle onClick={handleClick} {...props}>
    <StepContent>{props.completed ? <FaCheck /> : children}</StepContent>
  </StepCircle>
);

const CompletedStepComponent = props => <Step {...props} completed="true" />;

Step.defaultProps = {
  children: null
};

export default Step;
export const CompletedStep = styled(CompletedStepComponent)`
  ${active};
`;
export const ActiveStep = styled(Step)`
  ${active};
`;
