/* global document, window */
/* eslint-disable class-methods-use-this */
import React, { Component } from 'react'; // eslint-disable-line no-unused-vars

interface BreakpointProps {
  children: Function,
}

interface BreakpointState {
  breakpoint: string,
}

class Breakpoint extends Component<BreakpointProps, BreakpointState> {
  constructor(props) {
    super(props);
    this.getBreakpoint = this.getBreakpoint.bind(this);
    this.setBreakpoint = this.setBreakpoint.bind(this);
    this.state = { breakpoint: this.getBreakpoint() };
  }

  componentDidMount() {
    window.addEventListener('resize', this.setBreakpoint);
  }

  getBreakpoint() {
    return window
      .getComputedStyle(document.querySelector('body') as HTMLBodyElement, ':before')
      .getPropertyValue('content')
      .replace(/"/g, '');
  }

  setBreakpoint() {
    this.setState({ breakpoint: this.getBreakpoint() });
  }

  render() {
    const {
      props: { children },
      state: { breakpoint }
    } = this;
    return (children as Function)(breakpoint);
  }
}

export default Breakpoint;
