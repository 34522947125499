import styled from 'styled-components';

export const AlphaHeading = styled.h1`
  font-weight: 500;
  font-size: 36px;
  line-height: 48px;
  margin-bottom: 24px;
  ${props => ({ ...props.theme })};
`;

export const BetaHeading = styled.h2`
  font-weight: 500;
  font-size: 30px;
  line-height: 36px;
  margin-bottom: 24px;
  ${props => ({ ...props.theme })};
`;

export const GammaHeading = styled.h3`
  font-weight: 500;
  font-size: 24px;
  line-height: 36px;
  margin-bottom: 12px;
  ${props => ({ ...props.theme })};
`;

export const DeltaHeading = styled.h4`
  font-weight: 500;
  font-size: 18px;
  line-height: 24px;
  margin-bottom: 12px;
  ${props => ({ ...props.theme })};
`;
