import React from 'react';
import Theme from '../../../../../components/Theme';
import { themePrimary, themeSecondary } from '../../../../../styles/constants';
import IconKeyWorker from "./hcp-intorduction-image.svg"
import { ListItem, List } from '../../../style';
import HCPFAQs from '../HCPFAQs';
import HCPServices from '../HCPServices';
import HCPLevels from '../HCPLevels';
import ObtainHCPServices from '../ObtainHCPServices';
import IconClientServiceCircle from "../../HowCanWeHelp/icon-client-services-circle.svg"

import {
  NDISHeaderSection,
  HeaderText,
  ProviderIntroHeading,
  SupportCategorySection,
  NDISParagraph,
  NDISHelpSection,
  NDISIntroductionContentWrapper,
  HowWeCanHelpSection,
  FAQInfoText,
  NDISFAQParagraph,
  NDISClientServiceCircleIcon,
  NDISOtherText,
  HowWeCanHelpDiv,
  FirstListContainer,
  SecondListContainer,
  HCPServicesSection,
  HelpButtonSection,
  HCPServiceParagraph,
  CategoryHeadingParagraph,
  BackToTopCard,
  BackToTopCardInfoText,
  HCPIntroductionIcon,
  HCPIntroductionIconContainer
} from "../../../style"

import {
  Card,
  CardInfoText,
  CallLink,
} from '../../../../Providers/containers/styles'

const BackToTopButtons = () => {
  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };

  return (
      <BackToTopCard>
        <CallLink href="#" onClick={scrollToTop}>
          <BackToTopCardInfoText><b>Back to top</b></BackToTopCardInfoText>
        </CallLink>
      </BackToTopCard>
  );
}

const HCPIntroductionContent = () => (
  <>
    <ProviderIntroHeading><b>What is a Home Care Package (HCP)?</b>
    </ProviderIntroHeading>
    <HeaderText>
        <NDISParagraph>
          <b>The Home Care Package (HCP) program is an initiative from the Australian Government to help you stay living independently at home.</b>
        </NDISParagraph>
    </HeaderText>
  </>
);

const HCPIntroduction = () => {
  const openChat = e => {
    e.preventDefault();
    var chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  const SupportButtons = () => {
    return (
      <HelpButtonSection>
        <Card onClick={openChat} style={{marginBottom: '16px'}}>
          <CardInfoText><b>Chat with a team member</b></CardInfoText>
        </Card>
        <Card style={{marginLeft: '16px'}}>
          <CallLink href="/">
            <CardInfoText><b>Search for services now</b></CardInfoText>
          </CallLink>
        </Card>
      </HelpButtonSection>
    );
  }

  return (
    <>
    <Theme theme={themeSecondary}>
      <NDISHeaderSection>
        <NDISIntroductionContentWrapper>
            <HCPIntroductionContent />
        </NDISIntroductionContentWrapper>
        <HCPIntroductionIconContainer>
          <HCPIntroductionIcon src={IconKeyWorker} alt={"Search NDIS services with Karista"} />
        </HCPIntroductionIconContainer>
      </NDISHeaderSection>
      <Theme theme={themePrimary}>
        <NDISHelpSection>
          <div>
            <FirstListContainer style={{marginTop: '0px'}}>
              <List>
                <ListItem>
                The <b>Home Care packages</b> program provides a subsidy towards a package of care and services to meet your individual needs.
                </ListItem><br></br>
                <ListItem>
                It allows you to have access to different levels of support and care from a Home Care Provider of your choice.
                </ListItem><br></br>
                <ListItem>
                Home Care Package funds aren’t paid to you directly. 
                Instead, you choose an approved home or aged care package provider to administer the funds on your behalf - they coordinate the care and services to support you in your own home.
                </ListItem><br></br>
              </List>
            </FirstListContainer>
            </div>
            <SecondListContainer>
              <List>
                <ListItem>
                You may also decide to have a <b>self managed</b> Home Care Package where you decide how to spend your funding. 
                You still need an approved HCP provider to manage it, but you get to pick who helps you and when. This means you can use more of your funding for the services you need.
                </ListItem><br></br>
                <ListItem>
                Depending on your income, you may be required to contribute to the cost of your care and services.
                </ListItem><br></br>
              </List>
            </SecondListContainer>
        </NDISHelpSection>
      </Theme>
      <HCPServicesSection>
        <HCPServiceParagraph>
          <b>What services are included in a HCP?</b>
        </HCPServiceParagraph>
        <CategoryHeadingParagraph>Depending on the level of <b>Home Care Package</b> you receive, you can get assistance with a range of different services focusing on your individual needs.</CategoryHeadingParagraph>
        <HCPServices></HCPServices>
      </HCPServicesSection>
      <ObtainHCPServices></ObtainHCPServices>
      <BackToTopButtons></BackToTopButtons>
      <HCPLevels></HCPLevels>
      <HCPFAQs></HCPFAQs><br></br>
      <BackToTopButtons></BackToTopButtons>
      <Theme theme={themeSecondary}>
        <NDISHeaderSection>
          <NDISClientServiceCircleIcon>
              <img src={IconClientServiceCircle} alt={'Karista is a platform that connects people with disability to NDIS registered providers'} />
          </NDISClientServiceCircleIcon>
          <HowWeCanHelpDiv>
            <SupportCategorySection style={{ paddingRight: '10px', paddingLeft: '10px'}}>
                <NDISOtherText>
                    <b>Do you need help to get the most out of your home help package?</b>
                </NDISOtherText>
            </SupportCategorySection>
            <HowWeCanHelpSection>
                <NDISFAQParagraph style={{ paddingTop: '10px' }}><b>Have a chat to one of our expert Client Service Managers and see how we can help you to optimise your Government funded home care.</b></NDISFAQParagraph>
            </HowWeCanHelpSection>
            <SupportButtons></SupportButtons>
          </HowWeCanHelpDiv>
        </NDISHeaderSection>
      </Theme>
    </Theme>
    </>
  );
}

export default HCPIntroduction;