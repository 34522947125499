import styled from "styled-components";
import Section from "../../../../components/Section";
import StyledLink from "../../../../components/Link";
import { BetaHeading, GammaHeading } from "../../../../components/Typography";
import mediaQueries from "../../../../styles/mediaQueries";
import {colors} from "../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const InformationSection = styled.div`
  justify-content: space-between;
`

export const InformationDiv = styled.div`
  justify-content: space-between;
  margin-left: 40px;

  @media (max-width: ${cardStackBreakpoint}) {
    width: auto;
    margin-left: 4px;
  }
`

export const HeaderSection = styled.div`
  text-align: left;
  width: 500px;
  ${mediaQueries.mobile`
    width: auto;
  `};
`;

export const HeaderHeading = styled(GammaHeading)`
  margin-bottom: 8px;
`;

export const BetaHeaderHeading = styled(BetaHeading)`
  margin-bottom: 8px;

  ${mediaQueries.mobile`
    text-align: center;
  `};
`;


export const HeaderText = styled.p`
  font-size: 17px;
  margin-bottom: 8px;
`;

export const InformationText = styled.p`
  font-size: 14px;
  margin-bottom: 2px;
`;

export const DataSection = styled(Section)`
  display: flex;
  flex-direction: row;
  justify-content: space-between;

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`

export const NDISCard = styled.div`
  color: ${colors.black};
  background: ${colors.lightGrey};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 390px;
  flex-basis: 390px;
  flex-grow: 1;
  flex-shrink: 1;
  
  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    padding: 20px 40px;
    width: 260px;
    margin-bottom: 4px;
  }
`;

export const Card = styled.div`
  color: ${colors.black};
  background: ${colors.lightGrey};
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-left: 20px;
  border-radius: 10px;
  margin-bottom: 20px;
  margin-right: 20px;
  width: 350px;
  flex-basis: 340px;
  flex-grow: 1;
  flex-shrink: 1;
  
  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
    padding: 20px 40px;
    width: 260px;
    margin-bottom: 4px;
  }
`;

export const CardIcon = styled.img`
  width: 80px;
`;

export const CardInternalNDIS = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 10px;
  border-radius: 10px;
  width: 342px;
  flex-basis: 342px;
  flex-grow: 1;
  flex-shrink: 1; 
  overflow-wrap: break-word;
  word-wrap: break-word;

  @media (max-width: ${cardStackBreakpoint}) {
    margin-left: 10px;
  }
`;

export const CardInternalOthers = styled.div`
  display: flex;
  flex-direction: column;
  align-items: left;
  text-align: left;
  padding: 10px;
  border-radius: 10px;

  @media (max-width: ${cardStackBreakpoint}) {
    padding: 0px;
  }
`;


export const CardSpacer = styled.div`
  max-width: 480px;
  min-width: 200px;
  flex-grow: 1;
  flex-basis: 0;
  @media (max-width: ${cardStackBreakpoint}) {
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const InfoText = styled.span`
  line-height: 26px;
`;

export const CardPurpleText = styled(InfoText)`
  color: ${colors.purple};
  font-weight: 400;
`;

export const CardsSection = styled.div`
  display: flex;
  flex-direction: row;
  
  @media (max-width: ${cardStackBreakpoint}) {
    align-items: center;
    flex-direction: column;
    margin-left: 0px;
  }
`;

export const CardIconContainer = styled.div`
  margin-top: 24px;
  margin-bottom: 0;
  @media (max-width: ${cardStackBreakpoint}) {
    margin-top: 0;
    margin-bottom: 8px;
  }
`;

export const ComplaintsAndFeedbackImageSection = styled.img`
  display: flex;
  align-items: center;
  padding-left: 20px;
  margin-top: 20px;

  @media (max-width: ${cardStackBreakpoint}) {
    display: none;
  }
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const CardText = styled(BlackInfoText)`
  padding-right: 5px;
  padding-bottom: 5px;
  padding-top: 0;
  max-width: 100%;
  word-wrap: break-word;
`;

export const ButtonLink = styled(StyledLink)`
  background-color: ${colors.orange};
  color: ${colors.purple};
  width: 220px;
  text-decoration: none;
  font-size: 15px;
  margin-bottom: 10px;
  padding: 6px;
  border-radius: 20px;
  cursor: pointer;
  line-height: 1.2;
`;

export const ButtonSection = styled.div`
  @media (max-width: ${cardStackBreakpoint}) {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;
    margin-top: 0px;
    margin-bottom: 10px;
  }
`;