import React from 'react';
import Theme from '../../../../components/Theme';
import {themeLightGrey, themeSecondary} from "../../../../styles/constants";
import WhatWeDoCard from "../../../../components/WhatWeDoCard";

import {
    BlackInfoText,
    DataProtectionIcon,
    ConfidentialitySection,
    HeaderHeading,
    HeaderSection,
    HeaderText
} from "./style";

export default (props) => (
    <Theme theme={themeLightGrey}>
        <HeaderSection>
            <HeaderHeading>How does Karista work?</HeaderHeading>
            <HeaderText>
                Karista provides a <b>free</b>, independent service connecting you with disability and home care
                services, therapists and support workers based on your personal needs and goals. Our Client
                Services team are experienced in finding and connecting NDIS and Home Care Package (HCP)
                participants to supports with availability.
            </HeaderText>
        </HeaderSection>

        <WhatWeDoCard />
        <Theme theme={themeSecondary}>
            <ConfidentialitySection>
                <DataProtectionIcon alt="Lock icon" />
                <BlackInfoText>
                We prioritise data security with end-to-end encryption, ensuring your information stays private and secure. 
                We guarantee your data will never be shared with third parties, 
                maintaining confidentiality and protecting your privacy at all times.
                </BlackInfoText>
            </ConfidentialitySection>
        </Theme>
    </Theme>
);
