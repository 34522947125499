import styled from 'styled-components';
import { colors } from '../../../../styles/constants';

export const ResetPasswordFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ResetPasswordFormContainer = styled.div`
  color: ${colors.darkPurple};
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
`;

export const SuccessContainer = styled.div`
  margin: 24px auto;
  max-width: 600px;
`;

export const CenterText = styled.div`
  text-align: center;
`;

export const ResetPasswordConfirmFormOuterContainer = styled.div`
  margin-top: 1.5rem;
`;
