import React from 'react';
import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import IconRocket from "./icon-rocket.svg"
import IconProfile from "./icon-profile.svg"
import Breakpoint from '../../../../components/Breakpoint';
import {
    AboutUsParagraph,
    BioPhoto,
    HeaderSection,
    HeaderText,
    HeaderHeading,
    RocketIcon,
    MissionStatement,
    ButtonLink,
    CommercialButtonSection
} from "../../style"


// Shared component for both desktop and mobile versions
const LeadershipContent = () => (
  <>
    <HeaderHeading>CEO and founder
        <br></br>
        Danielle Bodinnar
    </HeaderHeading>
    <HeaderText>
        <AboutUsParagraph>
            Danielle founded Karista in 2016, driven by her passion for transforming the healthcare industry.
            A dedicated entrepreneur with a deep understanding of the aged care and disability sectors, 
            Danielle’s inspiration stems from both her professional leadership roles in large healthcare 
            businesses and her personal experiences as a mother of a neurodiverse child. 
            <br></br>
            <br></br>
            Her firsthand knowledge of the value of therapies and support services has shaped her 
            vision for Karista, helping families access the critical resources they need for meaningful 
            outcomes. Under her leadership, Karista continues to make a significant impact on the 
            healthcare landscape.
        </AboutUsParagraph>
    </HeaderText>
  </>
);

// Shared component for both desktop and mobile versions
const MissionStatementContent = () => (
  <>
    <HeaderText>
        <MissionStatement>Our Mission and Vision </MissionStatement>
        <AboutUsParagraph>
            Danielle and the Karista team are on a mission to impact 1 million lives by 2025
            and share a vision of providing better care at a lower total cost to consumers and providers.
        </AboutUsParagraph>
    </HeaderText>
  </>
);

const CommercialModelButton = () => {
  const handleClick = () => {

    let formLoc = document.getElementById("KaristaCommericalModel")
    formLoc && formLoc.scrollIntoView({
        behavior: 'smooth',
        block: 'start',
        inline: 'nearest'
    });
  };

  return (
      <ButtonLink as="a" onClick={handleClick}>
          <b>Read about our commercial model</b>
      </ButtonLink>
  );
}


const LeadershipIntro = () => {
    const renderLeadershipDesktop  = () => {
      return (
        <HeaderSection>
            <BioPhoto>
              <img src={IconProfile} alt={''} />
            </BioPhoto>
            <div>
                <LeadershipContent />
                <RocketIcon><img src={IconRocket} alt={'Our Mission'} /></RocketIcon>
                <MissionStatementContent />
                <CommercialButtonSection>
                  <CommercialModelButton />
                </CommercialButtonSection>
            </div>
        </HeaderSection>
      );
    }

    const renderLeadershipMobile  = () => {
      return (
        <HeaderSection>
            <div>
                <LeadershipContent />
                <BioPhoto>
                  <img src={IconProfile} alt={''} />
                </BioPhoto>
                <MissionStatementContent />
            </div>
            <CommercialButtonSection>
              <CommercialModelButton />
            </CommercialButtonSection>
        </HeaderSection>
      );
    }

    return (
      <Theme theme={themeSecondary}>
          <Breakpoint>
            {breakpoint => {
              if (breakpoint === 'desktop' || breakpoint === 'tablet') return renderLeadershipDesktop();
              return renderLeadershipMobile();
            }}
          </Breakpoint>
      </Theme>
    );
}

export default LeadershipIntro;
