/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { RouteComponentProps } from 'react-router';

import InactiveProviderMessage from '../../../../components/InactiveProviderMessage';
import StarRating from '../../../../components/StarRating';
import Link from '../../../../components/Link';
import ProviderLogo from '../../../../components/ProviderLogo';

import {
  Card,
  ProviderContainer,
  ProviderHeader,
  HeaderInner,
  NameContainer,
  ProviderName,
  Button,
  ZeroReviewContainer,
  ReviewContainer
} from '../../../Providers/components/Provider/style';
import { Provider as ProviderT, ProviderId } from '../../../../state/types';

interface ProviderProps extends ProviderT, RouteComponentProps {
  providerId: ProviderId,
};

const Provider: React.FC<ProviderProps> = props => {
  const {
    providerId: id,
    logo_url: logoUrl,
    overall_review: overallReview,
    reviews_count: reviewsCount = 0,
    is_active: isActive = false,
    name,
    location,
    url_slug: url_slug
  } = props;

  const previousUrl = `${location.pathname}${location.search}`;

  return (
    <Card>
      <ProviderContainer>
        <ProviderHeader>
          {logoUrl && <ProviderLogo src={logoUrl} />}
          <HeaderInner>
            <NameContainer>
              <ProviderName>{name}</ProviderName>
              <ReviewContainer>
                <StarRating review={overallReview} />
                {reviewsCount > 0 ? (
                  <Link
                    to={{
                      pathname: `/providers/${url_slug}/${id}/reviews`,
                      state: { previousUrl }
                    }}
                  >
                   {'View'} {reviewsCount} {reviewsCount > 1 ? 'reviews' : 'review'}
                  </Link>
                ) : (
                  <ZeroReviewContainer>0 reviews</ZeroReviewContainer>
                )}
              </ReviewContainer>
              {!isActive && (
                <InactiveProviderMessage providerId={id} providerName={name} />
              )}
            </NameContainer>
            <div>
              <Button
                border
                to={{
                  pathname: `/admin/providers/${url_slug}/${id}/edit`,
                  state: { previousUrl }
                }}
              >
                View
              </Button>
            </div>
          </HeaderInner>
        </ProviderHeader>
      </ProviderContainer>
    </Card>
  );
}

export default Provider;
