import { createGlobalStyle } from 'styled-components';

import calculateEm from './calculateEm';
import { colors, sizes } from './constants';

export default createGlobalStyle`

  /* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
  */

  * {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  html, body, div, span, applet, object, iframe,
  h1, h2, h3, h4, h5, h6, p, blockquote, pre,
  a, abbr, acronym, address, big, cite, code,
  del, dfn, em, img, ins, kbd, q, s, samp,
  small, strike, strong, sub, sup, tt, var,
  b, u, i, center,
  dl, dt, dd, ol, ul, li,
  fieldset, form, label, legend,
  table, caption, tbody, tfoot, thead, tr, th, td,
  article, aside, canvas, details, embed,
  figure, figcaption, footer, header, hgroup,
  menu, nav, output, ruby, section, summary,
  time, mark, audio, video {
  margin: 0;
  padding: 0;
  border: 0;
  font-size: 100%;
  vertical-align: baseline;
  }
  /* HTML5 display-role reset for older browsers */
  article, aside, details, figcaption, figure,
  footer, header, hgroup, menu, nav, section {
  display: block;
  }
  ul {
  list-style: disc outside none;
  }
  blockquote, q {
  background: #f9f9f9;
  border-left: 10px solid #ccc;
  margin: 1.5em 0px;
  padding: 0.5em 10px;
  }
  blockquote:before, blockquote:after,
  q:before, q:after {
  content: '';
  content: none;
  }
  table {
  border-collapse: collapse;
  border-spacing: 0;
  }
  strong {
    font-weight:600;
  }
  select {
    &:first-child {
      color: graytext;
    }
  }
  html {
    font-size: 100%;
  }

  body {
    background: ${colors.darkPurple};
    font-family: filson-soft, sans-serif;
    font-style: normal;
    font-weight: 300;
    font-size: 18px;
    line-height: 30px;
    margin: 0;
    padding-top: 60px;
  }

  body:before {
    content: "desktop";
    display: none;
  }

  @media only screen and (max-device-width : ${calculateEm(sizes.tablet)}) {
    body:before {
      content: "tablet";
    }
  }

  @media only screen and (max-device-width : ${calculateEm(sizes.mobile)}) {
    body:before {
      content: "mobile";
    }
  }
  img[width="1"][height="1"] {
    display: none;
  }
`;
