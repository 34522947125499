import React from 'react';
import { isEmail, isEmpty } from 'validator';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { BetaHeading } from '../../../../components/Typography';
import Hero from '../../../../components/Hero';
import { PurpleLink, WhiteLink } from '../../../../components/Link';
import Label from '../../../../components/Label';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import FormItem from '../../../../components/FormItem';

import ValidationError from '../../../../components/ValidationError';

import { CenterText, LoginContainer, LoginHeader } from './styles';

interface LoginFormProps {
  isUserLogin?: boolean,
  onSubmit: (...args: any[]) => any,
  authError: any,
};

const LoginForm: React.FC<InjectedFormProps<{}, LoginFormProps> & LoginFormProps> = ({ isUserLogin, handleSubmit, onSubmit, authError }) => {
  const heading = !isUserLogin ? 'Admin login' : 'Login';

  const emailNormalize = (email) => {
    // Convert the email to lowercase
    return email.toLowerCase();
  };

  const submitForm = (values: any) => {
    // Normalize email before submitting
    const normalizedValues = {
      ...values,
      email: emailNormalize(values.email),
    };
    onSubmit(normalizedValues);
  };

  return (
    <div>
      <Hero>
        <LoginHeader>
          <BetaHeading>{heading}</BetaHeading>
          <WhiteLink to="/" caretLeft>
            back
          </WhiteLink>
        </LoginHeader>
      </Hero>
      <LoginContainer>
        <form onSubmit={handleSubmit(submitForm)}>
          <FormItem>
            <ValidationError
              meta={{
                submitFailed: !!authError && authError.status === 401,
                error: 'Your email address or password is incorrect'
              }}
            />
            <ValidationError
              meta={{
                submitFailed: !!authError && authError.status !== 401,
                error: "Sorry, there's been an unexpected error. Please contact Karista support."
              }}
            />
          </FormItem>
          <FormItem>
            <Label htmlFor="email">Email address</Label>
            <Field component={Input} name="email" />
          </FormItem>
          <FormItem>
            <Label htmlFor="password">Password</Label>
            <Field component={Input} name="password" type="password" />
          </FormItem>
          <Button fullWidth>Log in</Button>
          <CenterText>
            Forgot your password? <PurpleLink to="/admin/password-reset">Reset Password</PurpleLink>
          </CenterText>
        </form>
      </LoginContainer>
    </div>
  );
};

LoginForm.defaultProps = {
  isUserLogin: true,
  authError: null
};

// TODO: Come back and fix up these `any` types.
export const validate = (values: any = {}) => {
  const errors: any = {};
  if (isEmpty(values.password || '')) {
    errors.message = 'Your password is required';
  }
  if (!isEmail(values.email || '')) {
    errors.email = 'Please enter a valid email';
  }
  if (isEmpty(values.email || '')) {
    errors.email = 'Your email is required';
  }
  return errors;
};

const formConfig = {
  fields: ['email', 'password'],
  form: 'admin-login',
  validate
};

export default reduxForm<{}, LoginFormProps>(formConfig)(LoginForm);
