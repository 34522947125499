import React from 'react';
import styled, { css } from 'styled-components';
import FaCaretLeft from 'react-icons/lib/fa/caret-left';
import FaCaretRight from 'react-icons/lib/fa/caret-right';
import { Link } from 'react-router-dom';

import { colors } from '../../styles/constants';

interface LinkProps {
  caretLeft?: boolean,
  caretRight?: boolean,
  border?: boolean,
};

const ThemedLink = styled(Link)<LinkProps>`
  ${props => ({ ...props.theme })};
  text-decoration: ${props => (props.caretLeft ? 'none' : 'underline')};
  background-color: transparent;
  cursor: pointer;
`;

export const StyledExternalLink = styled.a`
  ${props => ({ ...props.theme })};
  text-decoration: underline;
  background-color: transparent;
  cursor: pointer;
`

export const PurpleExternalLink = styled.a`
  color: ${colors.purple};
`

const StyledLink: React.FC<React.ComponentProps<typeof ThemedLink>> = (
  { children, caretLeft = false, caretRight = false, border, ...props } // eslint-disable-line
) => (
  <ThemedLink {...props}>
    {caretLeft ? <FaCaretLeft /> : null}
    <span>{children}</span>
    {caretRight ? <FaCaretRight /> : null}
  </ThemedLink>
);

StyledLink.defaultProps = {
  caretLeft: false,
  caretRight: false,
  border: false
};

export const WhiteLink = styled(StyledLink)`
  color: ${colors.white};
  ${props =>
    props.border
      ? css`
        text-decoration: none;
        border: 1px solid ${colors.white};
        border-radius: 5px;
        padding: 3px 12px;
      `
      : ''};
`;
export const WhiteAnchor = styled("a")`
  color: ${colors.white};
`;
export const PurpleLink = styled(StyledLink)`
  color: ${colors.darkPurple};
  ${props =>
    props.border
      ? css`
          text-decoration: none;
          border: 1px solid ${colors.darkPurple};
          border-radius: 5px;
          padding: 3px 12px;
        `
      : ''};
`;
export const OrangeLink = styled(StyledLink)`
  color: ${colors.orange};
  ${props =>
    props.border
      ? css`
        text-decoration: none;
        border: 1px solid ${colors.orange};
        border-radius: 5px;
        padding: 3px 12px;
      `
      : ''};
`;

export const ButtonLink = styled(StyledLink)`
  background-color: ${colors.orange};
  text-decoration: none;
  padding: 6px 24px;
  border-radius: 5px;
`;

export default StyledLink;
