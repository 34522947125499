import React, { useEffect } from 'react';
import styled from 'styled-components';

import { ButtonLink } from '../../components/Link'; // eslint-disable-line
import Section from '../../components/Section';
import Hero from '../../components/Hero';
import Theme from '../../components/Theme';
import { BetaHeading } from '../../components/Typography';
import { Centered } from '../../components/Layout';
import mediaQueries from '../../styles/mediaQueries';
import calculateRem from '../../styles/calculateRem';
import Button from '../../components/Button';
import SuccessMessage from '../../components/SuccessMessage';
import { themeLightGrey } from '../../styles/constants';
import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';

const MainContent = styled.div`
  display: flex;
  flex-direction: column;
  min-height: 85vh;
`;

const ContentSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${calculateRem(16)};
  font-size: 20px;
  flex: 1;
`;

const ButtonWrapper = styled.div`
  padding-top: ${calculateRem(24)};
  padding-bottom: ${calculateRem(24)};
  display: flex;
  justify-content: space-between;
`;

const WideLink = styled(Button)`
  width: 350px;
  ${mediaQueries.mobile`
    width: 100%;
    margin-right: 0;
    padding: 0;
  `};
`;

const MessageSection = styled(Centered)`
  max-width: ${calculateRem(720)};
  margin: ${calculateRem(16)} auto;
  padding: 0 ${calculateRem(16)};
  text-align: left;
  display: flex;
  align-items: center;
`;

const RequestSuccess = () => {

  useEffect(() => {
    const offlineChatButton = document.getElementById('offline-chat-container');
    const onlineChatScript = document.getElementById('embedded-service');
    const onlineChatScriptMain = document.getElementById('offline-chat-contaier');
    const chatIframe = document.getElementById('embeddedMessagingFrame');

    if (onlineChatScript) {
      onlineChatScript.style.display = 'none';
      onlineChatScript.remove();
    }
    if (offlineChatButton) {
      offlineChatButton.remove();
    }
    if (onlineChatScriptMain) {
      onlineChatScriptMain.remove();
    }
    if (chatIframe) {
      chatIframe.style.display = 'none';
      chatIframe.remove();
    }
  }, []);

  return (
    <Theme theme={themeLightGrey}>
      <Navigation>
          <OrangeLink border to="/">
            Search Services
          </OrangeLink>
        </Navigation>
        <Hero>
          <BetaHeading>Thank you for your request</BetaHeading>
        </Hero>
      <MainContent>
        
        <MessageSection>
          <SuccessMessage message="Your request and details has been received." />
        </MessageSection>
        <ContentSection>
          <p>
            A member of client services team will be in touch with you when we return.
          </p>
          <ButtonWrapper>
            <ButtonLink
              to={{
                pathname: '/'
              }}
            >
              <WideLink>Back to Home</WideLink>
            </ButtonLink>
          </ButtonWrapper>
        </ContentSection>
        <Footer />
      </MainContent>
    </Theme>
  );
}

export default RequestSuccess;
