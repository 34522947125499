import { css } from 'styled-components';

import calculateEm from './calculateEm';
import { sizes } from './constants';

type CssArgs = Parameters<typeof css>;

type MediaQueries = {
  [Size in keyof typeof sizes]: (...args: CssArgs) => any
}

const mediaQueries: MediaQueries = Object.keys(sizes).reduce((accumulator, label) => {
  const em = calculateEm(sizes[label]);
  // eslint-disable-next-line no-param-reassign
  accumulator[label] = (...args: CssArgs) => css`
    @media (max-width: ${em}) {
      ${css(...args)};
    }
  `;
  return accumulator;
}, {} as MediaQueries);

export default mediaQueries;
