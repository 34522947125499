import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

const Div = styled.div`
  border-bottom: 1px solid ${colors.darkPurple};
  height: ${calculateRem(18)};
  text-align: center;
  width: 100%;
`;

const Span = styled.span`
  background-color: ${colors.white};
  padding: 0 ${calculateRem(12)};
  font-weight: 500;
`;

const Hr = ({ children }) => (
  <Div>
    <Span>{children}</Span>
  </Div>
);

export default Hr;
