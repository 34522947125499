import * as React from "react"
import {ButtonLink} from "../../../../components/Link"
import { trackResourceDownload } from "../../tracking"
import { DownloadContainer, StyledCaption, Thumbnail } from "./styles"


export default function Downloadable({ title, url, preview }) {
    const trackDownload = () => {
        trackResourceDownload({label: title, filename: url.split("/").pop()})
    }
    return (
        <DownloadContainer>
            <Thumbnail src={preview} alt={title} />
            <StyledCaption>{title}</StyledCaption>
            <ButtonLink to={url} target="_blank" onClick={trackDownload}>Download</ButtonLink>
        </DownloadContainer>
    )
}