import React, {ReactNode} from 'react';
import Theme from '../../../../components/Theme';
import {themeLightGrey} from "../../../../styles/constants";
import IconEnquiryClick from "./icon-enquiry-click.svg"
import IconOnboard from "./icon-onboard.svg"
import IconRequestArrow from "./icon-recieve-request-arrow.svg"
import IconProfileSetup from "./icon-setup-profile.svg"
import EnquireButton from '../EnquireButton';

import {
    HeaderHeading,
    HeaderSection,
    Card,
    CardSpacer,
    CardsSection
} from "./style";

import {
    CardIcon, CardIconContainer,
    CardInfoText,
    CardOrangeText,
    CardTopAnchor,
    CardTopIcon
} from "../../../CampaignHome/style";


interface CardProps {
  cardNumber: number
  iconSrc: string
  iconAlt: string
  header: ReactNode
  detail: ReactNode
}


const StepCard = (props: CardProps) => (
    <CardSpacer>
        <Card>
            <CardTopAnchor>
                <CardTopIcon>{props.cardNumber}</CardTopIcon>
            </CardTopAnchor>
            <CardIconContainer>
                <CardIcon src={props.iconSrc} alt={props.iconAlt} />
            </CardIconContainer>
            <CardOrangeText>{props.header}</CardOrangeText>
            <CardInfoText>{props.detail}</CardInfoText>
        </Card>
    </CardSpacer>
)

export default (props) => {
    const ProviderBenefits = () => {
        return (
            <CardsSection>
                <StepCard
                    cardNumber={1}
                    header="Complete the enquiry form"
                    iconSrc={IconEnquiryClick}
                    iconAlt="Complete the enquiry form"
                    detail="One of our team members will be in contact with you about 
                    how you can grow your business through Karista."
                />
                <StepCard
                    cardNumber={2}
                    iconSrc={IconProfileSetup}
                    iconAlt="Set up your online profile"
                    header="Set up your online profile"
                    detail="We give you access to an online Admin portal so you can set up
                    and manage your business profile and collect and respond to reviews."
                />
                <StepCard
                    cardNumber={3}
                    iconSrc={IconRequestArrow}
                    iconAlt="Provider Start receiving requests"
                    header="Start receiving requests"
                    detail="Receive fully triaged requests from our team that aim to match your
                     available capacity and capability."
                />
                <StepCard
                    cardNumber={4}
                    iconSrc={IconOnboard}
                    iconAlt="Onboard"
                    header="Onboard"
                    detail="We don't just refer! We onboard the client, completing the paperwork required,
                    so you can move forward with the client sooner and start delivering quality supports faster."
                />
            </CardsSection>
        );
    }

    return(
    <Theme theme={themeLightGrey}>
        <HeaderSection>
            <HeaderHeading>How does Karista work for Providers?</HeaderHeading>
        </HeaderSection>
        <ProviderBenefits />
        <EnquireButton />
    </Theme>
)};
