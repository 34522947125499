import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import SupportButton from './components/SupportButton';
import IconClientServiceCircle from './components/HowCanWeHelp/icon-client-services-circle.svg';

import {
  FormSucessParagraph,
  EnquireFormHeading,
  FormSuccessIcon,
  FormSuccessSection
} from './style';

const ComplaintsAndFeedbackRedirect = () => (
  <Theme theme={themeSecondary}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>
    <Page>
      <FormSuccessSection>
        <FormSuccessIcon
          src={IconClientServiceCircle}
          alt="Disability Providers and Home Care Package"
        />
        <EnquireFormHeading>
          <b>
            Thank you! We have received your information via our feedback form.
          </b>
        </EnquireFormHeading>
        <br />
        <FormSucessParagraph>
          A member of our team will reach out within the next 48 business hours
          to discuss your feedback further.
        </FormSucessParagraph>
      </FormSuccessSection>
      <SupportButton />
    </Page>
    <Footer />
  </Theme>
);

export default ComplaintsAndFeedbackRedirect;
