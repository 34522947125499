import React from 'react';
import { skipToken } from "@reduxjs/toolkit/query/react";
import { RouteComponentProps } from 'react-router';

import { Waiting } from './waiting';
import Welcome from '../components/Welcome';
import { useCurrentProviderQuery, useGetAuthorizedProviderIdsQuery } from '../hooks';

export interface WelcomeProviderProps extends RouteComponentProps<{ id?: string }> {
};

const WelcomeProvider: React.FC<WelcomeProviderProps> = (props) => {
  const { data: authorizedProviderIds, isLoading: authorizedIsLoading } = useGetAuthorizedProviderIdsQuery();
  const providerId = props.match.params.id ? Number(props.match.params.id) : getFirstAuthorizedId(authorizedProviderIds)

  const { data: provider, isLoading: currentIsLoading } =
    useCurrentProviderQuery(providerId);

  if (!authorizedProviderIds || authorizedProviderIds.length === 0 || authorizedIsLoading || currentIsLoading) {
    return <Waiting />;
  }


  return (
    <Welcome
      authorizedProviderIds={authorizedProviderIds || []}
      provider={provider}
    />
  );
}

function getFirstAuthorizedId(authorizedIds?: number[]): number | typeof skipToken {
  return (
    (authorizedIds && authorizedIds.length === 1)
    ? Number(authorizedIds[0])
    : skipToken
  )
}

export default WelcomeProvider;
