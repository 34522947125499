import styled from "styled-components";
import Section from "../../../../../components/Section";
import { AlphaHeading } from "../../../../../components/Typography";
import {colors} from "../../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const HeaderSection = styled(Section)`
  text-align: center;
  padding: 4px;
`;

export const HeaderHeading = styled(AlphaHeading)`
  margin-bottom: 8px;
  margin-top: 32px;
  
  @media (max-width: ${cardStackBreakpoint}) {
    font-size: 24px;
  }
`;

export const ServiceCard = styled.div`
  color: ${colors.black};
  background: ${colors.white};
  width: 600px;
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding: 20px;
  border-radius: 10px;
  
  @media (max-width: ${cardStackBreakpoint}) {
    padding: 20px 40px;
    width: 260px;
  }
`;

export const CardSpacer = styled.div`
  max-width: 480px;
  min-width: 200px;
  flex-grow: 1;
  flex-basis: 0;
  margin-bottom: 32px;
  @media (max-width: ${cardStackBreakpoint}) {
    &:not(:last-of-type) {
      margin-bottom: 48px;
    }
  }
`;

export const CardsSection = styled(Section)`
  display: flex;
  justify-content: center;
  padding-left:  6%;
  flex-direction: column;

  @media (max-width: ${cardStackBreakpoint}) {
    align-items: center;
    margin-left: 0px;
  }
`;

export const BlackInfoText = styled.span`
  color: ${colors.black};
`;

export const UnderlineHeaderSection = styled(Section)`
  color: ${colors.black};
  text-align: center;
  margin-bottom: 20px;
`;