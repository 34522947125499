import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';

const ReviewsTitleContainer = styled.div`
  h3 {
    padding-top: ${calculateRem(10)};
  }
`;

export default ReviewsTitleContainer;
