import {createEntityAdapter} from '@reduxjs/toolkit';
import {createApi, fetchBaseQuery} from '@reduxjs/toolkit/query/react';
import {pickBy} from 'lodash';

import {
  AgeGroup,
  FundingType,
  Provider,
  ProviderPagination,
  QuoteDetail,
  RequestQuoteResult,
  Service,
  ServiceArea,
  Postcode,
  ProviderId,
  Review,
  ReviewResponse,
  Campaign,
  HCPServiceArea,
  Diagnosis,
  ProviderSlug
} from '../state/types';
import {omitUndefined} from '../util/isNil';
import cookie from '../util/cookie';

import * as constants from '../apps/Providers/constants';
import { setLoggedInLegacy } from '../apps/Admin/adminSlice';
import {ProviderSearchOrder} from "../apps/Providers/providersSlice";

export const ageGroupsAdapter = createEntityAdapter<AgeGroup>({
  selectId: ageGroup => ageGroup.code,
});

export const fundingTypesAdapter = createEntityAdapter<FundingType>({
  selectId: fundingType => fundingType.code,
});

interface LoginArgs {
  auth: {
    email: string,
    password: string,
  },
};

interface GetAvailabilityArgs {
  postcodes_list?: string,
  service_areas_list?: string,
  age_groups_list?: string,
  funding_types_list?: string,
  provider?: number
};

interface PaginatedResponse<T> {
  results: T[]
  count: number
  next: string
  previous: string
  pageSize: number
  totalPages: number
}

const karistaApi = createApi({
  reducerPath: 'karistaApi',
  baseQuery: fetchBaseQuery({
    baseUrl: '/api/',
    credentials: 'include',
    prepareHeaders: headers => {
      headers.set('Access-Control-Allow-Origin', '*');
      headers.set('X-CSRFToken', cookie.get('csrftoken'));
      return headers;
    },
  }),
  tagTypes: ['Provider', 'Review'],
  endpoints: (builder) => ({

    login: builder.mutation<{}, LoginArgs>({
      queryFn: async ({ auth }, api, extraOptions, baseFetch) => {
        let r = await baseFetch('/users/set-csrf-cookie/');
        if (r.error !== undefined) {
          return r;
        }
        r = await baseFetch({
          url: '/users/login/',
          method: 'POST',
          body: auth,
        });
        return r as any;
      },
      // When logging in, we invalidate all tags instead of doing a full API cache reset. Resetting the API cache has
      // the consequence of destroying any errors returned from the server, and so we don't want to do that. We do,
      // however, want to have the system remove any potentially sensitive data regardless of the success of the login,
      // just in case.
      invalidatesTags: ['Provider', 'Review'],
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled
          dispatch(setLoggedInLegacy(true));
        } catch (e) {
          dispatch(setLoggedInLegacy(false));
        }
      },
    }),

    logout: builder.mutation<void, void>({
      query: () => ({
        url: '/users/logout/',
        method: 'POST',
      }),
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          await queryFulfilled;
        }
        catch (e) {
        }
        finally {
          // No matter what happens, always clear out all API cache data on logout.
          dispatch(karistaApi.util.resetApiState());
          dispatch(setLoggedInLegacy(false));
        }
      },
    }),

    getAuthState: builder.query<{ authenticated: boolean }, void>({
      query: () => 'users/login-state/',
      onQueryStarted: async (args, { dispatch, queryFulfilled }) => {
        try {
          const response = await queryFulfilled;
          dispatch(setLoggedInLegacy(response.data.authenticated));
        } catch (e) {
          dispatch(setLoggedInLegacy(false));
        }
      },
    }),

    resetPasswordRequest: builder.mutation<void, string>({
      query: (email) => ({
        url: 'users/password-reset/',
        method: 'POST',
        body: { email },
      }),
    }),

    resetPasswordConfirm: builder.mutation<void, any>({
      query: (data) => ({
        url: 'users/password-reset-confirm/',
        method: 'POST',
        body: data,
      }),
    }),

    getAllServices: builder.query<Service[], void>({query: () => 'services/'}),

    getAllServiceAreas: builder.query<ServiceArea[], void>({query: () => 'service-areas/'}),

    getAllHCPServiceAreas: builder.query<HCPServiceArea[], void>({query: () => 'hcp-service-areas/'}),

    getAllDiagnosis: builder.query<Diagnosis[], void>({query: () => 'diagnosis/'}),

    getAllAgeGroups: builder.query<Record<string, AgeGroup>, void>({
      query: () => 'age-groups/',
      transformResponse: (response: AgeGroup[]) => (
        omitUndefined(
          ageGroupsAdapter.setAll(ageGroupsAdapter.getInitialState(), response).entities,
        )
      ),
    }),

    getAllFundingTypes: builder.query<Record<string, FundingType>, void>({
      query: () => 'funding-types/',
      transformResponse: (response: FundingType[]) => (
        omitUndefined(
          fundingTypesAdapter.setAll(fundingTypesAdapter.getInitialState(), response).entities,
        )
      ),
    }),

    getPostcode: builder.query<Postcode[], string>({query: (code) => `postcodes/${code}/`}),

    getPostcodes: builder.query<Postcode[], string>({query: (query) => `postcodes/?query=${query}`}),

    getProviders: builder.query<
      PaginatedResponse<Provider>,
      {
        query?: string,
        service_areas_list?: string,
        funding_types_list?: string,
        age_groups_list?: string,
        postcodes_list?: string,
        order?: ProviderSearchOrder,
        page?: number,
        pageSize?: number,
      }
    >({
      query: (params) => {
        const queryString = Object.keys(omitUndefined(params)).map((key) => {
            return `${encodeURIComponent(key)}=${encodeURIComponent(params[key])}`;
        }).join('&');
        return `providers/?${queryString}`;
      },
      providesTags: ['Provider'],
    }),

    getOwnedProviders: builder.query<
      PaginatedResponse<Provider>,
      { page: number, pageSize: number } | void
    >({
      query: (args) => `provider-admin?page=${(args ? args.page : null) || 1}&pageSize=${(args ? args.pageSize : 10) || 10})`,
      providesTags: ['Provider'],
    }),

    getAvailability: builder.query<{ provider: number }[], GetAvailabilityArgs>({
      query: (query) => ({
        url: `availability/`,
        params: query,
      }),
    }),

    getProviderDetails: builder.query<Provider, ProviderId>({
      query: (providerId) => `providers/${providerId}/`,
      providesTags: (provider) => provider ? [{ type: 'Provider', id: provider.id }] : [],
      transformResponse: (response: Provider) => response,
    }),

    getProviderDetailsUsingSlug: builder.query<Provider, ProviderSlug>({
      query: (providerSlug) => `providers/${providerSlug}/`,
      providesTags: (provider) => provider ? [{ type: 'Provider', url_slug: provider.url_slug }] : [],
      transformResponse: (response: Provider) => response,
    }),

    getProviderDetailsPrivileged: builder.query<Provider, number>({
      query: (providerId) => ({
        url: `provider-admin/${providerId}/`,
        method: 'GET',
      }),
      providesTags: (provider) => provider ? [{type: 'Provider', id: provider.id}] : [],
      transformResponse: (response: Provider) => response,
    }),

    getProviderDetailsPrivilegedUsingSlug: builder.query<Provider, String>({
      query: (providerSlug) => ({
        url: `provider-admin/${providerSlug}/`,
        method: 'GET',
      }),
      providesTags: (provider) => provider ? [{type: 'Provider', url_slug: provider.url_slug}] : [],
      transformResponse: (response: Provider) => response,
    }),

    updateProviderDetails: builder.mutation<Provider, Partial<Provider> & {id: number}>({
      query: (provider) => ({
        url: `provider-admin/${provider.id}/`,
        method: 'PATCH',
        body: {
          age_groups: provider.age_groups,
          description: provider.description,
          funding_types: provider.funding_types,
          name: provider.name,
          postcodes: provider.postcodes,
          regions: provider.regions,
          services: provider.services,
          short_description: provider.short_description,
          url: provider.url,
        },
      }),
      invalidatesTags: (result, error, {id}) => [{type: "Provider", id}]
    }),

    updateProviderLogo: builder.mutation<void, {id: number, file: File}>({
      query: ({id, file}) => {
        const body = new FormData();
        body.set("logo", file);
        return {
          url: `provider-admin/${id}/logo/`,
          method: 'PUT',
          body,
        }
      },
      invalidatesTags: (result, error, {id}) => [{type: "Provider", id}]
    }),

    getProviderReviews: builder.query<PaginatedResponse<Review>, { providerId: number, page?: number, pageSize?: number, onlyUnresponded?: boolean }>({
      query: ({
                providerId,
                page = 1,
                pageSize = 20,
                onlyUnresponded = false
              }) => ({
        url: 'reviews/',
        params: { page, pageSize, onlyUnresponded, provider: providerId },
      }),
      providesTags: ['Review'],
    }),

    createProviderReview: builder.mutation<Review, Review>({
      queryFn: async (data, queryApi, extraOptions, baseQuery) => {
        const response = await baseQuery({
          url: `reviews/`,
          method: 'POST',
          body: data
        })
        if (!response.error) {
          return {
            // TODO: Why does this type need to be forced?
            data: response.data as Review,
            meta: response.meta,
          };
        }
        let saveError =
          'Could not submit review. Please refresh the page and try again.';

        if (response.error.data && typeof response.error.data === 'object' && 'non_field_errors' in response.error.data) {
          // @ts-ignore
          const non_field_errors = response.error.data.non_field_errors;
          if (Array.isArray(non_field_errors) && non_field_errors.length > 0) {
            if (non_field_errors[0] === "The fields email, provider must make a unique set.") {
              saveError = 'You cannot submit more than one review for the same provider.';
            }
          }
        }
        return {
          error: {
            // TODO: Why does this type need to be forced?
            status: response.error.status as number,
            data: saveError,
          },
          meta: response.meta,
        }
      },
    }),

    createReviewResponse: builder.mutation<ReviewResponse, { reviewId: number, comment: string }>({
      query: ({ reviewId, comment }) => ({
        url: `provider-admin/${reviewId}/review-response/`,
        method: 'PUT',
        body: {
          review: reviewId,
          comment,
        },
      }),
      invalidatesTags: ['Review'],
    }),

    deleteReviewResponse: builder.mutation<ReviewResponse, { reviewId: number }>({
      query: ({ reviewId }) => ({
        url: `provider-admin/${reviewId}/review-response/`,
        method: 'DELETE',
      }),
      invalidatesTags: ['Review'],
    }),

    getQuoteDetailForm: builder.query<QuoteDetail, { quoteId: string }>({
      query: ({ quoteId }) => ({
        url: `quotes/${quoteId}/typeform/`,
        method: 'GET'
      }),
    }),

    createRequestForQuoteForm: builder.mutation<RequestQuoteResult, any>({
      query: (data) => {
        // If the user picks "I don't know" as their funding type, act as
        // if they didn't pick anything at all.
        //
        // KAR-247 https://airtable.com/tblB5eRdbXMRLYZZZ/viwZRihupPNr7x20c/recBX03ujhBV9hWKN
        const filteredData = pickBy(
          data,
          (value, key) => key !== 'funding_types_list' || value !== 'i-don-t-know'
        );
        return {
          url: 'quotes/',
          method: 'POST',
          body: filteredData,
        };
      },
    }),

    updateRequestForQuoteForm: builder.mutation<RequestQuoteResult, any>({
      query: (data) => {
        return {
          url: `quotes/${data.id}/`,
          method: 'PATCH',
          body: {
            participant_first_name: data.participant_first_name, 
            participant_last_name: data.participant_last_name, 
            participant_relation: data.participant_relation,
            diagnosis: data.diagnosis,
            hcp_service_areas: data.hcp_service_areas,
            additional_services: data.additional_services,
            gender_preference: data.gender_preference,
            language_preference: data.language_preference,
            day_preference: data.day_preference,
            time_preference: data.time_preference,
            additional_preference: data.additional_preference,
            automate_referral: data.automate_referral,
            hcp_referral: data.hcp_referral
          },
        };
      },
    }),
    
    createMessage: builder.mutation<void, { email: string, message: string }>({
      query: ({ email, message }) => ({
        url: 'messages/',
        method: 'POST',
        body: { email, message },
      }),
    }),

    getCampaign: builder.query<Campaign, string>({
      query: (campaign) => ({
        url: `campaigns/${campaign}/`,
      })
    }),

    createComplaintAndFeedback: builder.mutation<void, any>({
      query: (data) => {
        console.log(data)
        return {
          url: 'feedbackandcomplaints/',
          method: 'POST',
          body: data,
        };
      },
    }),

  }),
});

export const {
  useLoginMutation,
  useLogoutMutation,
  useGetAuthStateQuery,
  useResetPasswordRequestMutation,
  useResetPasswordConfirmMutation,
  useGetAllAgeGroupsQuery,
  useGetAllFundingTypesQuery,
  useGetOwnedProvidersQuery,
  useGetProviderDetailsQuery,
  useGetProviderDetailsUsingSlugQuery,
  useUpdateProviderDetailsMutation,
  useUpdateProviderLogoMutation,
  useGetProviderReviewsQuery,
  useCreateProviderReviewMutation,
  useCreateReviewResponseMutation,
  useDeleteReviewResponseMutation,
  useGetQuoteDetailFormQuery,
  useCreateRequestForQuoteFormMutation,
  useUpdateRequestForQuoteFormMutation,
  useCreateMessageMutation,
  useCreateComplaintAndFeedbackMutation,
} = karistaApi;

export default karistaApi;
