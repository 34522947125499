/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';

import ValidationError from '../ValidationError';
import styles from "./style.module.css";

const FieldContainer = styled.div`
  display: flex;
  height: 40px;
`;

const Input = ({ input, ...props }) => (
  <div {...props}>
    <ValidationError {...props} />
    <FieldContainer>
      <input {...props} {...input} className={styles.formField} />
    </FieldContainer>
  </div>
);

export default Input;
