/* eslint-disable indent */
import React from 'react';
import styled from 'styled-components';

import Label from '../../components/Label';
import ValidationError from '../ValidationError';
import { colors } from '../../styles/constants';
import styles from "./styles.module.css"

const CheckContainer = styled.div`
  input,
  label {
    cursor: pointer;
    position: relative;
  }
  position: relative;
  .checkmark {
    position: absolute;
    top: 3px;
    left: 0;
    height: 18px;
    width: 18px;
    border-radius: 2px;
    background-color: ${colors.white};
    border: 1px solid ${colors.darkPurple};
  }
  input:checked ~ .checkmark {
    background-color: ${colors.darkPurple};
  }
  .checkmark:after {
    content: '';
    position: absolute;
    left: 4px;
    bottom: 4px;
    width: 6px;
    height: 10px;
    border: solid white;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
    display: none;
  }
  input:checked ~ .checkmark:after {
    display: block;
  }
`;

const CheckLabel = styled(Label)`
  padding-left: 30px;
`;

interface CheckboxProps {
  label?: string | null,
  input?: any | null,
  checked?: boolean,
  onClick?: (...args: any[]) => any,
  // TODO: Both `error` and `meta` have been included here, but we should do a survey of how this component is used and
  // reduce to one method of specifying validation errors.
  error?: string,
  meta?: any,
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void, // Optional external onChange callback
};

const Checkbox: React.FC<CheckboxProps> = ({ label, children, input, onChange, ...props }) => {
  const handleInternalChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (input && input.onChange) {
      input.onChange(e);
    }

    if (onChange) {
      onChange(e);
    }
  };

  return (
    <CheckContainer>
      <ValidationError error={props.error} meta={props.meta} />
      <CheckLabel {...props}>
        {label}
        {children}
        <input type="checkbox" {...props} {...input} className={styles.checkboxInput} onChange={handleInternalChange} />
        <span className="checkmark" />
      </CheckLabel>
    </CheckContainer>
  );
};

Checkbox.defaultProps = {
  label: null,
  // children: null
};

export default Checkbox;
