import React from 'react';

import { DeltaHeading } from '../Typography';

const InactiveProviderMessage = ({ providerId, providerName }) => (
  <DeltaHeading>
    This profile is inactive - it is not visible to any Karista clients. If this
    is incorrect please&nbsp;
    <a
      target="_blank"
      style={{ color: 'inherit' }}
      href={`mailto:info@karista.com.au?subject=My profile ${providerName} (${providerId}) is inactive`}
    >
      get in touch
    </a>
  </DeltaHeading>
);

export default InactiveProviderMessage;
