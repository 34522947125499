import styled, { css } from 'styled-components';

import { colors, spin } from '../../styles/constants';

interface StyledButtonProps {
  fullWidth?: boolean,
  shrink?: boolean,
  strong?: boolean,
  spinning?: boolean,
};

export const StyledButton = styled.button<StyledButtonProps>`
  border-radius: 6px;
  cursor: pointer;
  font-family: filson-soft, sans-serif;
  font-size: 18px;
  font-style: normal;
  font-weight: 300;
  line-height: 36px;
  min-height: 60px;
  text-align:center;
  padding: 0 36px;
  &:disabled {
    opacity: 0.3;
    pointer-events: none;
    cursor: not-allowed;
  }
  ${({ fullWidth }) => fullWidth ? `width: 100%;` : ''}
  ${({ shrink }) => shrink ? `padding: 0;` : ''}
  ${({ strong }) => strong ? `font-weight: bold;` : ''}
  ${({ spinning }) =>
    spinning ?
    css`
      color: transparent !important;
      pointer-events: none;
      position: relative;
      cursor: not-allowed;

      &:after {
        animation: ${spin} 500ms infinite linear;
        border-radius: 290486px;
        border-right-color: transparent !important;
        border-top-color: transparent !important;
        content: '';
        display: block;
        height: 1em;
        position: relative;
        width: 1em;
        left: 50%;
        margin-left: -0.5em;
        margin-top: -0.5em;
        position: absolute;
        top: 50%;
        position: absolute !important;
      }
    ` : ''}
`;

export const orange = css`
  background-color: transparent;
  border-color: ${colors.orange};
  color: ${colors.orange};
  &:after {
    border: 2px solid ${colors.orange};
  }
`;

export const primary = css`
  background-color: ${colors.orange};
  border: 1px solid ${colors.orange};
  color: ${colors.darkPurple};
  &:after {
    border: 2px solid ${colors.darkPurple};
  }
`;

export const transparent = css`
  background-color: transparent;
  border-color: ${colors.darkPurple};
  color: ${colors.darkPurple};
  &:after {
    border: 2px solid ${colors.darkPurple};
  }
`;

export const transparentWhite = css`
  background-color: transparent;
  border-color: ${colors.white};
  color: ${colors.white};
  &:after {
    border: 2px solid ${colors.white};
  }
`;

export const LinkButton = styled.button`
  border: none;
  text-decoration: underline;
  background: none;
  background-color: transparent;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  line-height: inherit;
  background: none;
  font-family: inherit;
  padding: 0px;
  cursor: pointer;
`;
