import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeLightGrey } from '../../styles/constants';
import NDISIntroduction from './components/NDIS/NDISIntroduction';

const NDISFundingInformation = () => (
  <>
    <Helmet>
      <title>How to Navigate NDIS Funding and Benefits</title>
      <meta
        name="description"
        content="Learn about the National Disability Insurance Scheme (NDIS) and how its funding can provide essential support and benefits for people with disabilities."
      />
    </Helmet>
    <Theme theme={themeLightGrey}>
      <Navigation>
        <OrangeLink border to="/">
          Back to search
        </OrangeLink>
      </Navigation>

      <NDISIntroduction />
      <Footer />
    </Theme>
  </>
);

export default NDISFundingInformation;
