import React from 'react';
import { withRouter } from 'react-router';

class HistoryListener extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      unlisten: props.history.listen(() => {
        if (window.analytics) {
          window.analytics.page();
        }
      })
    };
  }

  componentWillUnmount() {
    this.state.unlisten();
  }

  render() {
    return null;
  }
}

export default withRouter(HistoryListener);
