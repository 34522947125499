import React, { useState } from 'react';
import StyledLink from '../Link';
import StarRating from '../StarRating';
import InactiveProviderMessage from '../InactiveProviderMessage';
import Button from '../Button';
import { 
  Heading,
  Logo, 
  Container, 
  ReviewLinkContainer, 
  LinkGeneratorContainer, 
  Span, 
  CopyLinkContainer } from './styles';

const ProviderHeader = ({
  providerName,
  providerId,
  providerRating = NaN,
  providerLogoUrl = undefined,
  providerIsActive = true,
  providerSlug,
  fromReviews,
}: ProviderHeaderProps) => {
  const [showLinkInput, setShowLinkInput] = useState(false);
  const [generatedLink, setGeneratedLink] = useState('');
  const [linkCopied, setLinkCopied] = useState(false);

  const generateLink = () => {
    const link = `https://www.karista.com.au/providers/${providerSlug}/${providerId}/review`;
    setGeneratedLink(link);
    setShowLinkInput(true);
  };

  const copyToClipboard = () => {
    // Logic to copy the generated link to the clipboard
    navigator.clipboard.writeText(generatedLink);
    // Set linkCopied to true to change button text
    setLinkCopied(true);
    // Reset linkCopied state after 3 seconds
    setTimeout(() => {
      setLinkCopied(false);
    }, 3000);
  };

  return (
    <Container>
      {providerLogoUrl ? (
        <Logo data-chromatic="ignore" src={providerLogoUrl} />
      ) : null}
      <div>
        <Heading>{providerName}</Heading>
        <StyledLink to={`/providers/${providerSlug}/${providerId}`}>View profile</StyledLink>
        <StarRating range={5} review={providerRating} />
        {!providerIsActive && (
          <InactiveProviderMessage
            providerId={providerId}
            providerName={providerName}
          />
        )}
        {fromReviews && providerIsActive && (<ReviewLinkContainer>
        <StyledLink to="#" onClick={generateLink}>
          Generate link to ask your customers to review on Karista
        </StyledLink>
        <br/>
        {showLinkInput && (
          <LinkGeneratorContainer>
            <Span>{generatedLink}</Span>
            <CopyLinkContainer>
              <Button onClick={copyToClipboard}>{linkCopied ? 'Link Copied' : 'Copy Link'}</Button>
            </CopyLinkContainer>
          </LinkGeneratorContainer>
        )}
        </ReviewLinkContainer>)}
      </div>
    </Container>
  );
};

export type ProviderHeaderProps = {
  providerName: string;
  providerId: string | number;
  providerRating?: number;
  providerLogoUrl?: string;
  providerIsActive?: boolean;
  providerSlug?: string;
  fromReviews?: boolean;
};

export default ProviderHeader;
