import React from 'react';
import moment from 'moment';

import StyledContextDate from './styles';

// This is needed so the logic doesn't change partway through the day
const getToday = now => moment(now.format('YYYY-MM-DD'));

const ContextDate = ({ date }) => (
  <StyledContextDate>
    {moment(date).calendar(undefined, {
      sameDay: '[Today]',
      lastDay: '[Yesterday]',
      lastWeek(now) {
        const today = getToday(now);
        const timeDiff = today.to(date);
        return `[${timeDiff}]`;
      },
      sameElse(now) {
        const today = getToday(now);
        const monthDiff = today.diff(date, 'month', true);

        // If date is more than 6 months but less than 2 years ago, use MMM YYYY format
        if (monthDiff > 6 && monthDiff < 24) {
          return 'MMM YYYY';
        }

        const timeDiff = today.to(date);
        return `[${timeDiff}]`;
      }
    })}
  </StyledContextDate>
);

export default ContextDate;
