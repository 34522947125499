import styled from "styled-components";
import {AlphaHeading, GammaHeading} from "../../components/Typography";
import {colors} from "../../styles/constants";

export const AdminAlphaHeading = styled(AlphaHeading)`
  color: ${colors.midLightPurple};
  font-weight: 600;
  margin-bottom: 0px;
  margin-top: 24px;
`;

export const AdminGammaHeading = styled(GammaHeading)`
  margin-bottom: 0px;
  margin-top: 24px;
`;
