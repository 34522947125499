import React from 'react';
import {RouteComponentProps} from "react-router";
import { Widget } from '@typeform/embed-react'
import {useGetQuoteDetailFormQuery} from "../../../services/karista";
import PageNotFound from "../../Static/PageNotFound";


export type QuoteDetailProps = RouteComponentProps<{ id?: string }>;

export const QuoteDetail: React.FC<QuoteDetailProps> = (props) => {
    const quoteId = props.match.params.id;
    if (!quoteId) {
        return <PageNotFound />;
    }
    const quoteDetailResult = useGetQuoteDetailFormQuery({ quoteId });
    if (!quoteDetailResult.data) {
        return <div />;
    }
    return <Widget id={quoteDetailResult.data.typeform_form_id} hidden={quoteDetailResult.data.typeform_hidden_data} style={{height: '700px'}} />;
}
