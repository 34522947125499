import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import { isEmail, isEmpty } from 'validator';

import Button from '../../../../components/Button';
import Label from '../../../../components/Label';
import Input from '../../../../components/Input';
import Textarea from '../../../../components/Textarea';
import Section from '../../../../components/Section';
import FormItem from '../../../../components/FormItem';
import { useCreateMessageMutation } from '../../../../services/karista';

interface ContactProps {
  setSubmitted: (...args: any[]) => any,
};

type ContactFormData = { email: string, message: string };

type FormContactProps = InjectedFormProps<ContactFormData, ContactProps> & ContactProps;

const Contact: React.FC<FormContactProps> = props => {
  const {
    handleSubmit,
    submitting,
    setSubmitted,
  } = props;

  const [createMessage] = useCreateMessageMutation();

  // TODO: This is not named well. Usually I would call it `handleSubmit`, but that's taken by `redux-form`.
  function onSubmit({ email, message }: ContactFormData) {
    const trimmedEmail = email.trim();
    createMessage({ email: trimmedEmail, message })
      .unwrap()
      .then(() => {
        setSubmitted(true);
      });
  }

  return (
    <Section>
      <p>
        If you tell us what you’re looking for our staff can help you find it.
      </p>
      <form>
        <FormItem>
          <Label htmlFor="message">Tell us what you&apos;re looking for:</Label>
          <Field
            component={Textarea}
            name="message"
            placeholder="Type here"
            rows={3}
          />
        </FormItem>
        <FormItem>
          <Label htmlFor="email">Your email address:</Label>
          <Field
            component={Input}
            name="email"
            placeholder="example@email.com"
            type="text"
          />
        </FormItem>
        <FormItem>
          <Button
            fullWidth
            onClick={handleSubmit(onSubmit || (() => {}))}
            spinning={submitting}
          >
            Get help finding providers
          </Button>
        </FormItem>
      </form>
    </Section>
  );
}

// TODO: Fix these `any` types.
export const validate = (values: any = {}) => {
  const errors: any = {};
  const message = values.message || '';
  const email = values.email || '';
  if (isEmpty(message.trim())) {
    errors.message = 'Your message is required below';
  }
  if (!isEmail(email.trim())) {
    errors.email = 'Please enter a valid email below';
  }
  if (isEmpty(email.trim())) {
    errors.email = 'Your email is required below';
  }
  return errors;
};

const formConfig = {
  fields: ['email', 'message'],
  form: 'contact',
  validate,
};

export default reduxForm<ContactFormData, ContactProps>(formConfig)(Contact);
