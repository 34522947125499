import React from 'react';
import Theme from '../../../../components/Theme';
import { themePrimary } from '../../../../styles/constants';
import IconParticpants from "./icon-participants.svg"
import IconTick from "./icon-tick.svg"
import Breakpoint from '../../../../components/Breakpoint';

import {
    AboutUsParagraph,
    ParticipantIcon,
    TickIcon,
    WhiteHeaderText,
    BetaHeaderHeading,
    DirectoryHeaderSection,
    CSMToDoSection,
    ToDoItems
} from "../../style";

// Shared component for both desktop and mobile versions
const NotJustADirectoryContent = () => (
    <>
        <BetaHeaderHeading>We're not just a directory</BetaHeaderHeading>
        <WhiteHeaderText>
            <AboutUsParagraph>
                Karista provides a free service connecting you with disability and aged
                care services, therapies, and home care supports based on your personal needs.
                Our aim is to assist you to get the right support from the start, 
                quickly, and stress-free.
            </AboutUsParagraph>
            <span style={{ fontSize: '18px', fontWeight: 'bold', marginBottom: '0px' }}>
                But we aren't just a directory. Karista is your partner in care.
                Our dedicated Client Service Managers:
            </span>
        </WhiteHeaderText>
    </>
  );

 // Shared component for both desktop and mobile versions
const ManagerToDoContent = () => (
    <>
        <ToDoItems>
            <TickIcon><img src={IconTick} alt={'Tick'} />
            Discuss your needs and gain an understanding of your goals.</TickIcon>
        </ToDoItems>
            <ToDoItems>
            <TickIcon><img src={IconTick} alt={'Tick'} />
            Help you to get the best value out of your NDIS plan or Home Care Package.</TickIcon>
        </ToDoItems>
        <ToDoItems>
            <TickIcon><img src={IconTick} alt={'Tick'} />
            Conduct a thorough search of providers with availability, so you have a choice.</TickIcon>
        </ToDoItems>
        <ToDoItems>
            <TickIcon><img src={IconTick} alt={'Tick'} />
            Match you with the provider that suits you best.</TickIcon>
        </ToDoItems>
        <ToDoItems>
            <TickIcon><img src={IconTick} alt={'Tick'} />
            Help you complete the paperwork, so you can get on with life!</TickIcon>
        </ToDoItems>
    </>
  );


const NotJustADirectory = () => {

    const NotJustADirectoryDesktop = () => {
        return (
            <Theme theme={themePrimary}>
                <DirectoryHeaderSection> 
                    <div>
                        <NotJustADirectoryContent />
                    </div>
                    <ParticipantIcon>
                        <img src={IconParticpants} alt={'Participants'} />
                    </ParticipantIcon>
                </DirectoryHeaderSection>
                <CSMToDoSection>
                    <ManagerToDoContent />
                </CSMToDoSection>
            </Theme>
        );
    }

    const NotJustADirectoryMobile = () => {
        return (
            <Theme theme={themePrimary}>
                <DirectoryHeaderSection>
                    <ParticipantIcon>
                        <img src={IconParticpants} alt={'Participants'} />
                    </ParticipantIcon>
                    <div>
                        <NotJustADirectoryContent />
                    </div>
                </DirectoryHeaderSection>
                <CSMToDoSection>
                    <ManagerToDoContent />
                </CSMToDoSection>
            </Theme>
        );
    }

    return (
        <Breakpoint>
            {breakpoint => {
                if (breakpoint === 'desktop' || breakpoint === 'tablet') return NotJustADirectoryDesktop();
                return NotJustADirectoryMobile();
            }}
        </Breakpoint>
    );
}   

export default NotJustADirectory;