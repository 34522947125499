import { configureStore } from '@reduxjs/toolkit';
import { setupListeners } from '@reduxjs/toolkit/query/react'
import { persistStore } from 'redux-persist';
import * as Sentry from '@sentry/react';
import ReduxQuerySync from 'redux-query-sync';

import karistaApi from '../services/karista';
import { history } from '../history';

import rootReducer from './rootReducer';

import {
  setProviderSearchParams,
  setProviderSearchPage,
  setProviderSearchOrder,
  mergeProvidersToQuote,
} from '../apps/Providers/providersSlice';

const sentryReduxEnhancer = Sentry.createReduxEnhancer({});

// This keeps the query string (search parameters) in sync with the Redux store. Currently, we're synchronising the
// values needed to support provider searching.
//
// This mechanism of persisting Redux state to the query string is beneficial for a number of reasons:
//
//   * Using the query string over local/session storage to persist data offers the advantage of supporting links into
//     the system with state context.
//   * Local/session storage has potential browser implementation inconsistencies.
//   * Components need only be concerned with interacting with Redux state; there is no need to have to worry about two
//     state storage mechanisms (Redux and the query string).
//   * This is a modular solution to state persistance. If we ever decide to use session storage, no component needs to
//     change, only a Redux enhancer replacement.
//
// While this solution is convenient, it has two code smells I don't like:
//
//   * All knowledge about the values to be sync'd is centralised here. This hurts our ability to break the system into
//     independent domains. NOTE: Lawrence has suggested a solution using a convention similar to reducers, where we
//     import persistence maps from each domain, merge them, and set them here.
//   * The value names must be mutually exclusive. This one isn't so bad, as we never really need to manage it; we just
//     work with the Redux state. Also, given smell #1, exclusivity is guaranteed.
//
const querySyncEnhancer = ReduxQuerySync.enhancer({
  params: {
    query: {
      selector: state => state.providers.providerSearchParams.query,
      action: value => setProviderSearchParams({ query: value }),
    },
    service_areas_list: {
      selector: state => state.providers.providerSearchParams.filters.service_areas_list,
      action: value => setProviderSearchParams({ filters: { service_areas_list: value }}),
    },
    funding_types_list: {
      selector: state => state.providers.providerSearchParams.filters.funding_types_list,
      action: value => setProviderSearchParams({ filters: { funding_types_list: value }}),
    },
    postcodes_list: {
      selector: state => state.providers.providerSearchParams.filters.postcodes_list,
      action: value => setProviderSearchParams({ filters: { postcodes_list: value }}),
    },
    age_groups_list: {
      selector: state => state.providers.providerSearchParams.filters.age_groups_list,
      action: value => setProviderSearchParams({ filters: { age_groups_list: value }}),
    },
    is_subscribed_for_onboarding: {
      selector: state => state.providers.providerSearchParams.filters.is_subscribed_for_onboarding,
      action: value => setProviderSearchParams({ filters: { is_subscribed_for_onboarding: value }}),
    },
    page: {
      selector: state => state.providers.providerSearchParams.page,
      action: value => setProviderSearchPage(value),
      stringToValue: str => parseInt(str),
    },
    order: {
      selector: state => state.providers.providerSearchParams.order,
      action: value => setProviderSearchOrder(value),
    },
    to_quote: {
      // The stringification is here due to an issue with `redux-query-sync`. It uses strict equality to check the value
      // and the `defaultValue`, and as such two lists will not compare equal. Converting to a string allows the
      // comparison to work as expected.
      selector: state => state.providers.providerSearchParams.providersToQuote.map(id => `${id}`).join(","),
      action: value => mergeProvidersToQuote(value),
      stringToValue: str => str ? str.split(',').map(id => parseInt(id)) : [],
      defaultValue: "",
    },
  },
  replaceState: true,
  initialTruth: 'location',
  history,
});

export const store = configureStore({
  reducer: rootReducer,
  enhancers: [sentryReduxEnhancer, querySyncEnhancer],
  middleware: getDefaultMiddleware => getDefaultMiddleware()
    .concat(karistaApi.middleware)
})

// This is needed to support advanced query refetching policies such
// as refresh on browser focus, and refetch on network reconnect.
setupListeners(store.dispatch);

export const persistor = persistStore(store);

export type RootState = ReturnType<typeof store.getState>
export type AppDispatch = typeof store.dispatch
