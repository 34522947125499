/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import { find } from 'lodash';
import Helmet from 'react-helmet';

import RequestAQuoteForm from '../../../components/RequestAQuoteForm';
import Hero from '../../../components/Hero';
import { BetaHeading } from '../../../components/Typography';
import Section from '../../../components/Section';
import calculateRem from '../../../styles/calculateRem';
import { RouteComponentProps } from 'react-router';
import { RequestQuoteResult, ServiceArea } from '../../../state/types';
import { trackQuoteSubmission } from "../tracking";
import { useAppSelector } from '../../../state/hooks';
import { useCreateRequestForQuoteFormMutation } from '../../../services/karista';
import { OrangeLink } from '../../../components/Link';
import { useGetAllAgeGroupsQuery, useGetAllFundingTypesQuery } from '../../../services/karista';
import { reduceQueries } from '../../../util/rtk-query';

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

const Error = () => <div>An error occured, please refresh your browser</div>;

export interface RequestQuoteMultipleProps extends RouteComponentProps {
  requestQuoteResult: RequestQuoteResult,
  serviceAreas: ServiceArea[] | null,
}

const RequestQuoteMultiple: React.FC<RequestQuoteMultipleProps> = props => {
  const {
    requestQuoteResult,
    serviceAreas,
    ...restProps
  } = props;

  const useInitialize = () => reduceQueries({
    ageGroups: useGetAllAgeGroupsQuery(),
    fundingTypes: useGetAllFundingTypesQuery()
  });

  const { ageGroups, fundingTypes, isLoading } = useInitialize();
  const ageGroupsData = ageGroups.data;
  const fundingTypesData = fundingTypes.data

  const { filters, providersToQuote } = useAppSelector(state => state.providers.providerSearchParams);
  const [createRequestForQuote] = useCreateRequestForQuoteFormMutation();

  if (!serviceAreas || isLoading) {
    return <div>Loading ...</div>;
  }

  const selectedServiceArea = find(
    serviceAreas,
    serviceArea =>
      serviceArea.name == filters['service_areas_list']
  );

  const selectedAgeGroup = filters.age_groups_list && ageGroupsData && ageGroupsData[filters.age_groups_list];
  const selectedFundingType = filters.funding_types_list && fundingTypesData && fundingTypesData[filters.funding_types_list];

  function onSubmit(formData) {
    const submitData = Object.assign(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      {},
      {
        providers: providersToQuote,
        service_area: (selectedServiceArea as ServiceArea).id
      },
      { ...formData, ...filters },
    );
    return createRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = filters.service_areas_list;
        await trackQuoteSubmission(service);
        return res;
      }).then((res) => {
        if (filters.funding_types_list && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(filters.funding_types_list)) {
          props.history.push(`/providers/quote-detail/additional-questions/${res.id}`);
        }
        else {
          props.history.push(`/providers/quote-detail/${res.id}`);
        }
      });
  }

  if (providersToQuote.length === 0) {
    return <Error />;
  }

  return (
    <div>
      {!!providersToQuote && (
        <div>
          <Helmet>
            <meta name="robots" content="noindex, nofollow" />
          </Helmet>
          {filters.service_areas_list && filters.postcodes_list && filters.age_groups_list && filters.funding_types_list ? 
             ( <Hero>
                  <BetaHeading>Connect with the best match</BetaHeading>
                    You have selected <b>{providersToQuote.length}</b>
                    {providersToQuote.length > 1 ? ' providers ' : ' provider '}
                    for <b>{filters.service_areas_list}</b> in <b>{filters.postcodes_list}</b> for {" "}
                    <b>{selectedAgeGroup && selectedAgeGroup.description}</b> under <b>{selectedFundingType && selectedFundingType.name}</b>.
                    {" "} 
                    <OrangeLink to="/">
                    Modify search
                    </OrangeLink> 
                    <br></br>
                </Hero>
            ) : (
              <Hero>
                <BetaHeading>Connect with the best match</BetaHeading>
                You have selected <b>{providersToQuote.length}</b>
                {providersToQuote.length > 1 ? ' providers.' : ' provider.'}
              </Hero>
            )}
          <StyledSection>
            <p>
              Please complete the form below so we can ensure you find the <b>best match</b>.
              This information is completely <b>confidential</b> and will not be released to the service provider without your approval.
            </p>
              <RequestAQuoteForm
                selectedServiceArea={selectedServiceArea && selectedServiceArea.id}
                onSubmit={onSubmit}
                serviceAreaCheck={filters.service_areas_list}
                postcode={filters.postcodes_list}
                serviceAreasList={serviceAreas}
              />
          </StyledSection>
        </div>
      )}
    </div>
  );
}

export default RequestQuoteMultiple;
