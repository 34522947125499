import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';
import Section from '../Section';
import mediaQueries from '../../styles/mediaQueries';

export const StyledFooter = styled.footer`
  background-color: ${colors.darkPurple};
  padding: ${calculateRem(8)};
  text-align: center;
`;

export const SubStyledFooter = styled.footer`
  background-color: ${colors.purple};
  padding: ${calculateRem(8)};
  text-align: center;
`;

export const Anchor = styled.a`
  color: ${colors.white};
  margin-right: 8px;
  text-decoration: none;
  cursor: pointer;
`;

export const FooterNavLink = styled.div`
  display: flex;

  ${mediaQueries.mobile`
    display: flex;
    flex-direction: column;
    `};
`;

export const PolicySection = styled(Section)`
  background-color: ${colors.purple};
  display: flex;
  align-items: left;
  padding-top: 10px;
  margin-bottom: 20px;
  flex-direction: row;

  ${mediaQueries.mobile`
    flex-direction: column;
    text-align: left;
  `};
`;

export const Separator = styled.text`
  color: ${colors.orange};
`

export const ContactUs = styled.div`
  background-color: ${colors.orange};
  color: ${colors.purple};
  width: 200px;
  display: flex;
  justify-content: space-around;
  align-items: stretch;
  height: fit-content;
  text-decoration: none;
  font-size: 15px;
  border-radius: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
  margin-left: 30px;
  border-radius: 20px;
  text-align: center;
  cursor: pointer;

  ${mediaQueries.mobile`
    align-items: left;
    margin-left: 0px;
    margin-right: 0px;
  `};
`;

interface StyledSpanProps {
    block?: boolean,
  }
  
// eslint disabled no-confusing-arrow rule for styled-components to use props
export const StyledSpan = styled.span<StyledSpanProps>`
  font-size: ${calculateRem(14)};
  color: ${colors.white};
  display: ${props => (props.block ? 'block' : 'inline')};
`;

export const ContactDropdownContainer = styled.div`
  position: relative;
  right: 36%;
`;

export const ContactDropdown = styled.div`
  position: absolute;
  top: ${calculateRem(-120)};
  left: 42%;
  background-color: ${colors.darkPurple};
  padding: ${calculateRem(12)};
  border-radius: ${calculateRem(5)};
  box-shadow: 0 0 ${calculateRem(10)} rgba(0, 0, 0, 0.2);
  text-align: left;
  width: 270px;

  ${mediaQueries.mobile`
    position: relative;
    top: ${calculateRem(-250)};
    left: 38%;
  `};
`;

export const ListItem = styled.li`
  color: ${colors.white};
`;

export const ResourcesSection = styled(Section)`
  background-color: ${colors.darkPurple};
  display: flex;
  align-items: left;
  padding-top: 16px;
  justify-content: space-around;
  flex-direction: row;
  
  ${mediaQueries.mobile`
    flex-direction: column;
    margin-left: 20px;
  `};
`

export const ResourceHeader = styled.text`
  color: ${colors.orange};
  font-size: 18px;
`

export const ResourceSubHeader = styled.text`
  color: ${colors.white};
  font-size: 16px;
`

export const ResourcesLinkSection = styled.div`
  text-align: left;
  display: flex;
  align-items: left;
  flex-direction: column;

  ${mediaQueries.mobile`
    margin-bottom: 20px;
  `};
`
