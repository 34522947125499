import React, {ReactNode} from 'react';
import IconClientService from "./icon-clientservice.svg"
import IconPerson from "./icon-person.svg"
import IconStar from "./icon-star.svg"
import {
    Card,
    CardIcon, CardIconContainer,
    CardInfoText,
    CardOrangeText, CardSpacer,
    CardsSection,
    CardTopAnchor,
    CardTopIcon,
    CallLinkBlack
} from "../../apps/CampaignHome/style";


interface CardProps {
  cardNumber: number
  iconSrc: string
  iconAlt: string
  header: ReactNode
  detail: ReactNode
}


const StepCard = (props: CardProps) => (
    <CardSpacer>
        <Card>
            <CardTopAnchor>
                <CardTopIcon>{props.cardNumber}</CardTopIcon>
            </CardTopAnchor>
            <CardIconContainer>
                <CardIcon src={props.iconSrc} alt={props.iconAlt} />
            </CardIconContainer>
            <CardOrangeText>{props.header}</CardOrangeText>
            <CardInfoText>{props.detail}</CardInfoText>
        </Card>
    </CardSpacer>
)

export default (props) => (
    <CardsSection>
        <StepCard
            cardNumber={1}
            header="Let us know what supports you need"
            iconSrc={IconClientService}
            iconAlt="Person with headset icon"
            detail={
            <span>
                Complete the online form, call us on <CallLinkBlack href="tel:+61485972676"><b>0485 972 676</b></CallLinkBlack> or live-chat with us to
                let us know about your needs, funding and location.
            </span>
            }
        />
        <StepCard
            cardNumber={2}
            iconSrc={IconPerson}
            iconAlt="Person being matched to services icon"
            header="We connect you with providers with availability"
            detail="The Karista Client Services team will connect you with Providers that
            meet your needs and have capacity."
        />
        <StepCard
            cardNumber={3}
            iconSrc={IconStar}
            iconAlt="Star icon"
            header="You choose the provider that suits you best"
            detail="Karista will then complete the paperwork (with your consent) so you
            can spend less time on admin and more time on the things that matter."
        />
    </CardsSection>
);
