import React, {ReactNode} from 'react';
import DOMPurify from 'dompurify';

import Theme from '../../../../components/Theme';
import { themeSecondary } from "../../../../styles/constants";
import ClipboardIcon from './icon-clipboard-tick.svg';
import CustomerServiceIcon from './icon-cust-service.svg';
import HandHeartIcon from './icon-hand-heart.svg';
import PuzzleIcon from './icon-puzzle.svg';

import {
    HeaderHeading,
    HeaderSection,
    Card,
    CardSpacer,
    CardsSection,
    CardIconContainer,
    CardIcon,
    CardInfoText,
    CardsInternalSection,
    OtherCard
} from "./style";

interface CardProps {
    iconSrc: string
    iconAlt: string
    detail: ReactNode
  }
  
const StepCard = (props: CardProps) => (
    <CardSpacer>
        <Card>
            <CardIconContainer>
                <CardIcon src={props.iconSrc} alt={props.iconAlt} />
            </CardIconContainer>
            <CardInfoText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detail, { ADD_ATTR: ["target"] }) }} />
        </Card>
    </CardSpacer>
)

const OtherStepCard = (props: CardProps) => (
    <CardSpacer>
        <OtherCard>
            <CardIconContainer>
                <CardIcon src={props.iconSrc} alt={props.iconAlt} />
            </CardIconContainer>
            <CardInfoText dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(props.detail, { ADD_ATTR: ["target"] }) }} />
        </OtherCard>
    </CardSpacer>
)

export default (props) => {
    const CommericalModel = () => {
        return (
            <CardsSection>
                <CardsInternalSection>
                    <StepCard
                        iconSrc={CustomerServiceIcon}
                        iconAlt="Person with headset icon"
                        detail="<p><b>Karista is not an NDIS provider and does not charge NDIS participants or their representatives.</b> 
                        Additionally, Karista is not a registered Home Care Package (HCP) provider and does not charge HCP recipients or their representatives.</p>
                        <br/>
                        <p>Karista charges NDIS providers, Home Care Package providers, and Allied Health providers annual subscription and referral fees. 
                        These costs do not affect the pricing for participants, as they are considered part of the providers' operational marketing and administrative expenses.</p>"
                    />
                    <StepCard
                        iconSrc={PuzzleIcon}
                        iconAlt="Puzzle icon"
                        detail="<p>From time to time, <b>Karista may assist you in completing a Service Level Agreement (SLA)</b> with a provider. 
                        Please note that SLAs sent to NDIS participants are not Karista's SLAs; they are agreements for services from NDIS providers. 
                        Karista does not employ workers, provide NDIS services under any of the NDIS registration categories, 
                        or sign SLAs with NDIS participants for the provision of any NDIS services.</p>"
                    />
                </CardsInternalSection>
                <CardsInternalSection>
                    <OtherStepCard
                        iconSrc={ClipboardIcon}
                        iconAlt="Clipboard icon"
                        detail="<b>All NDIS providers subscribed to Karista must comply with the NDIS Worker Screening Clearance</b> and undergo this formal background check. 
                        Clients who are connected to an NDIS provider via the Karista service model should ensure that the NDIS provider completes the worker screening process."
                    />
                    <OtherStepCard
                        iconSrc={HandHeartIcon}
                        iconAlt="Heart in Hand icon"
                        detail="<b>Karista adheres to the <a href='https://www.ndiscommission.gov.au/rules-and-standards/ndis-code-conduct' target='_blank'>NDIS Code of Conduct</a>,</b> which includes acting with integrity, honesty, and transparency. 
                        We take prompt action to raise and address concerns that may impact the quality and safety of supports provided to people with disabilities. 
                        For any complaints, please refer to our  <a href='/complaints-and-feedback'>Complaints and Feedback section</a>."
                    />
                </CardsInternalSection>
            </CardsSection>
        );
    }

    return(
    <Theme theme={themeSecondary}>
        <HeaderSection>
            <HeaderHeading id="KaristaCommericalModel">Karista’s Commercial model</HeaderHeading>
        </HeaderSection>
        <CommericalModel />
    </Theme>
)};