import React from 'react';
import { RouteComponentProps } from 'react-router';
import ProviderDetailsForm from '../../../components/ProviderDetailsForm';

import { FundingType, AgeGroup, Service } from '../../../state/types';
import { Waiting } from './waiting';
import { useCurrentProviderQuery } from '../hooks';
import {useUpdateProviderDetailsMutation, useUpdateProviderLogoMutation} from '../../../services/karista';
import {PROVIDER_TABS_EDIT} from "../constants";
import Section from '../../../components/Section';
import AdminProviderHeading from "../../../components/AdminProviderHeading";

export interface EditProviderProps extends RouteComponentProps<{id?: string, slug?: string}> {
  services: Service[],
  ageGroups: {[k: string]: AgeGroup},
  fundingTypes: {[k: string]: FundingType},
}

const EditProvider = (props: EditProviderProps) => {
  const {
    match: {
      params: { id, slug }
    },
    fundingTypes,
    ageGroups,
    services,
  } = props;

  const [updateProviderDetails] = useUpdateProviderDetailsMutation();
  const [updateProviderLogo] = useUpdateProviderLogoMutation();

  const { data: provider, isLoading, isError } = useCurrentProviderQuery(Number(id));

  if (isLoading) {
    return (
      <div>
        <AdminProviderHeading currentPage={PROVIDER_TABS_EDIT} />
        <Waiting />
      </div>
    );
  }
  if (isError) {
    return (
      <div>
        <AdminProviderHeading currentPage={PROVIDER_TABS_EDIT} />
        <Section>
          You are not authorised to edit this provider.
        </Section>
      </div>
    );
  }

  // What we're doing here is making our submission promise that we send to the form
  // reject if there's an error (RTKQ normally intercepts rejection itself). We unwrap the promise
  // to allow the form to catch said errors and display a message in a simple way.
  const onSubmit = (data) => updateProviderDetails(data).unwrap();
  const onSubmitLogo = (file) => updateProviderLogo({id: provider.id, file: file}).unwrap();
  
  //provider overall review is coming of undefined type
  //It is stored as number but coming as undefined - need to check
  const totalScore = Number(provider.overall_review);
  return (
    <div>
      <AdminProviderHeading
          currentPage={PROVIDER_TABS_EDIT}
          providerName={provider.name}
          providerId={id}
          providerLogoUrl={provider.logoUrl}
          providerRating={totalScore}
          providerIsActive={provider.isActive}
          providerSlug={provider.url_slug}
        />
      <ProviderDetailsForm
        {...provider}
        onSubmit={onSubmit}
        onSubmitLogo={onSubmitLogo}
        fundingTypeOptions={fundingTypes}
        ageGroupOptions={ageGroups}
        serviceOptions={services}
      />
    </div>
  );
}

EditProvider.defaultProps = {
  fundingTypesOptions: [],
  ageGroupOptions: []
};

export default EditProvider;
