import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';
import moment from 'moment';
import { connect } from 'react-redux';

import ContextDate from '../ContextDate';
import FormItem from '../FormItem';
import Textarea from '../Textarea';
import Button from '../Button';

import {
  SwitchableLayout,
  SwitchableLayoutItem,
  ProviderReviewResponseCard,
  ProviderReviewResponseHeading
} from '../ProviderReview/styles';

interface BaseProviderReviewResponseFormProps {
  id: number,
  createReviewResponse: (...args: any) => void,
  deleteReviewResponse: (...args: any) => void,
  onSubmit: () => void,
  onRemove: () => void,
  updated?: string,
};

interface ProviderReviewResponseFormData {
  comment: string,
};

type ProviderReviewResponseFormProps =
  InjectedFormProps<ProviderReviewResponseFormData, BaseProviderReviewResponseFormProps>
  & BaseProviderReviewResponseFormProps;

type HandleSubmitArgs = { comment: string, remove?: boolean };

export const ProviderReviewResponseForm: React.FC<ProviderReviewResponseFormProps> = ({
  id,
  createReviewResponse,
  deleteReviewResponse,
  change,
  handleSubmit,  // From redux-form.
  onSubmit,      // External callback.
  onRemove,      // External callback.
  updated
}) => {
  // TODO: This is awful naming, however we already have `onSubmit` callback from the parent component, `handleSubmit`
  // is owned by `redux-form` here, and so our pickings are slim.
  function innerHandleSubmit({ comment, remove }: HandleSubmitArgs) {
    if (remove) {
      deleteReviewResponse({ reviewId: id })
    } else {
      createReviewResponse({ reviewId: id, comment })
    }
    if (onSubmit) {
      onSubmit();
    }
  }

  return (
    <ProviderReviewResponseCard>
      <form onSubmit={handleSubmit(innerHandleSubmit)}>
        <SwitchableLayout distribute>
          <SwitchableLayoutItem>
            <ProviderReviewResponseHeading>
              Your response
            </ProviderReviewResponseHeading>
          </SwitchableLayoutItem>
          <SwitchableLayoutItem>
            <ContextDate date={updated || moment().format('YYYY-MM-DD')} />
          </SwitchableLayoutItem>
        </SwitchableLayout>
        <FormItem>
          <Field
            name="comment"
            component={Textarea}
            placeholder="Type response here"
            type="text"
          />
        </FormItem>
        <FormItem style={{ textAlign: 'right' }}>
          <Button type="submit">{updated ? 'Update' : 'Reply'}</Button>
          <Button
            type="button"
            onClick={handleSubmit(values => {
              change('comment', '');
              onRemove();
              return innerHandleSubmit({ comment: values.comment, remove: true });
            })}
            style={{ marginLeft: '1rem' }}
          >
            Remove
          </Button>
        </FormItem>
      </form>
    </ProviderReviewResponseCard>
  );
}

ProviderReviewResponseForm.defaultProps = {
  updated: '',
};

// This pre-populates the forms with any existing comments
const mapStateToProps = (store, ownProps) => ({
  ...ownProps,
  initialValues: {
    comment: ownProps.comment,
  },
});

export default connect(mapStateToProps)(
  reduxForm<ProviderReviewResponseFormData, BaseProviderReviewResponseFormProps>({})(ProviderReviewResponseForm)
);
