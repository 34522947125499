import Popup from "reactjs-popup";
import {PopupProps} from "reactjs-popup/dist/types";
import {AdminGammaHeading} from "../../apps/Admin/styles";
import {useDropzone} from "react-dropzone";
import PlaceholderSvg from "../ProviderDetailsForm/Placeholder_view_vector.svg";
import Button from "../Button";
import React from "react";
import './style.css'
import ValidationError from "../ValidationError";

interface FilePopupProps extends Omit<PopupProps, "children"> {
  onSubmit: (file: File) => Promise<any>
  headingText: string
  fileInputName?: string
  children?: React.ReactNode
}

const baseStyle = {
  flex: 1,
  gap: '10px',
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  padding: '20px',
  borderWidth: 2,
  borderRadius: 2,
  borderColor: '#eeeeee',
  borderStyle: 'dashed',
  backgroundColor: '#fafafa',
  color: '#bdbdbd',
  outline: 'none',
  transition: 'border .24s ease-in-out'
};

const focusedStyle = {
  borderColor: '#2196f3'
};

const acceptStyle = {
  borderColor: '#00e676'
};

const rejectStyle = {
  borderColor: '#ff1744'
};

const ImageUploadModal = (props: FilePopupProps) => {
  const [selectedFile, setSelectedFile] = React.useState<File | null>(null);
  const [selectedFileURL, setSelectedFileURL] = React.useState<string | null>(null);
  const [isSubmitting, setIsSubmitting] = React.useState<boolean>(false);
  const [isPristine, setIsPristine] = React.useState<boolean>(true);
  const [validationError, setValidationError] = React.useState<string | null>(null);

  const onDropFile = (acceptedFiles) => {
    setSelectedFile(acceptedFiles[0]);
    setIsPristine(false);
    setValidationError(null);
  }
  const onDropRejected = (drop) => {
    setValidationError("Sorry, only image files up to 1 megabyte are accepted.")
  }
  const {
    getRootProps,
    getInputProps,
    isFocused,
    isDragAccept,
    isDragReject
  } = useDropzone(
      {accept: {'image/*': []}, onDrop: onDropFile, maxSize: 1048576, onDropRejected: onDropRejected});

  const onClickSubmit = (event) => {
    if (selectedFile) {
      event.stopPropagation();
      setIsSubmitting(true);
      props.onSubmit(selectedFile)
          .then(() => setIsPristine(true))
          .finally(() => setIsSubmitting(false));
    }
  };

  const style = React.useMemo(() => ({
    ...baseStyle,
    ...(isFocused ? focusedStyle : {}),
    ...(isDragAccept ? acceptStyle : {}),
    ...(isDragReject ? rejectStyle : {})
  }), [
    isFocused,
    isDragAccept,
    isDragReject
  ]);

  // @ts-ignore
  const rootProps = getRootProps({style});

  React.useEffect(() => setSelectedFileURL(selectedFile ? URL.createObjectURL(selectedFile) : null), [selectedFile]);
  return (
      <Popup open={true} {...props}>
        <div {...rootProps}>
          <input data-selector="dropzone-file-input" name={props.fileInputName} {...getInputProps()} />
          <AdminGammaHeading>{props.headingText}</AdminGammaHeading>
          {selectedFileURL && <img src={selectedFileURL || PlaceholderSvg} className="logo-preview" />}
          <p className="image-upload-modal-subheading">Click to select a file, or drag and drop</p>
          <Button onClick={onClickSubmit} disabled={isPristine || isSubmitting}>
            {isSubmitting? "Uploading..." : "Upload"}
          </Button>
          {!!validationError &&
            <ValidationError meta={{submitFailed: true, error: validationError}} />
          }
          { props.children }
        </div>
      </Popup>
    );
}

export default ImageUploadModal;
