import React from 'react';
import { Helmet } from 'react-helmet';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Theme from '../../components/Theme';
import { themeLightGrey } from '../../styles/constants';
import HCPIntroduction from './components/HCP/HCPIntroduction';

const HCPFundingInformation = () => (
  <>
    <Helmet>
      <title>Understanding Home Care Packages</title>
      <meta
        name="description"
        content="Discover what a Home Care Package (HCP) is and how it supports independent living at home. Learn the steps to obtain your HCP today."
      />
    </Helmet>
    <Theme theme={themeLightGrey}>
      <Navigation>
        <OrangeLink border to="/">
          Back to search
        </OrangeLink>
      </Navigation>

      <HCPIntroduction />
      <Footer />
    </Theme>
  </>
);

export default HCPFundingInformation;
