import GA4 from 'react-ga4';
import { eventCategories } from '../../util/analytics';
import { getCampaign } from "../../util/local-storage";


const SERVICE_REQUEST_EVENT = "Service Request Submission";


export async function trackQuoteSubmission(service) {
  GA4.event({ category: eventCategories.REQUESTS, action: SERVICE_REQUEST_EVENT });
  const { analytics } = (window as any);
  const data = { service, campaign: getCampaign() };
  return analytics.track(`${eventCategories.REQUESTS} - ${SERVICE_REQUEST_EVENT}`, data);
}
