import React from 'react';

import NavTab from '../NavTab';
import { TabContainer } from './styles';
import {
  PROVIDER_TABS_WELCOME,
  PROVIDER_TABS_EDIT,
  PROVIDER_TABS_REVIEWS,
  PROVIDER_TABS_LIST
} from '../../apps/Admin/constants';

const AdminNavTabs = ({ currentPage, providerId, providerSlug } : {currentPage: string, providerId?: string | number | undefined, providerSlug: string | undefined}) => (
  <TabContainer>
    {providerId ? (
      <>
        <NavTab
          active={currentPage === PROVIDER_TABS_WELCOME}
          to="/admin/providers/welcome"
        >
          Welcome
        </NavTab>
        <NavTab
          active={currentPage === PROVIDER_TABS_EDIT}
          to={`/admin/providers/${providerSlug}/${providerId}/edit`}
        >
          My Profile
        </NavTab>
        <NavTab
          active={currentPage === PROVIDER_TABS_REVIEWS}
          to={`/admin/providers/${providerSlug}/${providerId}/reviews`}
        >
          My Reviews
        </NavTab>
      </>) : (
      <>
        <NavTab
          active={currentPage === PROVIDER_TABS_WELCOME}
          to="/admin/providers/welcome"
        >
          Welcome
        </NavTab>
        <NavTab
          active={currentPage === PROVIDER_TABS_LIST}
          to="/admin/providers"
        >
          My Profiles
        </NavTab>
      </>
    )}
  </TabContainer>
);

export default AdminNavTabs;
