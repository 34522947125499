import React, { useEffect, useState } from 'react';
import Helmet from 'react-helmet';
import moment from 'moment-timezone';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import OfflineChat from './OfflineLiveChat';
import { BetaHeading, GammaHeading } from '../../components/Typography';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import { Centered } from '../../components/Layout';

const removeOtherChatElements = () => {
  const offlineChatButton = document.getElementById('offline-chat-container');
  const onlineChatScript = document.getElementById('embedded-service');
  const onlineChatScriptMain = document.getElementById('offline-chat-contaier');
  const chatIframe = document.getElementById('embeddedMessagingFrame');

  if (onlineChatScript) {
    onlineChatScript.style.display = 'none';
    onlineChatScript.remove();
  }
  if (offlineChatButton) {
    offlineChatButton.remove();
  }
  if (onlineChatScriptMain) {
    onlineChatScriptMain.remove();
  }
  if (chatIframe) {
    chatIframe.style.display = 'none';
    chatIframe.remove();
  }

  // Additional clean-up if necessary
  if (window.embeddedservice_bootstrap) {
    window.embeddedservice_bootstrap = undefined;
  }
};

const EmbeddedChat = () => {
  useEffect(() => {
    const initOnlineChat = () => {
      if (process.env.REACT_APP_ENV === 'production') {
        // Append the chat script
        const script = document.createElement('script');
        script.src =
          'https://karista.my.site.com/ESWKaristaLiveChatRete1721867489950/assets/js/bootstrap.min.js';

        script.onload = () => {
          // Initialize the embedded messaging
          const caseNumber = new URLSearchParams(window.location.search).get(
            'caseNumber'
          );
          /* global embeddedservice_bootstrap */
          embeddedservice_bootstrap.settings.language = 'en_US';
          embeddedservice_bootstrap.settings.widgetWidth = '100%';
          embeddedservice_bootstrap.settings.widgetHeight = '100%';

          embeddedservice_bootstrap.init(
            '00D7F000003iTjS',
            'Karista_Live_Chat_Retention',
            'https://karista.my.site.com/ESWKaristaLiveChatRete1721867489950',
            {
              scrt2URL: 'https://karista.my.salesforce-scrt.com'
            }
          );

          // Add an event listener for when the embedded messaging is ready
          window.addEventListener('onEmbeddedMessagingReady', () => {
            if (caseNumber) {
              embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
                CaseNumber: caseNumber
              });
            }
          });
        };

        document.body.appendChild(script);
      } else if (
        process.env.REACT_APP_ENV === 'development' ||
        process.env.REACT_APP_ENV === 'staging'
      ) {
        // Append the chat script
        const script = document.createElement('script');
        script.src =
          'https://karista--uat.sandbox.my.site.com/ESWKaristaLiveChatRete1718342910030/assets/js/bootstrap.min.js';

        script.onload = () => {
          // Initialize the embedded messaging
          const caseNumber = new URLSearchParams(window.location.search).get(
            'caseNumber'
          );
          /* global embeddedservice_bootstrap */
          embeddedservice_bootstrap.settings.language = 'en_US';
          embeddedservice_bootstrap.settings.widgetWidth = '100%';
          embeddedservice_bootstrap.settings.widgetHeight = '100%';

          embeddedservice_bootstrap.init(
            '00D9j0000007d4f',
            'Karista_Live_Chat_Retention',
            'https://karista--uat.sandbox.my.site.com/ESWKaristaLiveChatRete1718342910030',
            {
              scrt2URL: 'https://karista--uat.sandbox.my.salesforce-scrt.com'
            }
          );

          // Add an event listener for when the embedded messaging is ready
          window.addEventListener('onEmbeddedMessagingReady', () => {
            if (caseNumber) {
              embeddedservice_bootstrap.prechatAPI.setHiddenPrechatFields({
                CaseNumber: caseNumber
              });
            }
          });
        };

        document.body.appendChild(script);
      }
    };

    const openChat = () => {
      const chatButton = document.getElementById('esw-fab');
      if (chatButton) {
        chatButton.click();
      }
    };

    removeOtherChatElements();
    initOnlineChat();

    setTimeout(() => {
      openChat();
    }, 3000);

    return () => {};
  }, []);

  return null;
};

const LiveChat = () => {
  const [showOfflineChat, setShowOfflineChat] = useState(false);
  const [showOnlineChat, setShowOnlineChat] = useState(false);

  useEffect(() => {
    removeOtherChatElements();

    const startOfDay = moment()
      .tz('Australia/Melbourne')
      .startOf('day');

    const businessHoursStart = startOfDay
      .clone()
      .set({ hour: 8, minute: 30 })
      .valueOf(); // 9:00 AM in Melbourne time
    const businessHoursEnd = startOfDay
      .clone()
      .set({ hour: 18, minute: 0 })
      .valueOf(); // 5:30 PM in Melbourne time

    // Get current time in Melbourne time zone
    const currentTime = moment().tz('Australia/Melbourne');
    const dayOfWeek = currentTime.day();
    const isWeekday = dayOfWeek >= 1 && dayOfWeek <= 5;
    const currentTimeValue = currentTime.valueOf();

    // Check if current time falls within business hours
    const isWithinBusinessHours =
      isWeekday &&
      (currentTimeValue >= businessHoursStart &&
        currentTimeValue <= businessHoursEnd);

    // Calculate remaining time until the end of business hours
    let remainingTime = 0;
    if (isWithinBusinessHours) {
      remainingTime = businessHoursEnd - currentTimeValue;
    }

    if (remainingTime <= 0) {
      setShowOfflineChat(true);
    } else {
      setShowOnlineChat(true);
    }
  }, []);

  return (
    <>
      <Helmet>
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1, minimum-scale=1"
        />
      </Helmet>
      <Theme theme={themeSecondary}>
        <Navigation>
          <OrangeLink border to="/">
            Search Services
          </OrangeLink>
        </Navigation>
        <Centered>
          <BetaHeading>Thank you for reaching out.</BetaHeading>
          <GammaHeading>
            Please wait as the chat loads and someone from our experienced
            client service team will be with you.
          </GammaHeading>
        </Centered>
        {showOnlineChat && <EmbeddedChat />}
        {showOfflineChat && <OfflineChat />}
        <Footer />
      </Theme>
    </>
  );
};

export default LiveChat;
