import React from 'react';

import { useGetProviderDetailsQuery, useCreateProviderReviewMutation, useGetProviderDetailsUsingSlugQuery } from '../../../services/karista';

import ReviewProviderForm from '../components/ReviewProviderForm';
import { RouteComponentProps } from 'react-router';

export interface ReviewProps extends RouteComponentProps<{id?: string, slug?: string}> {
  providerId: string,
};

const Review: React.FC<ReviewProps> = props => {
  const {
    providerId,
  } = props;

  const providerSlug = props.match.params.slug || ''

  const {isError: isProviderError} = useGetProviderDetailsUsingSlugQuery(props.match.params.slug as string);

  const [
    createProviderReview,
    {isLoading, isSuccess, error},
  ] = useCreateProviderReviewMutation();

  if (isProviderError) {
    window.location.href = "/";
    return null;
  }

  return (
    <div>
      <ReviewProviderForm
        providerId={providerId}
        providerSlug={providerSlug}
        createProviderReview={createProviderReview}
        createProviderReviewStatus={isLoading ? {} : {
          success: isSuccess,
          message: ((error || {}) as any).data,  // TODO: I'm not sure why TS needs the `any` here.
        }}
      />
    </div>
  )
}

export default Review;
