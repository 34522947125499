import styled from 'styled-components';
import { colors, sizes } from '../../styles/constants';
import { DeltaHeading } from '../Typography';

export const ProviderReviewResponseHeading = styled(DeltaHeading)`
  background: ${colors.lightGrey};
`;

const gutter = 24;

interface ProviderReviewCardProps {
  showResponse?: boolean,
};

export const ProviderReviewCard = styled.div<ProviderReviewCardProps>`
  border: 1px solid ${colors.darkPurple};
  border-radius: 6px 6px ${props => (props.showResponse ? '0' : '6px')} 6px;
  justify-content: space-between;
  max-width: ${sizes.tablet}px;
  margin: 0 auto;
  margin-bottom: ${gutter}px;
  padding: ${gutter}px;
  color: ${colors.darkPurple};
`;

export const ProviderReviewResponseCard = styled.div`
  margin: -${gutter}px 0 ${gutter}px ${gutter}px;
  background: ${colors.lightGrey};
  border-radius: 0 0 6px 6px;
  padding: ${gutter}px;
`;

export const StyledReviewDate = styled.div`
  color: ${colors.purple};
`;

interface SwitchableLayoutProps {
  stacked?: boolean,
  distribute?: boolean,
};

export const SwitchableLayout = styled.div<SwitchableLayoutProps>`
  display: flex;
  flex-direction: ${props => (props.stacked ? 'column' : 'row')};
  justify-content: ${props =>
    props.distribute ? 'space-between' : 'flex-start'};
`;

export const SwitchableLayoutItem = styled.div`
  flex: 0 1 auto;
`;
