import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { BetaHeading } from '../Typography';

export default styled.div`
  padding-top: ${calculateRem(48)};
  width: 100%;
`;

export const InlineBetaHeading = styled(BetaHeading)`
  display: inline-block;
  margin-right: 1rem;
`;
