import styled from 'styled-components';
import Checkbox from '../../../../components/Checkbox';

import mediaQueries from '../../../../styles/mediaQueries';
import { colors } from '../../../../styles/constants';
import { BetaHeading } from '../../../../components/Typography';
import { PurpleLink } from '../../../../components/Link';

export const Card = styled.div`
  border: 1px solid ${colors.darkPurple};
  border-radius: 6px;
  margin-bottom: 24px;
  max-width: 100%;
  overflow: hidden;
`;

export const ProviderContainer = styled.section`
  padding: 24px 24px 12px;
`;

export const ProviderHeader = styled.div`
  display: flex;
  ${mediaQueries.mobile`
    display: block;
  `};
`;

export const HeaderInner = styled.div`
  display: flex;
  flex: 1 1 auto;
  justify-content: space-between;
  ${mediaQueries.mobile`
  display: block;
  `};
`;

export const NameContainer = styled.div`
  ${mediaQueries.mobile`
    margin-bottom: 12px;
  `};
`;

export const ProviderName = styled(BetaHeading)`
  margin-bottom: 0;
`;

export const Button = styled(PurpleLink)`
  padding: 12px 24px;
  ${mediaQueries.mobile`
    display: block;
    text-align: center;
    margin-bottom: 12px;
  `};
`;

export const ShortDescription = styled.p`
  margin: 0;
`;

export const ZeroReviewContainer = styled.span`
  margin-left: 10px;
`;

export const ReviewContainer = styled.div`
  display: flex;
`;

interface RequestQuoteContainerProps {
  checked?: boolean,
};

export const RequestQuoteContainer = styled.div<RequestQuoteContainerProps>`
  background-color: ${props =>
    props.checked ? `${colors.lightOrange}` : `${colors.orange}`};
  padding: 12px 24px;
`;

export const ProviderCheck = styled(Checkbox)`
  pointer-events: none;
`;

export const CheckWrapper = styled.span`
  cursor: pointer;
`;
