import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';
import { WideLink } from '../ProfileHeader';

export const StyledList = styled.ul`
  list-style-type: none;
  margin: 0;
  padding: 0;
`;

export const ReviewContainer = styled.div`
  width: 100%;
  display: flex;
  margin-bottom: ${calculateRem(24)};
  a {
    margin-left: ${calculateRem(10)};
  }
`;

export const ButtonsContainer = styled.div`
  padding-bottom: ${calculateRem(24)};
`;

export const TransparentButton = styled(WideLink)`
  background-color: transparent;
  border-color: ${colors.darkPurple};
`;

export const DetailsSection = styled.section`
  margin-bottom: ${calculateRem(24)};
`;
