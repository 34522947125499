/* eslint-disable jsx-a11y/anchor-is-valid */

import React from 'react';
import { RouteComponentProps } from 'react-router-dom';

import { MULTIPLE_PROVIDER_QUOTES } from '../../constants';
import StarRating from '../../../../components/StarRating';
import Link from '../../../../components/Link';

import {
  Card,
  ProviderContainer,
  ProviderHeader,
  HeaderInner,
  NameContainer,
  ProviderName,
  Button,
  ShortDescription,
  ZeroReviewContainer,
  ReviewContainer,
  RequestQuoteContainer,
  ProviderCheck,
  CheckWrapper
} from './style';

import { ProviderId, ProviderSlug } from '../../../../state/types';
import ProviderLogo from '../../../../components/ProviderLogo';

interface SelectProviderProps {
  handleClick: (providerId: ProviderId) => void,
  providerId: ProviderId,
  providersToQuote: ProviderId[],
  label: string,
  providerSlug: ProviderSlug
};

interface SelectProviderState {
  checked: boolean,
};

class SelectProvider extends React.Component<SelectProviderProps, SelectProviderState> {
  static defaultProps = {
    label: null,
    providerId: null,
    providerSlug: null,
    providersToQuote: []
  };

  constructor(props) {
    super(props);
    this.toggleProvider = this.toggleProvider.bind(this);
    this.state = {
      checked: props.providersToQuote.includes(props.providerId)
    };
  }

  componentWillReceiveProps(nextProps) {
    const { providerId, providersToQuote } = nextProps;
    this.setState({
      checked: providersToQuote.includes(providerId)
    });
  }

  toggleProvider(providerId, providersToQuote, handleClick) {
    handleClick(providerId);
  }

  render() {
    const { handleClick, providerId, providerSlug, providersToQuote, label } = this.props;
    return (
      <RequestQuoteContainer checked={this.state.checked}>
        <CheckWrapper
          onClick={e => {
            e.preventDefault();
            this.toggleProvider(providerId, providersToQuote, handleClick);
          }}
        >
          <ProviderCheck
            checked={this.state.checked}
            label={this.state.checked ? "Provider Selected" : label}
            onClick={e => {
              e.preventDefault();
              this.toggleProvider(providerId, providersToQuote, handleClick);
            }}
          />
        </CheckWrapper>
      </RequestQuoteContainer>
    );
  }
}

interface ProviderProps extends RouteComponentProps {
  id: ProviderId,
  logo_url: string,
  short_description: string,
  overall_review: number,
  reviews_count: number,
  name: string,
  providersToQuote: ProviderId[],
  funding_types_list?: string[],
  age_groups_list?: string[],
  canSelect?: boolean,
  url_slug: string,
  onProviderSelect: (providerId: ProviderId) => void,
};

const Provider: React.FC<ProviderProps> = props => {
  const {
    logo_url: logoUrl,
    short_description: shortDescription,
    overall_review: overallReview,
    reviews_count: reviewsCount = 0,
    name,
    providersToQuote,
    location,
    // TODO: The two following props previously had the empty list defaults assigned to them, which is why they're still
    // here, even though they don't seem to be used anywhere. We should review these props, confirm they're not used,
    // and remove them.
    funding_types_list = [],
    age_groups_list = [],
    canSelect,
    url_slug,
    onProviderSelect,
  } = props;
  // TODO: We need to sort out the types of the IDs getting passed around.

  return (
    <Card>
      <ProviderContainer>
        <ProviderHeader>
          {logoUrl && <ProviderLogo src={logoUrl} /> }
          <HeaderInner>
            <NameContainer>
              <ProviderName>{name}</ProviderName>
              <ReviewContainer>
                <StarRating review={overallReview} />
                {reviewsCount > 0 ? (
                  <Link to={`/providers/${url_slug}/${props.id}/reviews`}>
                    {'View'} {reviewsCount} {reviewsCount > 1 ? 'reviews' : 'review'}
                  </Link>
                ) : (
                  <ZeroReviewContainer>0 reviews</ZeroReviewContainer>
                )}
              </ReviewContainer>
            </NameContainer>
            <div>
              <Button border to={`/providers/${url_slug}/${props.id}`}>
                View
              </Button>
            </div>
          </HeaderInner>
        </ProviderHeader>
        <ShortDescription>{shortDescription}</ShortDescription>
      </ProviderContainer>
      {canSelect &&
        MULTIPLE_PROVIDER_QUOTES && (
          <SelectProvider
            label="Select Provider"
            providersToQuote={providersToQuote}
            providerId={props.id}
            providerSlug={props.url_slug}
            handleClick={onProviderSelect}
          />
        )}
    </Card>
  );
}

export default Provider;
