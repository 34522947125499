import styled from 'styled-components';
import { colors } from '../../../../styles/constants';
import { ButtonLink } from '../../../../components/Link';

export const ResetPasswordFormHeader = styled.div`
  display: flex;
  justify-content: space-between;
`;

export const ResetPasswordFormContainer = styled.div`
  color: ${colors.darkPurple};
  max-width: 600px;
  margin: 0 auto;
  padding: 0 24px;
`;

export const SuccessContainer = styled.div`
  margin: 24px auto;
  max-width: 600px;
`;

export const BackToSearchButton = styled(ButtonLink)`
  padding: 12px 24px;
  display: block;
  max-width: 250px;
  text-align: center;
`;

export const ResetPasswordFormOuterContainer = styled.div`
  margin-top: 1.5rem;
`;
