import styled from 'styled-components';
import mediaQueries from "../../styles/mediaQueries";
import React from "react";

interface ProviderLogoProps {
  src: string,
};

const Logo = styled.img<ProviderLogoProps>`
  src: url(${props => props.src});
  object-fit: contain;
  width: 80px;
  max-height: 80px;
`;

export const LogoContainer = styled.div`
  width: 80px;
  max-height: 80px;
  margin-right: 12px;
  display: flex;
  ${mediaQueries.mobile`
    margin-right: 0;
    margin-bottom: 5px;
    margin-top: 0;
  `};
`;

const ProviderLogo = ({ src }: ProviderLogoProps) => <LogoContainer><Logo src={src} /></LogoContainer>;

export default ProviderLogo;
