import styled from 'styled-components';
import Section from '../../../../components/Section';

export const SuccessText = styled.p`
  margin: 24px 0;
`;

export const SuccessTitle = styled.h2`
  margin-top: 36px;
`;

export default styled(Section)`
  margin: 0;
  padding: 0;
  max-width: 100%;
`;

export const SubLabel = styled.p`
  line-height: 24px;
  margin-top: -6px;
  margin-bottom: 6px;
`;
