import React, { useState, useEffect }  from 'react';

import Theme from '../../../../../components/Theme';
import { themeLightGrey, themeSecondary } from '../../../../../styles/constants';
import { ListItem, List } from '../../../style';
import CoreSupportsIcon from "./core-supports-icon.svg";
import SupportCordinatorIcon from "./support-coordination-icon.svg";
import CapitalSupprtIcon from "./capital-support-icon.svg";
import UpArrow from "./up-arrow-icon.svg";
import DownArrow from "./down-arrow-icon.svg"
import { colors } from '../../../../../styles/constants';

import {
  SupportCategorySection,
  NDISFAQSection,
  FAQInfoText,
  CoreSupportIconContainer,
  CoreSupportIcon,
  CoreSupportBuldingSection,
  SupportCordinationIcon,
  CoreSupportBuldingElement,
  SupportButtonsSection,
  CoreSupportIconSection,
  CapiltaSupportIconContainer,
  SupportCategoryHeading,
  CoreSupportMobileIcon,
  CoreSupportMobileIconContainer,
  SupportCordinationMobileIcon,
  DownArrowSection,
} from "../../../style"

import {
  Card,
  CardInfoText,
  CallLink,
} from '../../../../../apps/Providers/containers/styles'

const SupportCategories = () => {

  const [coreSupportsOpen, setCoreSupportsOpen] = useState(true);
  const [capacityBuildingOpen, setCapacityBuildingOpen] = useState(false);
  const [capitalSupportsOpen, setCapitalSupportsOpen] = useState(false);

  useEffect(() => {
    if (window.innerWidth < 821) {
      setCoreSupportsOpen(false);
    }
  }, []);

  const toggleCoreSupports = () => {
    setCoreSupportsOpen(!coreSupportsOpen);
  };

  const toggleCapacityBuilding = () => {
    setCapacityBuildingOpen(!capacityBuildingOpen);
  };

  const toggleCapitalSupports = () => {
    setCapitalSupportsOpen(!capitalSupportsOpen);
  };

  const ArrowIcon = ({ isOpen }) => {
    if (window.innerWidth > 768) {
      return isOpen ? <img src={UpArrow} alt="Up Arrow" /> : <img src={DownArrow} alt="Down Arrow" />;
    } else {
      return null; // Don't render arrow icon for mobile
    }
  };

  const SupportButtons = () => {
    return (
      <SupportButtonsSection>
        <Card style={{marginLeft: '18px'}}>
          <CallLink href="/">
            <CardInfoText><b>Search for supports now</b></CardInfoText>
          </CallLink>
        </Card>
      </SupportButtonsSection>
    );
  }

  const CoreSupports = () => {
    return (
      <Theme theme={themeLightGrey}>
      <div>
        <SupportCategorySection onClick={toggleCoreSupports} style={{paddingTop: '24px'}}>
            <SupportCategoryHeading>
                <b>1. Core supports</b>
                <ArrowIcon isOpen={coreSupportsOpen} />
              </SupportCategoryHeading>
        </SupportCategorySection>
        {coreSupportsOpen && (
          <NDISFAQSection>
            <FAQInfoText style={{color: colors.purple}}>Core Supports are there to support you in your daily life, to meet your needs related to your disability, and to help you achieve your goals. 
              Your Core Supports budget is the most versatile, meaning you can usually use it for any of these four types of support. </FAQInfoText><br></br>
              {window.innerWidth > 768 ? (
                <>
                <List style={{width: '575px', color: colors.black}}>
                  <ListItem ><b>Assistance with daily Life -</b> these supports assist you with your personal tasks in day-to-day life so you can 
                              live as independently as possible and can be provided individually in a range of environments, including your own home.
                  </ListItem><br></br>
                  <ListItem><b>Assistance with social and community participation -</b> these supports help you to take part in community, 
                              social and recreational activities and can be provided in a range of environments, such as in the community or a centre.
                  </ListItem><br></br>
                  <ListItem><b>Consumables -</b> Supports to purchase everyday use items that you need because of your disability. 
                            For example, continence, low-cost assistive technology and Home Enteral Nutrition (HEN) products.</ListItem><br></br>
                  <ListItem><b>Transport -</b> Supports to pay a provider to transport you to an activity that is not itself a support – 
                              or to a support that is delivered by another provider. This support category is for transport from a provider. 
                              If you receive funding for day-to-day transport, like taxis or public transport, you’ll usually use the Transport Recurring support category instead
                  </ListItem><br></br>
              </List>
              <SupportButtons></SupportButtons><br></br>
              <CoreSupportIconContainer>
                <CoreSupportIcon src={CoreSupportsIcon} alt={"Core Supports"} />
              </CoreSupportIconContainer>
            </>) :
            (<>
              <CoreSupportMobileIconContainer>
                <CoreSupportMobileIcon src={CoreSupportsIcon} alt={"Core Supports"} />
              </CoreSupportMobileIconContainer>
              <List style={{color: colors.black}}>
                <ListItem><b>Assistance with daily Life -</b> these supports assist you with your personal tasks in day-to-day life so you can 
                            live as independently as possible and can be provided individually in a range of environments, including your own home.
                </ListItem><br></br>
                <ListItem><b>Assistance with social and community participation -</b> these supports help you to take part in community, 
                            social and recreational activities and can be provided in a range of environments, such as in the community or a centre.
                </ListItem><br></br>
                <ListItem><b>Consumables -</b> Supports to purchase everyday use items that you need because of your disability. 
                          For example, continence, low-cost assistive technology and Home Enteral Nutrition (HEN) products.</ListItem><br></br>
                <ListItem><b>Transport -</b> Supports to pay a provider to transport you to an activity that is not itself a support – 
                            or to a support that is delivered by another provider. This support category is for transport from a provider. 
                            If you receive funding for day-to-day transport, like taxis or public transport, you’ll usually use the Transport Recurring support category instead
                </ListItem><br></br>
              </List>
              <SupportButtons></SupportButtons><br></br>
              <DownArrowSection>
                <img src={UpArrow} alt="Up Arrow" onClick={toggleCoreSupports} />
              </DownArrowSection>
              </>)}
          </NDISFAQSection>
        )}
      </div>
      </Theme>
    );
  }

  const CapacityBuilidngSupports = () => {
    return (
      <Theme theme={themeLightGrey}>
      <div>
        <SupportCategorySection onClick={toggleCapacityBuilding} style={{paddingTop: '24px'}}>
            <SupportCategoryHeading>
                <b>2. Capacity Building supports</b>
                <ArrowIcon isOpen={capacityBuildingOpen} />
            </SupportCategoryHeading>
        </SupportCategorySection>
        {capacityBuildingOpen && (
          <NDISFAQSection>
            <FAQInfoText style={{color: colors.purple}}>Capacity Building supports help you maintain or build your skills and independence. 
              All capacity building supports are stated - this means you must use your funding in the way described in your plan
            </FAQInfoText>
            {window.innerWidth > 768 ? (
              <>
              <CoreSupportBuldingSection>
                <CoreSupportBuldingElement>
                  <List style={{color: colors.black}}>
                      <ListItem><b>Support coordination -</b> Support Coordinators help you understand your plan and connect with NDIS providers, 
                                community and mainstream and other government supports. They help you build your confidence and coordinate your supports.
                      </ListItem><br></br>
                      <ListItem><b>Improved living arrangements -</b>  Supports to help you find and keep an appropriate place to live.
                      </ListItem><br></br>
                      <ListItem><b>Increased social & community participation -</b> Development and training to increase your skills so you can participate in community, 
                                social and recreational activities.
                      </ListItem><br></br>
                      <ListItem><b>Finding and keeping a job -</b> these supports help you find and keep a job and may include employment-related support, 
                                training, and assessments; such as school leaver employment supports.
                      </ListItem>
                  </List>
                </CoreSupportBuldingElement>
                <CoreSupportBuldingElement>
                  <List style={{color: colors.black}}>
                      <ListItem><b>Improved relationships  -</b> Supports to help you develop positive social skills and interact with others in the community.
                      </ListItem><br></br>
                      <ListItem><b>Improved health & wellbeing -</b>  Including exercise or diet advice to manage the impact of your disability. The NDIS does not fund gym memberships. 
                      </ListItem><br></br>
                      <ListItem><b>Improved learning -</b> Supports to assist you to move from school to further education, such as university or TAFE. Examples include training, advice and support. 
                      </ListItem><br></br>
                      <ListItem><b>Improved life choices -</b> Plan management to help you manage your plan, funding and paying for services. 
                      </ListItem><br></br>
                      <ListItem><b>Improved daily living -</b>  Assessment, training or therapy to help increase your skills, independence and community participation. These services can be delivered in groups or individually.
                      </ListItem>
                  </List>
                </CoreSupportBuldingElement>
              </CoreSupportBuldingSection>
              <CoreSupportIconSection>
                <SupportCordinationIcon src={SupportCordinatorIcon} alt={"NDIS Support Coordination"} />
                <SupportButtons></SupportButtons>
              </CoreSupportIconSection>
            </>) :
            (
              <>
                <CoreSupportMobileIconContainer>
                  <SupportCordinationMobileIcon src={SupportCordinatorIcon} alt={"Core Supports"} />
                </CoreSupportMobileIconContainer>
                <List style={{color: colors.black}}>
                    <ListItem style={{color: colors.black}}><b>Support coordination -</b> Support Coordinators help you understand your plan and connect with NDIS providers, 
                              community and mainstream and other government supports. They help you build your confidence and coordinate your supports.
                    </ListItem><br></br>
                    <ListItem><b>Improved living arrangements -</b>  Supports to help you find and keep an appropriate place to live.
                    </ListItem><br></br>
                    <ListItem><b>Increased social & community participation -</b> Development and training to increase your skills so you can participate in community, 
                              social and recreational activities.
                    </ListItem><br></br>
                    <ListItem><b>Finding and keeping a job -</b> these supports help you find and keep a job and may include employment-related support, 
                              training, and assessments; such as school leaver employment supports.
                    </ListItem><br></br>
                    <ListItem><b>Improved relationships  -</b> Supports to help you develop positive social skills and interact with others in the community.
                    </ListItem><br></br>
                    <ListItem><b>Improved health & wellbeing -</b>  Including exercise or diet advice to manage the impact of your disability. The NDIS does not fund gym memberships. 
                    </ListItem><br></br>
                    <ListItem><b>Improved learning -</b> Supports to assist you to move from school to further education, such as university or TAFE. Examples include training, advice and support. 
                    </ListItem><br></br>
                    <ListItem><b>Improved life choices -</b> Plan management to help you manage your plan, funding and paying for services. 
                    </ListItem><br></br>
                    <ListItem><b>Improved daily living -</b>  Assessment, training or therapy to help increase your skills, independence and community participation. These services can be delivered in groups or individually.
                    </ListItem><br></br>
                </List>
                <SupportButtons></SupportButtons><br></br>
                <DownArrowSection>
                  <img src={UpArrow} alt="Up Arrow" onClick={toggleCapacityBuilding} />
                </DownArrowSection>
            </>)}
          </NDISFAQSection>
        )}
      </div>
      </Theme>
    );
  }

  const CapitalSupports = () => {
    return (
      <Theme theme={themeLightGrey}>
      <div>
        <SupportCategorySection onClick={toggleCapitalSupports} style={{paddingTop: '24px'}}>
            <SupportCategoryHeading>
                <b>3. Capital supports</b>
                <ArrowIcon isOpen={capitalSupportsOpen} />
            </SupportCategoryHeading>
        </SupportCategorySection>
        {capitalSupportsOpen && (
          <NDISFAQSection>
            <FAQInfoText style={{color: colors.purple}}>Capital supports are medium and high-cost supports and are stated, meaning you must use your funding in the way described in your plan.</FAQInfoText><br></br>
            {window.innerWidth > 768 ? (
              <>
              <List style={{width: '550px', color: colors.black}}>
                <ListItem><b>Assistive technology -</b> This includes equipment items for mobility, personal care, communication and recreational inclusion such as wheelchairs or vehicle modifications.
                </ListItem><br></br>
                <ListItem><b>Home modifications -</b> Home modifications such as installation of a hand rail in a bathroom, or Specialist Disability Accommodation 
                          for participants who require special housing because of their disability. 
              </ListItem><br></br>
            </List>
            <CapiltaSupportIconContainer>
              <CoreSupportIcon src={CapitalSupprtIcon} alt={"Capital Supports"} />
            </CapiltaSupportIconContainer>
            </>): 
            (<>
              <CoreSupportMobileIconContainer>
                <CoreSupportMobileIcon src={CapitalSupprtIcon} alt={"Core Supports"} />
              </CoreSupportMobileIconContainer>
              <List style={{color: colors.black}}>
                <ListItem><b>Assistive technology -</b> This includes equipment items for mobility, personal care, communication and recreational inclusion such as wheelchairs or vehicle modifications.
                </ListItem><br></br>
                <ListItem><b>Home modifications -</b> Home modifications such as installation of a hand rail in a bathroom, or Specialist Disability Accommodation 
                          for participants who require special housing because of their disability. 
              </ListItem><br></br>
            </List>
            <DownArrowSection>
                <img src={UpArrow} alt="Up Arrow" onClick={toggleCapitalSupports} />
              </DownArrowSection>
            </>)}
          </NDISFAQSection>
        )}
      </div>
      </Theme>
    );
  }

  return (
    <>
      <Theme theme={themeSecondary}>
        <CoreSupports />
        <div style={{ margin: '20px 0' }} />
        <CapacityBuilidngSupports />
        <div style={{ margin: '20px 0' }} />
        <CapitalSupports />
      </Theme>
    </>
  );
}

export default SupportCategories;