import React from 'react';
import { StyledGrid } from './styles';

interface GridProps {
  columns?: number,
  wrap?: 'nowrap' | 'wrap' | 'wrap-reverse' | 'inherit' | 'initial' |  'unset',
  alignItems?: 'normal' |'stretch' | 'center' | 'start' | 'end' | 'flex-start' | 'flex-end' |
    'self-start' | 'self-end' | 'left' | 'right' | 'baseline' | 'first baseline'| 'last baseline' |
    'safe center' | 'unsafe center' | 'inherit' | 'initial' | 'unset',
  spacingWidth?: number,
  spacingUnit?: string,
  children?: React.ReactNode,
};

const Grid: React.FC<GridProps> = props => {
  const {
    columns = 12,
    wrap = 'nowrap',
    alignItems = 'normal',
    spacingWidth = 1,
    spacingUnit = 'rem',
    ...rest
  } = props;

  return (
    <StyledGrid
      columns={columns}
      wrap={wrap}
      alignItems={alignItems}
      spacingWidth={spacingWidth}
      spacingUnit={spacingUnit}
      {...rest}
    />
  );
}

export default Grid;
