import React from 'react';
import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import IconCollaborate from "./icon-provider-collaborate.svg"

import {
  ProviderParagraph,
  ProviderCollaborateIcon,
  HeaderSection,
  HeaderText,
  ProviderIntroHeading,
} from "../../style"

const ProviderIntroductionContent = () => (
  <>
    <ProviderIntroHeading><b>Learn how you can grow your business with Karista</b>
    </ProviderIntroHeading>
    <br />
    <HeaderText>
        <ProviderParagraph>
          <b>Karista offers you a streamlined business service, not just a directory listing. 
          We remove the burden of marketing and administration, 
          so you can get on with delivering outstanding supports.</b>
        </ProviderParagraph>
    </HeaderText>
  </>
);

const ProviderIntroduction = () => {

  return (
    <Theme theme={themeSecondary}>
        <HeaderSection>
          <div>
              <ProviderIntroductionContent />
          </div>
          <ProviderCollaborateIcon>
              <img src={IconCollaborate} alt={"grow your business with Karista"} />
          </ProviderCollaborateIcon>
      </HeaderSection>
    </Theme>
  );
}

export default ProviderIntroduction;
