import React, {useState} from 'react';
import styled from 'styled-components';
import mediaQueries from '../../styles/mediaQueries';
import { colors } from '../../styles/constants';

import BurgerMenu from './hamburgermenu.svg';
import CloseMenu from './closemenu.svg';

const ArrowIcon = () => (
  <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 19 19" width="21" height="21" id="arrow-icon">
    <path fill={colors.orange} d="M12 15.25L5.375 8.625 6.78 7.22 12 12.44 17.22 7.22 18.625 8.625z"/>
  </svg>
);

interface NavBarProps {
  isHidden?: boolean,
};

// TODO: The types here will need to be examined once we've fixed the media queries.
const NavBar = styled.ul<NavBarProps>`
  padding: 0;
  ${mediaQueries.tablet`
    position: fixed;
    width: 260px;
    top: 60px;
    right: 0;
    bottom: 0;
    height: 100%;
    text-align: right;
    background: ${colors.darkPurple};
    transform: ${(props: any) =>
      props.isHidden ? 'translate(280px)' : 'translate(0)'};
  `};
`;

const Arrow = styled.span`
  vertical-align: middle;
`;

const NavLink = styled.li`
  display: inline;
  margin: 0 24px;
  ${mediaQueries.tablet`
    display: block;
    margin: 12px 24px;
  `};
`;

const Link = styled.a`
  text-decoration: none;
  color: ${colors.orange};
  cursor: pointer;
`;

const Dropdown = styled.div`
  position: absolute;
  top: 100%;
  width: auto;
  background: ${colors.darkPurple};
  padding: 10px;
  border-radius: 4px;
  display: none;
  ${NavLink}:hover & {
    display: block;
  }
  ${mediaQueries.tablet`
    position: relative;
    margin-top: 10px;
    width: auto;
    left: 0;
    background: ${colors.purple};
  `}
`;

const DropdownItem = styled.div`
  margin-bottom: 5px;
  padding-bottom: 5px;
  border-bottom: 1px solid ${colors.orange};
  cursor: pointer;
  color: ${colors.orange};
  &:last-child {
    border-bottom: none;
  }
  &:hover {
    color: ${colors.lightOrange};
  }

  ${mediaQueries.tablet`
    font-size: 16px;
  `}
`;

const Burger = styled.button`
  background: transparent;
  border: none;
  margin-left: 24px;
  height: 30px;
  width: 30px;
  display: none;
  ${mediaQueries.tablet`
    display: flex;
    justify-content: center;
    align-items: center;
  `};
  &:hover {
    cursor: pointer;
  }
  &:focus {
    outline: 0;
  }
`;

const navLinks = [
  {
    link: '/about',
    label: 'About us'
  },
  {
    link: '/',
    label: 'Funding Information',
    services: []
  },
  {
    link: '/list-your-organisation',
    label: 'For Providers'
  },
  {
    link: 'https://info.karista.com.au/blog/',
    label: 'Blog'
  }
];

// const NavButtons = ({ isHidden }) => (
//   <NavBar isHidden={isHidden}>
//     {navLinks.map(link => (
//       <NavLink key={`nav-link-${link.label}`}>
//         <Link href={link.link} rel="dofollow">
//           {link.label}
//         </Link>
//       </NavLink>
//     ))}
//   </NavBar>
// );
const NavButtons = ({ isHidden }) => {
  const [isFundingOptionOpen, setIsFundingOptionOpen] = useState(false);

  const handleServicesToggle = () => {
    setIsFundingOptionOpen(!isFundingOptionOpen);
  };

  const fundingOptions = {
    "national-disability-insurance-scheme": "NDIS - National Disability Insurance Scheme",
    "home-care-package-funding": "HCP -  Home Care Package Funding"
  }

  const handleServiceClick = (urlKey) => {
    // Redirect to service page
    window.location.href = `/funding-information/${urlKey}`;
  };

  return (
    <NavBar isHidden={isHidden}>
      {navLinks.map(link => (
        <NavLink key={`nav-link-${link.label}`}>
          {link.services ? (
             <>
             <Link onClick={handleServicesToggle}>{link.label}<Arrow><ArrowIcon /></Arrow></Link>
             <Dropdown style={{ display: isFundingOptionOpen ? 'block' : 'none' }}>
               {Object.keys(fundingOptions).map(key => (
                 <DropdownItem key={key} onClick={() => handleServiceClick(key)}>
                   {fundingOptions[key]}
                 </DropdownItem>
               ))}
             </Dropdown>
           </>
          ) : (
            <Link href={link.link} rel="dofollow">
              {link.label}
            </Link>
          )}
        </NavLink>
      ))}
    </NavBar>
  );
};

export const Hamburger = ({ handleClick, showBurger }) => (
  <Burger onClick={handleClick}>
    {showBurger ? (
      <img src={BurgerMenu} alt="menu" />
    ) : (
      <img src={CloseMenu} alt="close the menu" />
    )}
  </Burger>
);

export default NavButtons;
