import React from 'react';
import styled from 'styled-components';
import StepList from '../StepList';
import StepContent from './StepContent';
import WrappedChildren from '../WrappedChildren';
import { BetaHeading } from '../Typography';

import { colors, themePrimaryTransparent } from '../../styles/constants';

const StyledHeader = styled.header`
  padding: 24px;
  background: ${colors.purple};
  color: ${colors.white};
`;

const StyledSection = styled.nav`
  position: relative;
  top: -18px;
  padding: 0 24px;
`;

interface SteppedNavigationProps {
  title?: string,
  completedSteps?: any[],
  currentStep: number,
  maxSteps: number,
  handleNavigatePrevious: Function,
};

// TODO: replace with a dry-er recomposed component
// count the number of children we receive
// Use that number to figure out how many steps to render
class SteppedNavigation extends React.Component<SteppedNavigationProps> {
  static defaultProps = {
    children: [],
    currentStep: 0,
    maxSteps: 0,
    title: ''
  };

  // TODO: This method seemed to be missing. TypeScript was throwing
  // errors, so I've added it in here. I don't know how this component
  // is intended to work, and I think the best option is a rewrite.
  handleNavigation(currentStep, whatIsThis) {
    console.warn('*** Unhandled call in SteppedNavigation');
  }

  render() {
    // TODO: Would be good to resolve this `any`. I think this whole
    // component could do with a rewrite.
    const children: any[] = this.props.children as any[];
    const {
      title,
      completedSteps,
      currentStep,
      maxSteps,
      handleNavigatePrevious
    } = this.props;
    return children && maxSteps ? (
      <div>
        <StyledHeader>
          <BetaHeading theme={themePrimaryTransparent}>{title}</BetaHeading>
        </StyledHeader>
        <StyledSection>
          <StepList
            maxSteps={maxSteps}
            currentStep={currentStep}
            completedSteps={completedSteps}
            handleNavigation={nextStep => handleNavigatePrevious(nextStep)}
          />
          <StepContent>
            {WrappedChildren(children.slice(currentStep, currentStep + 1), {
              handleCompleteStep: () => this.handleNavigation(currentStep, 1)
            })}
          </StepContent>
        </StyledSection>
      </div>
    ) : null;
  }
}

export default SteppedNavigation;
