import React from 'react';
import { isEmpty, concat } from 'lodash';
import { Field, reduxForm, InjectedFormProps, formValueSelector } from 'redux-form';
import Modal from 'react-modal';
import Button from '../Button';
import Label from '../Label';
import Select from '../Select';
import Input from '../Input';
import { PostcodeSearchBox } from '../SearchBox';

import { Grid, GridItem } from '../Grid';
import { AgeGroup, FundingType, ServiceArea } from '../../state/types';
import { useSelector } from 'react-redux';
import karistaApi from "../../services/karista";
import { reduceQueries } from '../../util/rtk-query';
import ValidationError from '../ValidationError';
import { CampaignHeading, FundingPopUp, FundingInformationLink, CloseButton, FundingPopUpSection, SeparateFundingInformationLink } from '../../../src/apps/CampaignHome/style'
import { Card, CardInfoText, CallLink } from '../../../src/apps/Providers/containers/styles'
import callIcon from '../../../src/apps/Providers/containers/static/icon-phone.png';
import chatIcon from '../../../src/apps/Providers/containers/static/icon-livechat.png';

const iconStyle = {
  width: '20px',
  height: '20px',
};

const postcodeSearchInputComponent = ({ ...rest }) => (
  <Field required component={Input} {...rest} name="postcodes_list" type="search" />
);

interface AdvancedSearchFormProps {
  onSubmit: (...args: any[]) => any,
  toggle?: (...args: any[]) => any,
  showForm?: boolean,
  serviceAreas: ServiceArea[] | null,
  ageGroups?: {[k: string]: AgeGroup},
  fundingTypes?: {[k: string]: FundingType},
  serviceAreaPrefill?: any;
  ageGroupPrefill?: string;
  fundingTypePrefill?: string;
  showHeading?: boolean
};

export interface AdvancedSearchFormData {
  query?: string,
  services_areas_list?: string,
  postcodes_list?: string,
  age_groups_list?: string,
  funding_types_list?: string,
};

const AdvancedSearchForm: React.FC<InjectedFormProps<AdvancedSearchFormData, AdvancedSearchFormProps> & AdvancedSearchFormProps> = ({
  handleSubmit,
  onSubmit,
  serviceAreas,
  ageGroups,
  fundingTypes,
  change,
  serviceAreaPrefill,
  ageGroupPrefill,
  fundingTypePrefill,
  showHeading
}) => {

  const selector = formValueSelector('advanced_search');
  // Setting the current values of the form with formValues
  const formValues = useSelector((state: any) =>
    selector(state, 'postcodes_list', 'service_areas_list', 'age_groups_list', 'funding_types_list')
  );

  const useInitialize = () => reduceQueries({
    // Getting all the postcodes for check
    // Empty String as argument to get all postcodes
    allPostCodes: karistaApi.useGetPostcodesQuery(''),
  });

  const { allPostCodes } = useInitialize();

  const allPostCodesData = allPostCodes.data || [];

  const [postCodeCheck, setPostCodeCheck] = React.useState(true);
  const [serviceAreaCheck, setServiceAreaCheck] = React.useState(false);
  const [ageGroupCheck, setAgeGroupCheck] = React.useState(false);
  const [fundingTypeCheck, setfundingTypeCheck] = React.useState(false);
  const [showPopup, setShowPopup] = React.useState(false);
  
  React.useEffect(() => {

    // Setting the service area to the camapign
    if (!serviceAreaCheck && serviceAreaPrefill) {
      change('service_areas_list', serviceAreaPrefill);
      setServiceAreaCheck(true)
    }

    if (!ageGroupCheck && ageGroupPrefill) {
      change('age_groups_list', ageGroupPrefill);
      setAgeGroupCheck(true)
    }
    
    // Check if the postcode provided is correct and present in available postcodes
    if (
      formValues.postcodes_list && allPostCodesData.length > 0 &&
      new Set(allPostCodesData.map(item => item.code)).has(formValues.postcodes_list))
    {
      setPostCodeCheck(true);
    } else if (!formValues.postcodes_list){
      setPostCodeCheck(true);
    }
    else if (allPostCodesData.length === 0){
      setPostCodeCheck(true);
    }
    else {
      setPostCodeCheck(false);
    }     
  }, [formValues.postcodes_list, allPostCodesData, formValues.service_areas_list]);

  React.useEffect(() => {
    if (!fundingTypeCheck && fundingTypePrefill) {
      change('funding_types_list', fundingTypePrefill);
      setfundingTypeCheck(true)
    }

    if (formValues.funding_types_list === 'i-don-t-know' && !showPopup) {
      // Show the popup
      setShowPopup(true);
    } else {
      // Close the popup if funding type changes
      setShowPopup(false);
    }  
  }, [formValues.funding_types_list]);
  const closePopup = () => {
    // Close the popup
    setShowPopup(false);
  };

  const modalStyles = {
    overlay: {
      filter: 'none'
    },
    content: {
      width: '55%', 
      maxWidth: '700px',
      height: '250px',
      margin: 'auto',
      backgroundColor: 'purple',
      color: 'white',
      padding: '20px',
      borderRadius: '8px',
      fontSize: '48px'
    },
  };

  if (window.innerWidth < 768) {
    modalStyles.content.height = '450px';
    modalStyles.content.fontSize = '28px'; 
    modalStyles.content.width = '65%';
  }

  const openChat = e => {
    e.preventDefault();
    var chatButton = document.getElementById('esw-fab');
    if (chatButton) {
      chatButton.click();
    }
  }

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      { showHeading && serviceAreaPrefill === "Home Care Package Provider" && 
        <CampaignHeading style={{ fontSize: '30px', textAlign: 'center', marginLeft: '124px', marginRight: '124px', marginBottom: '44px' }}>
          Search for Home Care providers in your area now
        </CampaignHeading>
        }
      {(serviceAreaPrefill === "Home Care Package Provider" && fundingTypePrefill === "HCP") ? (
        <Grid wrap="wrap" alignItems="flex-end">
         <GridItem mobile={12} tablet={4}>
         <Label emphasis htmlFor="postcode">
            Postcode or Suburb
         </Label>
         {/* Display error message in case of incorrect postcode value  */}
         {!postCodeCheck && 
             <div style={{"fontSize": 16}}>
               <ValidationError meta={{submitFailed: true, error: "Search & select a postcode to proceed"}} />
             </div>
         }
         <PostcodeSearchBox
           inputComponent={postcodeSearchInputComponent}
           onSuggestionSelected={(e, { suggestionValue }) =>
             change('postcodes_list', suggestionValue)
           }
           placeholder="Type"
           hasRightMargin={false}
           maxAutoSuggestionResults={8}
         />
       </GridItem>
       <GridItem mobile={12} tablet={5}>
       <Label emphasis htmlFor="fundingType">
            Funding level
          </Label>
          {(!!fundingTypes && !isEmpty(fundingTypes)) ? (
            <Field
            component={Select}
            required
            name="funding_types_list"
            fieldOptions={concat(
              Object.keys(fundingTypes)
                .filter(key => fundingTypes[key].hcp_allowed === true)
                .map(key => ({
                  label: fundingTypes[key].name,
                  value: fundingTypes[key].code
                }))
              ,
              { label: "I don't know", value: 'i-don-t-know' }
            )}
          />
          ) : (
            <div>Loading ...</div>
          )}
        </GridItem>
       <GridItem mobile={12} tablet={3}>
        <Button spinning={false} fullWidth shrink strong type="submit" disabled={!postCodeCheck}>
          Search
        </Button>
      </GridItem></Grid>
      ): (<Grid wrap="wrap" alignItems="flex-end">
        <GridItem mobile={12} tablet={6}>
          <Label emphasis htmlFor="service">
            Service required
          </Label>
          {(!!serviceAreas && !isEmpty(serviceAreas)) ? (
            <Field
              component={Select}
              name="service_areas_list"
              fieldOptions={serviceAreas.map(serviceArea => serviceArea.name).sort().map(name => ({value: name, label: name}))}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </GridItem>
        <GridItem mobile={12} tablet={6}>
          <Label emphasis htmlFor="postcode">
            Postcode or Suburb
          </Label>
          {/* Display error message in case of incorrect postcode value  */}
          {!postCodeCheck && 
              <div style={{"fontSize": 16}}>
                <ValidationError meta={{submitFailed: true, error: "Search & select a postcode to proceed"}} />
              </div>
          }
          <PostcodeSearchBox
            inputComponent={postcodeSearchInputComponent}
            onSuggestionSelected={(e, { suggestionValue }) =>
              change('postcodes_list', suggestionValue)
            }
            placeholder="Type"
            hasRightMargin={false}
            maxAutoSuggestionResults={8}
          />
        </GridItem>
        <GridItem mobile={12} tablet={3}>
          <Label emphasis htmlFor="ageGroup">
            Age of recipient
          </Label>
          {(!!ageGroups && !isEmpty(ageGroups)) ? (
            <Field
              component={Select}
              required
              name="age_groups_list"
              fieldOptions={Object.keys(ageGroups).map(key => ({
                label: ageGroups[key].description,
                value: ageGroups[key].code
              }))}
            />
          ) : (
            <div>Loading ...</div>
          )}
        </GridItem>
        <GridItem mobile={12} tablet={6}>
          <Label emphasis htmlFor="fundingType">
            Funding type
          </Label>
          {(!!fundingTypes && !isEmpty(fundingTypes)) ? (
            <Field
            component={Select}
            required
            name="funding_types_list"
            fieldOptions={concat(
              // Check if "Home Care Package" is in the service areas list
              formValues.service_areas_list === "Home Care Package Provider"  ? (
                Object.keys(fundingTypes)
                  .filter(key => fundingTypes[key].hcp_allowed === true)
                  .map(key => ({
                    label: fundingTypes[key].name,
                    value: fundingTypes[key].code
                  }))
              ) : (
                Object.keys(fundingTypes)
                  .filter(key => fundingTypes[key].hcp_allowed === false)
                  .map(key => ({
                    label: fundingTypes[key].name,
                    value: fundingTypes[key].code
                  }))
              ),
              { label: "I don't know", value: 'i-don-t-know' }
            )}
          />
          ) : (
            <div>Loading ...</div>
          )}
          
        </GridItem>
        <GridItem mobile={12} tablet={3}>
          <Button spinning={false} fullWidth shrink strong type="submit" disabled={!postCodeCheck}>
            Search
          </Button>
        </GridItem>
      </Grid>)}
      <Modal
        isOpen={showPopup}
        onRequestClose={closePopup}
        contentLabel="Funding Type Popup"
        // ariaHideApp={false} // Fix for warning in React 17
        style={modalStyles}
      >
         <CloseButton onClick={closePopup}>x</CloseButton>
         <FundingPopUp>
          <p>
            Not sure about funding? We can help.<br />
            <br />
            Use the below options to connect:
          </p>
          <FundingPopUpSection>
            <Card  style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px', marginTop: '10px' }}>
              <img src={callIcon} alt="Call icon" style={iconStyle}/>
              <CallLink href="tel:+61485972676">
                <CardInfoText>Call us on 0485 972 676</CardInfoText>
              </CallLink>
            </Card>

            <Card onClick={openChat} style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px', marginTop: '10px' }}>
              <img src={chatIcon} alt="Chat icon" style={iconStyle} />
              <CardInfoText>Live Chat</CardInfoText>
            </Card>

            <Card onClick={closePopup} style={{ marginRight: '10px', marginLeft: '10px', marginBottom: '10px', marginTop: '10px' }}>
              <CardInfoText>Continue search</CardInfoText>
            </Card>
          </FundingPopUpSection>
          <FundingInformationLink>
            Learn about funding types: &nbsp; 
            <span>&#8226;&nbsp; </span> <SeparateFundingInformationLink href="/funding-information/national-disability-insurance-scheme">NDIS</SeparateFundingInformationLink>&nbsp; &nbsp;  
            <span>&#8226;&nbsp; </span> <SeparateFundingInformationLink href="/funding-information/home-care-package-funding">HCP</SeparateFundingInformationLink>
          </FundingInformationLink>
        </FundingPopUp>
      </Modal>
    </form>
  );
};

AdvancedSearchForm.defaultProps = {
  serviceAreas: [],
  ageGroups: {},
  fundingTypes: {}
};

const formConfig = {
  form: 'advanced_search',
  destroyOnUnmount: false,
  fields: [
    'services_areas_list',
    'postcodes_list',
    'age_groups_list',
    'funding_types_list'
  ]
};

export default reduxForm<AdvancedSearchFormData, AdvancedSearchFormProps>(formConfig)(AdvancedSearchForm);
