import React from 'react';
import styled from 'styled-components';

import mediaQueries from '../../styles/mediaQueries';
import calculateRem from '../../styles/calculateRem';

import { PurpleLink } from '../Link';
import { BetaHeading } from '../Typography';
import ReviewOption from '../ReviewOption';
import InactiveProviderMessage from '../InactiveProviderMessage';
import ProviderLogo from '../ProviderLogo';
import Section from '../Section';
import Button from '../Button';
import {ProviderId} from "../../state";

const HeaderSection = styled(Section)`
  padding-top: ${calculateRem(24)};
`;

const HeadingContainer = styled.div`
  display: flex;
  align-items: center;
  padding-top: ${calculateRem(10)};
`;

const BackButtonContainer = styled.div`
  text-align: right;
`;

const HeadingWrapper = styled.div`
  margin-left: ${calculateRem(10)};
`;

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(24)};
`;

export const WideLink = styled(Button)`
  margin-bottom: ${calculateRem(12)};
  margin-right: ${calculateRem(10)};
  width: 350px;
  ${mediaQueries.mobile`
    width: 100%;
    margin-right: 0;
    padding: 0;
  `};
`;

export const ProviderName = styled(BetaHeading)`
  margin-bottom: 0;
`;

interface ProfileHeaderProps {
  logoUrl?: string | null,
  providerId: ProviderId,
  name: string,
  hasReviews: boolean,
  purple?: boolean,
  reviewsCount?: number,
  isActive?: boolean,
  previousUrl?: string,
  review?: number,
  providerSlug: string;
};

// TODO: The `reviewsCount` cast below is there to account for the
// default value. Once we update how the default values are being set
// (i.e. use unpacking) we can remove that cast.
const ProfileHeader: React.FC<ProfileHeaderProps> = ({
  children,
  logoUrl,
  providerId,
  name,
  hasReviews,
  purple,
  reviewsCount,
  isActive,
  review,
  ...props
}) => (
  <div>
    <HeaderSection>
      <BackButtonContainer>
        <PurpleLink
          caretLeft
          to="/providers"
        >
          search results
        </PurpleLink>
      </BackButtonContainer>
      <HeadingContainer>
        {logoUrl && <ProviderLogo src={logoUrl} />}
        <HeadingWrapper>
          <ProviderName>{name}</ProviderName>
          <ReviewOption
            id={providerId}
            hasReviews={hasReviews}
            review={review}
            reviewsCount={reviewsCount as number}
            {...props}
          />
          {!isActive && (
            <InactiveProviderMessage
              providerId={providerId}
              providerName={name}
            />
          )}
        </HeadingWrapper>
      </HeadingContainer>
    </HeaderSection>
    <StyledSection>{children}</StyledSection>
  </div>
);

ProfileHeader.defaultProps = {
  logoUrl: null,
  purple: false,
  reviewsCount: 0,
  isActive: true
};

export default ProfileHeader;
