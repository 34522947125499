import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';
import mediaQueries from '../../styles/mediaQueries';
import { colors } from '../../styles/constants';
import { Centered } from '../Layout';

export const CenteredWithGutters = styled(Centered)`
  max-width: ${calculateRem(720)};
  margin: ${calculateRem(48)} auto ${calculateRem(12)};
  padding: 0 ${calculateRem(16)};
  text-align: left;
`;

export const BtnContainer = styled.div`
  margin: 0 auto;
`;

export const LeftAligned = styled(Centered)`
  text-align: left;
`;

export const SplitColumnHalfContainer = styled.div`
  display: flex;
  justify-content: space-between;
  ${mediaQueries.mobile`
    flex-direction:column;
  `};
`;

export const InputContainer = styled.div`
  display: flex;
`;

export const FormFieldFlexed = styled.div`
  width: 100%;
  flex: 1 1 0px;
`;

export const Input = styled.input`
  flex: 1;
  border: 1px solid #000000;
  border-radius: 6px;
  color: ${colors.darkPurple};
  font-size: ${calculateRem(18)};
  line-height: ${calculateRem(36)};
  max-height: ${calculateRem(60)};
  min-height: ${calculateRem(60)};
  padding: 6px 12px;
  width: 100%;
  &:disabled {
    pointer-events: none;
  }
`;

export const Textarea = styled.textarea`
  flex: 1;
  border: 1px solid #000000;
  border-radius: 6px;
  color: ${colors.darkPurple};
  font-size: ${calculateRem(18)};
  line-height: ${calculateRem(36)};
  max-height: ${calculateRem(60)};
  min-height: ${calculateRem(60)};
  padding: 6px 12px;
  width: 100%;
  margin-bottom: ${calculateRem(16)};
  resize: none;
  &:disabled {
    pointer-events: none;
  }
`;
