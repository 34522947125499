import React from 'react';
import styled from 'styled-components';
import { colors } from '../../../../styles/constants';

import AccountIcon from './account-icon.svg';
import AccountIconActive from './account-icon-active.svg';

interface NavBarProps {
  isHidden?: boolean,
};

const NavBar = styled.ul<NavBarProps>`
  padding: 0;
  position: fixed;
  width: 260px;
  top: 60px;
  right: 0;
  bottom: 0;
  height: 100%;
  text-align: right;
  background: ${colors.darkPurple};
  transform: ${props => (props.isHidden ? 'translate(280px)' : 'translate(0)')};
`;

interface NavLinkProps {
  isActive?: boolean,
  iconActive: string,
  icon: string,
};

const NavLink = styled.li<NavLinkProps>`
  display: block;
  padding: 12px 12px;
  background: url(${props => (props.isActive ? props.iconActive : props.icon)})
    no-repeat 15px;
  color: ${props => (props.isActive ? colors.darkPurple : colors.orange)};
  background-color: ${props =>
    props.isActive ? colors.orange : colors.darkPurple};
  &:hover {
    background: url(${props => props.iconActive}) no-repeat 15px
      ${colors.orange};
  }
  &:hover > a {
    color: ${colors.darkPurple};
  }
`;

interface LinkProps {
  isActive?: boolean,
};
                           
const Link = styled.a<LinkProps>`
  width: 100%;
  display: inline-block;
  padding: 0 0;
  text-decoration: none;
  color: ${props => (props.isActive ? colors.darkPurple : colors.orange)};
  background-color: transparent;
`;

interface ProfileProps {
  isActive?: boolean,
};

const Profile = styled.button<ProfileProps>`
  background-color: ${props =>
    props.isActive ? 'transparent' : colors.orange};
  border: none;
  margin-left: 24px;
  height: 60px;
  width: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  &:hover {
    cursor: pointer;
    background-color: ${props =>
      props.isActive ? colors.darkPurple : colors.orange};
  }
  &:focus {
    outline: 0;
  }
`;

const DropdownButtons = ({
  isHidden,
  dropDownLinks,
  location: { pathname }
}) => (
  <NavBar isHidden={isHidden}>
    {dropDownLinks.map(link => {
      if (link.show) {
        return (
          <NavLink
            key={`nav-link-${link.label}`}
            icon={link.icon}
            iconActive={link.iconActive}
            isActive={link.link === pathname}
          >
            <Link
              href={link.link}
              onClick={link.onClick}
              isActive={link.link === pathname}
            >
              {link.label}
            </Link>
          </NavLink>
        );
      }
      return '';
    })}
  </NavBar>
);

export const ProfileButton = ({ handleClick, showProfile }) => (
  <Profile id="userProfileButton" onClick={handleClick} isActive={showProfile}>
    {showProfile ? (
      <img src={AccountIcon} alt="menu" />
    ) : (
      <img src={AccountIconActive} alt="close the menu" />
    )}
  </Profile>
);

export default DropdownButtons;
