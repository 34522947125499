import React, { useEffect } from 'react';

const OfflineChat = () => {
  const participantName = new URLSearchParams(window.location.search).get(
    'name'
  );

  const toggleOfflineChat = () => {
    const chatContainer = document.getElementById('chat-container');
    const chatIcon = document.getElementById('chat-icon');
    const arrowIcon = document.getElementById('chat-arrow-icon');
    const chatForm = document.getElementById('chat-form');

    if (
      chatContainer.style.display === 'none' ||
      chatContainer.style.display === ''
    ) {
      chatContainer.style.display = 'block';
      chatIcon.style.display = 'none';
      arrowIcon.style.display = 'block';
    } else {
      chatContainer.style.display = 'none';
      chatIcon.style.display = 'block';
      arrowIcon.style.display = 'none';
      chatForm.style.display = 'none';
    }
  };

  const toggleDropdown = () => {
    const dropdownContent = document.getElementById('dropdown-content');
    if (
      dropdownContent.style.display === 'none' ||
      dropdownContent.style.display === ''
    ) {
      dropdownContent.style.display = 'block';
    } else {
      dropdownContent.style.display = 'none';
    }
  };

  const endConversation = () => {
    const dropdownContent = document.getElementById('dropdown-content');
    const chatContainer = document.getElementById('chat-container');
    chatContainer.style.display = 'none';
    dropdownContent.style.display = 'none';
  };

  useEffect(() => {
    toggleOfflineChat();
  }, []);

  return (
    <div id="offline-chat-container">
      <div
        className="chat-button-container"
        id="chat-button"
        onClick={toggleOfflineChat}
        onKeyPress={e => {
          if (e.key === 'Enter') toggleOfflineChat();
        }}
        role="button"
        tabIndex="0"
      >
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          id="chat-icon"
        >
          <path
            fill="white"
            d="M3 18v3h3l7-2-7-2H3zM21 3H3v14h8l2 2h8V5c0-1.1-.9-2-2-2z"
          />
          <path d="M0 0h24v24H0z" fill="none" />
        </svg>
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 24 24"
          width={24}
          height={24}
          id="chat-arrow-icon"
          style={{ display: 'none' }}
        >
          <path
            fill="black"
            d="M12 15.25L5.375 8.625 6.78 7.22 12 12.44 17.22 7.22 18.625 8.625z"
          />
        </svg>
      </div>
      <div className="chat-container" id="chat-container">
        <div className="chat-header">
          <div className="dropdown">
            <svg
              className="dots-icon"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 24 24"
              onClick={toggleDropdown}
              onKeyPress={e => {
                if (e.key === 'Enter') toggleDropdown();
              }}
              role="button"
              tabIndex="0"
            >
              <circle cx={12} cy={12} r={2} />
              <circle cx={12} cy={5} r={2} />
              <circle cx={12} cy={19} r={2} />
            </svg>
            <div
              className="dropdown-content"
              id="dropdown-content"
              style={{ display: 'none' }}
            >
              <button type="button" onClick={endConversation}>
                End Conversation
              </button>
            </div>
          </div>
          <img src="./favicon.ico" alt="Karista" />
          <h1>Request a call back</h1>
        </div>
        <div className="chat-image" id="chat-image">
          <img
            src="./image-chat-offline.png"
            alt="Karista offline chat form to request call back"
          />
        </div>
        <div className="chat-form">
          <form
            action="https://karista--uat.sandbox.my.salesforce.com/servlet/servlet.WebToCase?encoding=UTF-8&orgId=00D9j0000007d4f&RecordTypeId=0129j000000qCMrAAM"
            method="POST"
            target="_top"
          >
            <input type="hidden" name="orgid" defaultValue="00D9j0000007d4f" />
            <input
              type="hidden"
              name="retURL"
              defaultValue="http://staging.karista.com.au/live-chat-request-success"
            />
            <input
              type="hidden"
              name="recordType"
              id="recordType"
              defaultValue="012980000004ISpAAM"
            />
            <input
              type="hidden"
              name="origin"
              id="origin"
              defaultValue="karista.com.au"
            />

            {participantName && (
              <p>
                Hi {participantName}, we are currently offline. We have your
                contact details; please let us know the best time to reach out
                to you. I will reach out to you once I am online.
              </p>
            )}
            <br />
            <label
              htmlFor="Best_Time_to_Call__c"
              id="Best_Time_to_Call__c_label"
            >
              Preferred time to contact:
            </label>
            <br />
            <input
              id="Best_Time_to_Call__c"
              maxLength="80"
              name="Best_Time_to_Call__c"
              size="20"
              type="text"
            />
            <br />
            <br />

            <input type="submit" name="submit" id="submit-button" />
          </form>
        </div>
      </div>
    </div>
  );
};

export default OfflineChat;
