/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
// import { find } from 'lodash';

import Hero from '../../../components/Hero';
import { BetaHeading } from '../../../components/Typography';
import Section from '../../../components/Section';
import calculateRem from '../../../styles/calculateRem';
import { RouteComponentProps } from 'react-router';
import { Diagnosis, HCPServiceArea, ServiceArea } from '../../../state/types';
import { useAppSelector } from '../../../state/hooks';
import { useUpdateRequestForQuoteFormMutation } from '../../../services/karista';
import AdditionalQuestionsForm from '../../../components/AdditionalQuestionsForm';
import { trackQuoteSubmission } from "../tracking";

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

export interface AdditionalQuoteQuestionProps extends RouteComponentProps<{ id?: string }> {
  serviceAreas: ServiceArea[] | null,
  hcpServiceAreas: HCPServiceArea[] | null,
  diagnosis: Diagnosis[] | null
  
}

const AdditionalQuestions: React.FC<AdditionalQuoteQuestionProps> = props => {
  const {
    serviceAreas,
    hcpServiceAreas,
    diagnosis,
    ...restProps
  } = props;

  const quoteId = props.match.params.id;

  const { filters, providersToQuote } = useAppSelector(state => state.providers.providerSearchParams);
  const [updateRequestForQuote] = useUpdateRequestForQuoteFormMutation();

  if (!serviceAreas || !hcpServiceAreas || !diagnosis) {
    return <div>Loading ...</div>;
  }

  function onSubmit(formData) {
    const submitData = Object.assign(                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                                      
      {},
      {
        id: quoteId
      },
      { ...formData, ...filters },
    );
    return updateRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = filters.service_areas_list;
        await trackQuoteSubmission(service);
        return res;
      })
      .then((res) => {
        props.history.push(`/providers/quote-success`);
      });
  }

  return (
    <div>
        <Hero>
            <BetaHeading>Connect with the best Home Care Provider</BetaHeading>
        </Hero>
        <StyledSection>
        <p>
            Please complete the form below so we can ensure you find the <b>best match</b>.
        </p>
        <AdditionalQuestionsForm
            hcpServiceAreas={hcpServiceAreas}
            onSubmit={onSubmit}
            serviceAreas={serviceAreas}
            diagnosis={diagnosis}
        />
        </StyledSection>
    </div>
  );
}

export default AdditionalQuestions;
