import styled from 'styled-components';
import { BetaHeading } from '../Typography';
import { colors } from '../../styles/constants';
import mediaQueries from '../../styles/mediaQueries';

export const Heading = styled(BetaHeading)`
  font-weight: 300;
  margin-bottom: 0;
`;

export const Logo = styled.img`
  flex: 0 1 auto;
  width: 80px;
  margin-right: 24px;
`;

export const Container = styled.div`
  display: flex;
  align-items: center;
`;

export const ReviewLinkContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 10px;
`;

export const LinkGeneratorContainer = styled.div`
  display: flex;
  align-items: center;
  flex-direction: row;
  margin-bottom: 10px;
  background: #f0f0f0;
  padding: 8px;
  border-radius: 4px;

  ${mediaQueries.mobile`
    flex-direction: column;
    align-items: flex-start;
  `};
`;

export const Span = styled.span`
  color: ${colors.purple};
  font-size: 14px;
`;

export const CopyLinkContainer = styled.div`
  margin-left: 8px;

  ${mediaQueries.mobile`
    margin-left: 0px;
  `};
`;
