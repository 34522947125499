import { RouteComponentProps } from 'react-router';
import { createSelector } from 'reselect';
import { parse } from 'qs';

import { RootState } from '../../state/store';

import { AdminState } from './adminSlice';

const selectLocation = (state: RootState, { location }: RouteComponentProps) => ({ ...location });

// extract the query string as an object
const selectQuery = createSelector(selectLocation, ({ search }) =>
  parse(search, { ignoreQueryPrefix: true })
);

const selectAdminState = ({ admin }: RootState): AdminState => ({ ...admin });
const selectAuth = createSelector(selectAdminState, state => state.auth);
const selectAuthError = createSelector(selectAdminState, state => state.authError);
const selectIsAuthenticated = createSelector(
  selectAdminState,
  state => !!state.auth ? state.auth.isAuthenticated : false,
);
const selectPostcodes = createSelector(selectAdminState, state => state.postcodes);
const selectShowOnlyUnrespondedReviews = createSelector(
  selectAdminState,
  state => state.showOnlyUnrespondedReviews,
);

export {
  selectAdminState,
  selectAuth,
  selectAuthError,
  selectIsAuthenticated,
  selectPostcodes,
  selectQuery,
  selectShowOnlyUnrespondedReviews,
};
