import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  Postcode,
  Provider,
  AuthSlice,
  AuthError,
} from '../../state/types';

export interface AdminState {
  currentProvider: Provider | null,
  postcodes: Postcode[] | null,
  showOnlyUnrespondedReviews: boolean,
  auth: AuthSlice,
  authError: AuthError | null,
  id?: string,  // TODO: This is the provider ID from refreshing the authentication token. Definitely need a better
                // name.
};

const initialState: AdminState = {
  currentProvider: null,
  postcodes: null,
  showOnlyUnrespondedReviews: false,
  auth: {
    isAuthenticated: false,
  },
  authError: null, // used to store any auth errors, 401, 403, 405
};

export const adminSlice = createSlice({
  name: 'admin',
  initialState,
  reducers: {
    setCurrentProvider: (state, action: PayloadAction<Provider | null>) => {
      state.currentProvider = action.payload;
    },
    toggleShowOnlyUnrespondedReviews: (state) => {
      state.showOnlyUnrespondedReviews = !state.showOnlyUnrespondedReviews;
    },
    setLoggedInLegacy: (state, action: PayloadAction<boolean>) => {
      state.auth.isAuthenticated = action.payload;
    },
  },
});

// TODO: This type can probably be removed once we start using hooks.
export type AdminActions = typeof adminSlice.actions;

export const {
  setCurrentProvider,
  toggleShowOnlyUnrespondedReviews,
  setLoggedInLegacy,
} = adminSlice.actions;

export default adminSlice.reducer
