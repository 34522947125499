import React from 'react';

import Container, { InlineBetaHeading } from './styles';

const AdminProviderReviewTitle = ({ allReviews, reviewCount, children }) => (
  <Container>
    <InlineBetaHeading>
      {allReviews
        ? 'All reviews'
        : `${reviewCount} review${
            reviewCount === 1 ? '' : 's'
          } pending response`}
    </InlineBetaHeading>
    {children}
  </Container>
);

AdminProviderReviewTitle.defaultProps = {
  children: null
};

export default AdminProviderReviewTitle;
