import React from "react"
import Select, {components} from "react-select";


const AccessibleClearIndicator = (props) => (
    <components.ClearIndicator {...props} className="clear-select" />
)


const AccessibleReactSelect = (props) => (
    <Select {...props} className="accessible-react-select" components={{ClearIndicator: AccessibleClearIndicator}} />
)

export default AccessibleReactSelect;
