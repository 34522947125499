/* eslint-disable react/no-unused-state */
/* eslint-disable class-methods-use-this */
/* eslint-disable max-len */
/* eslint-disable consistent-return */

import React from 'react';
import { isEmail } from 'validator';
import {isValidPhoneNumber} from "libphonenumber-js";
import {Controller, useForm} from "react-hook-form";
import '../../../node_modules/react-dates/lib/css/_datepicker.css';
import { concat } from 'lodash';
import Button, {LinkButton} from '../Button';
import Input from '../Input';
import Select from '../Select';
import Checkbox from '../Checkbox';
import ValidationError from '../ValidationError';
import FormItem from '../FormItem';
import {
  CenteredWithGutters,
  StyledLabel,
  BtnContainer,
  BottomLink,
} from './styles';
import {ServiceArea, AgeGroup, FundingType} from "../../state";
import styles from "../../styles/global.module.css"
import {WhiteAnchor} from "../Link";
import karistaApi from "../../services/karista";
import {PostcodeSearchBox} from "../SearchBox";

const RenderCheckbox = (props) => (
  <Checkbox {...props}>
    I agree to the Karista{' '}
    <a href="/terms-and-conditions" target="_blank">
      Terms and Conditions
    </a>{' '}
    and{' '}
    <a href="/privacy-policy" target="_blank">
      Privacy Policy
    </a>
  </Checkbox>
);

interface RequestAQuoteFormProps {
  onSubmit: (data: RequestAQuoteSubmitData) => Promise<void>,
  providerId?: number,
  postcode?: string,
  serviceAreasList?: ServiceArea[],
  selectedServiceArea?: number,
  fundingType?: string,
  fundingTypeList?: {[k: string]: FundingType},
  ageGroup?: string,
  ageGroupList?: {[k: string]: AgeGroup},
  serviceAreaCheck?: string
  providerSlug?: string,
}

// These types are for ease of managing form state, final
// data types for submission are below.
type FormValues = {
  name: string
  email: string
  postcode: string
  phone: string
  service_area: string
  funding_types_list: string
  age_groups_list: string
  terms: boolean
  hcp_referral: boolean
}

// This is different from the form state values.
// The transformation happens on delegation to the submit handler.
export type RequestAQuoteSubmitData = {
  name: string
  email: string
  postcode: string | null
  phone: string
  service_area: number
  funding_types_list: string
  age_groups_list: string
  terms: boolean
  hcp_referral: boolean
}

const RequestAQuoteForm = (props: RequestAQuoteFormProps) => {
  const {
    register,
    formState,
    handleSubmit,
    setValue,
    watch,
    reset,
    control,
    setError,
    trigger
  } = useForm<FormValues>({
    mode: "all",
    defaultValues: {
      name: "",
      email: "",
      postcode: props.postcode || "",
      phone: "",
      service_area: props.selectedServiceArea? props.selectedServiceArea.toString() : "",
      age_groups_list: props.ageGroup? props.ageGroup.toString() : "",
      funding_types_list: props.fundingType? props.fundingType.toString() : "",
      terms: false,
      hcp_referral: false
    }
  });

  const [getPostcode] = karistaApi.useLazyGetPostcodeQuery();
  const [getAvailability] = karistaApi.useLazyGetAvailabilityQuery();
  const [overridePostcode, setOverridePostcode] = React.useState(false);
  const [overrideAvailability, setOverrideAvailability] = React.useState(false);
  const postcodeInputRef = React.useRef();
  const postcodeWatch = watch("postcode");
  const [updatedFundingTypeList, setUpdatedFundingTypeList] = React.useState<{ label: string; value: string }[]>([]);

  // Reset the "submit anyway" buttons if the postcode changes
  React.useEffect(() => {
    if (postcodeWatch) {
      trigger("postcode");
    }
  }, [overridePostcode, overrideAvailability]);
  React.useEffect(() => {
    if (overridePostcode) {
      setOverridePostcode(false);
    }
    if (overrideAvailability) {
      setOverrideAvailability(false);
    }
  }, [postcodeWatch]);

  const {errors} = formState;
  
  const setFundingTypeBasedOnServiceArea = async (selectedServiceAreaForFunding) => {

    if (!props.providerId || !props.serviceAreasList || !props.fundingTypeList) {
      return;
    }
    const pickedServiceArea = props.serviceAreasList.find(sa => sa.id === parseInt(selectedServiceAreaForFunding));
    const serviceAreaName = pickedServiceArea ? pickedServiceArea.name : ""  

    if (props.fundingTypeList) {
      if(serviceAreaName === "Home Care Package Provider") {
        const filteredFundingTypes = Object.keys(props.fundingTypeList)
          .filter(key => props.fundingTypeList && props.fundingTypeList[key].hcp_allowed === true)
          .map(key => ({
            label: props.fundingTypeList && props.fundingTypeList[key].name || "",
            value: props.fundingTypeList && props.fundingTypeList[key].code || ""
          }))
        setUpdatedFundingTypeList(filteredFundingTypes);
      }
      else {
        const filteredFundingTypes = Object.keys(props.fundingTypeList)
          .filter(key => props.fundingTypeList && props.fundingTypeList[key].hcp_allowed === false)
          .map(key => ({
            label: props.fundingTypeList && props.fundingTypeList[key].name || "",
            value: props.fundingTypeList && props.fundingTypeList[key].code || ""
          }))
        setUpdatedFundingTypeList(filteredFundingTypes);
      } 
    }
    return "";
  }
  const validatePostcode = async (postcode, formData) => {
    if (overridePostcode || overrideAvailability) {
      return;
    }

    const result = await getPostcode(postcode, true);
    // @ts-ignore
    if (!result.isSuccess && result.error && result.error.status === 404) {
      return "notfound";
    }

    if (!props.providerId || !props.serviceAreasList) {
      return;
    }
    const serviceArea = props.serviceAreasList.find(sa => sa.id === formData.service_area);
    const availability = await getAvailability({provider: props.providerId, service_areas_list: serviceArea && serviceArea.name, postcodes_list: formData.postcode});
    if (availability.isSuccess && availability.data.length === 0) {
      return "unavailable";
    }
  }
  const submitForm = async (data: FormValues) => {
    const serviceAreaSelected = props.serviceAreasList && props.serviceAreasList.find(sa => sa.id === parseInt(data.service_area));
    try {
      await props.onSubmit({...data, postcode: overridePostcode ? null : data.postcode, service_area: parseInt(data.service_area),
        funding_types_list: data.funding_types_list, age_groups_list: data.age_groups_list, hcp_referral: serviceAreaSelected && serviceAreaSelected.name==="Home Care Package Provider" ? true : false});
      reset({}, {keepValues: true});
    }
    catch {
      setError('root', {message: 'Unexpected error'});
    }
  }

  const postcodeInput = (inputProps) => (
      <Input
        className={styles.halfWidthDesktop}
        ref={postcodeInputRef}
        input={{placeholder: "Type here", ...inputProps}}
        meta={{submitFailed: !!errors.postcode && errors.postcode.type !== 'validate', error: "Please enter a valid postcode"}}
      />
  );

  const renderUnAvailablePostcodeField = (inputProps) => {
    if (!props.postcode) {
      return (
          <PostcodeSearchBox
            inputComponent={postcodeInput}
            inputProps={inputProps.field}
            onSuggestionSelected={(e, { suggestionValue }) => setValue('postcode', suggestionValue)}
            placeholder="Type"
            hasRightMargin={false}
            maxAutoSuggestionResults={8}
          />
      );
    }
    return null;
  };

  const renderAvailablePostcodeField = (inputProps) => (
      <PostcodeSearchBox
        inputComponent={postcodeInput}
        inputProps={inputProps.field}
        onSuggestionSelected={(e, { suggestionValue }) => setValue('postcode', suggestionValue)}
        placeholder="Type"
        hasRightMargin={false}
        maxAutoSuggestionResults={8}
      />
  );

  const handleButtonClick = () => {
    window.location.href = '/';
  };

  return (
    <CenteredWithGutters>
      <form onSubmit={handleSubmit(submitForm)}>
        <FormItem>
          <StyledLabel>Your name</StyledLabel>
          <Input
            input={{placeholder:" Type here", type: "text", ...register("name", {required: true})}}
            meta={{submitFailed: !!errors.name, error: "Your name is required"}}
          />
        </FormItem>
        <FormItem>
          <StyledLabel>Email</StyledLabel>
          <Input
            input={{placeholder: "Type here", type: "email", ...register("email", {required: true, validate: (val) => isEmail(val)? undefined : "Invalid URL"})}}
            meta={{submitFailed: !!errors.email, error: "Please enter a valid email"}}
          />
        </FormItem>
        <FormItem>
          <StyledLabel>Phone number</StyledLabel>
          <Input className={styles.halfWidthDesktop}
            // @ts-ignore
            input={{placeholder: "Type here", type: "tel", ...register("phone", {required: true, validate: (val) => isValidPhoneNumber(val, "AU")})}}
            meta={{submitFailed: !!errors.phone, error: "Please enter a valid phone number"}}
          />
        </FormItem>
        {!props.selectedServiceArea && (
          <FormItem>
            {props.serviceAreasList && props.serviceAreasList.length > 0 && (
              <div>
                <StyledLabel>What service do you require?</StyledLabel>
                <Select
                    fieldOptions={props.serviceAreasList.map(serviceArea => ({
                      label: serviceArea.name,
                      value: serviceArea.id
                    }))}
                    input={{placeholder: "Select from list", type: "text", ...register("service_area", {required: true, onChange: (event) => setFundingTypeBasedOnServiceArea(event.target.value)})}}
                    meta={{submitFailed: !!errors.service_area, error: "Please select a service"}}
                />
              </div>
            )}
          </FormItem>
        )}
         {!props.fundingType && (
          <FormItem>
            {props.fundingTypeList && (
              <div>
                <StyledLabel>What funding type do you have?</StyledLabel>
                <Select
                fieldOptions={concat(
                    updatedFundingTypeList.length > 0 ? updatedFundingTypeList : 
                    Object.keys(props.fundingTypeList)
                    .filter(key => props.fundingTypeList && props.fundingTypeList[key].hcp_allowed === false)
                    .map(key => ({
                      label: props.fundingTypeList && props.fundingTypeList[key].name || "",
                      value: props.fundingTypeList && props.fundingTypeList[key].code || ""
                    })),
                    { label: "I don't know", value: 'i-don-t-know' }
                  )}
                  input={{placeholder: "Select from list", type: "text", ...register("funding_types_list", {required: true})}}
                  meta={{submitFailed: !!errors.funding_types_list, error: "Please select a funding type"}}
                />
              </div>
            )}
          </FormItem>
        )}
        {!props.ageGroup && (
          <FormItem>
            {props.ageGroupList && (
              <div>
                <StyledLabel>What is the age group of the recipient?</StyledLabel>
                <Select
                    fieldOptions={Object.keys(props.ageGroupList).map(key => ({
                      label: props.ageGroupList && props.ageGroupList[key].description,
                      value: props.ageGroupList && props.ageGroupList[key].code
                    }))}
                    input={{placeholder: "Select from list", type: "text", ...register("age_groups_list", {required: true})}}
                    meta={{submitFailed: !!errors.age_groups_list, error: "Please select a age group"}}
                />
              </div>
            )}
          </FormItem>
        )}
        <FormItem>
          {!props.postcode && (
            <StyledLabel>Postcode</StyledLabel>
          )}
          {!!errors.postcode && errors.postcode.type === "validate" && errors.postcode.message === "notfound" &&
              <div>
                <ValidationError meta={{submitFailed: true, error: "Sorry, we can't find the provided postcode."}} />
                <ValidationError meta={{submitFailed: true, error: "If you think this is an error, you can"}}>
                  &nbsp;<LinkButton type="button" onClick={(e) => setOverridePostcode(true)}>submit anyway.</LinkButton>
                </ValidationError>
              </div>
          }
          {!!errors.postcode && errors.postcode.type === "validate" && errors.postcode.message === "unavailable" &&
              <div>
                <ValidationError meta={{submitFailed: true, error: "Sorry, this provider doesn't have availability in your area."}} />
                <ValidationError meta={{submitFailed: true, error: "If you'd like us to find you an alternative provider, you can"}}>
                  &nbsp;<LinkButton type="button" onClick={(e) => setOverrideAvailability(true)}>submit anyway.</LinkButton>
                </ValidationError>
              </div>
          }
          {props.postcode && !!errors.postcode && ( errors.postcode.type === "minLength" || errors.postcode.type === "maxLength" ) &&
              <div>
              <ValidationError meta={{submitFailed: true, error: "The provided postcode is not valid "}} />
              <ValidationError meta={{submitFailed: true, error: "If you think this is an error, you can"}}>
                &nbsp;<LinkButton type="button" onClick={(e) => setOverridePostcode(true)}>submit anyway.</LinkButton>
              </ValidationError>
              <ValidationError meta={{submitFailed: true, error: "or you can"}}>
                &nbsp;<a href="/" onClick={handleButtonClick}>modify search.</a>
              </ValidationError>
            </div>
          }
          {props.postcode ? renderUnAvailablePostcodeField({ field: register("postcode", { required: true, minLength: 4, maxLength: 4, validate: validatePostcode }) }) : null}
          {!props.postcode && (
            <Controller
              render={renderAvailablePostcodeField}
              control={control}
              name="postcode"
              rules={{ required: true, minLength: 4, maxLength: 4, validate: validatePostcode }}
            />
          )}
        </FormItem>
        <FormItem>
          <ValidationError />
          <RenderCheckbox meta={{submitFailed: !!errors.terms, error: 'Please agree to the terms and conditions before proceeding'}} input={register("terms", {required: true})} />
        </FormItem>
        <div>
          <CenteredWithGutters>
            <BtnContainer>
              <Button disabled={formState.isSubmitting || formState.isSubmitSuccessful} fullWidth type="submit">
                Next
              </Button>
            </BtnContainer>
          </CenteredWithGutters>
          <CenteredWithGutters>
            <BtnContainer>
              <BottomLink
                to={
                  props.providerId
                    ? `/providers/${props.providerSlug}/${props.providerId}/`
                    : '/providers/'
                }
              >
                Cancel or change selections
              </BottomLink>
            </BtnContainer>
          </CenteredWithGutters>
        </div>
        {errors.root && (
          <CenteredWithGutters>
              <ValidationError meta={{submitFailed: true, error: "Sorry, there has been a system error."}} >
                &nbsp;If this error persists, please <WhiteAnchor href="mailto:info@karista.com.au?Subject=I can't submit my request">contact us</WhiteAnchor>.
              </ValidationError>
          </CenteredWithGutters>
        )}
      </form>
    </CenteredWithGutters>
  );
}

export default RequestAQuoteForm;
