/* eslint import/prefer-default-export: 0 */
import styled from 'styled-components';
import calculateRem from '../../styles/calculateRem';

export const TabContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  align-self: flex-end;
  font-size: ${calculateRem(15)};

  a {
    text-decoration: none;
  }
`;
