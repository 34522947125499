/* global window */
import ReactGA4 from 'react-ga4';

const GA4_TAGID = process.env.REACT_APP_GA4_TAGID || null;

if (GA4_TAGID) {
  ReactGA4.initialize(GA4_TAGID);
}

export const eventCategories = {
  ERRORS: 'Errors',
  REQUESTS: 'Inquiries',
  ADMIN: 'Provider Portal'
};
