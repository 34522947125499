import React from 'react';
import { Route, RouteProps } from 'react-router-dom';

import { AuthSlice } from '../../state/types';

// TODO: This could be moved somewhere more global.
export const LOGIN_URL = '/admin/login';

interface BaseComposedRouteProps extends Omit<RouteProps, 'component'> {
  requiresAuth?: boolean,
  auth?: AuthSlice,
  history: any,  // TODO: Find the correct type for this.
  component: React.ElementType,
};

type ComposedRouteProps<T extends {}> = BaseComposedRouteProps & T;

//
// Wrap a standard `Route` with a `requiresAuth` prop. When set to
// true, this will redirect to a login page if the user is not already
// logged in.
//
const ComposedRoute = <T extends {}>(props: ComposedRouteProps<T>) => {
  const {
    component: Component,
    location,
    render,
    path,
    exact,
    sensitive,
    strict,
    requiresAuth = false,
    ...restProps
  } = props;
  return (
    <Route
      location={location}
      path={path}
      exact={exact}
      sensitive={sensitive}
      strict={strict}
      render={renderProps => {
        // This check and subsequent jump to the login page must occur
        // within the render function. If not, the check and jump may
        // happen many times; once for each `ComposedRoute` on the
        // current page.
        const auth = (restProps.auth ? restProps.auth : {}) as AuthSlice;
        if (requiresAuth && !auth.isAuthenticated) {
          restProps.history.push({
            pathname: LOGIN_URL,
          });
          return null;
        }
        return <Component requiresAuth={requiresAuth} {...restProps} {...renderProps} />;
      }}
    />
  );
}

export default ComposedRoute;
