/* eslint-disable */
import React from 'react';
import styled from 'styled-components';
import Helmet from 'react-helmet';

import RequestAQuoteForm from '../../../components/RequestAQuoteForm';
import Hero from '../../../components/Hero';
import { BetaHeading } from '../../../components/Typography';
import Section from '../../../components/Section';
import { PageNotFoundInner } from '../../Static/PageNotFound';
import calculateRem from '../../../styles/calculateRem';
import { resolveProviderServiceAreas } from '../../../util/transforms';
import { RouteComponentProps } from 'react-router';
import { Provider, RequestQuoteResult, Service, ServiceArea } from '../../../state/types';
import {trackQuoteSubmission} from "../tracking";
import { useAppSelector } from '../../../state/hooks';
import { useCreateRequestForQuoteFormMutation, useGetProviderDetailsQuery, useGetProviderDetailsUsingSlugQuery } from '../../../services/karista';
import { OrangeLink } from '../../../components/Link';
import { useGetAllAgeGroupsQuery, useGetAllFundingTypesQuery } from '../../../services/karista';
import { reduceQueries } from '../../../util/rtk-query';

const StyledSection = styled(Section)`
  padding-top: ${calculateRem(48)};
`;

export interface RequestQuoteProps extends RouteComponentProps<{id?: string, slug?: string}> {
  services: Service[]
  serviceAreas: ServiceArea[]
  requestQuoteResult: RequestQuoteResult
};

interface InnerRequestQuoteProps extends Omit<RequestQuoteProps, 'services'> {
  selectedProvider: Provider & {serviceAreas: ServiceArea[]},
  isProviderError: boolean,
  isLoading: boolean,
};

const useRequestQuoteProps = (props: RequestQuoteProps): InnerRequestQuoteProps => {
  const {
    match: {
      params: { id: providerId, slug: providerSlug },
    },
    services,
    serviceAreas,
  } = props;

  // const {data: rawProvider, isLoading, isError} = useGetProviderDetailsQuery(parseInt(providerId as string));
  const {data: rawProvider, isLoading, isError} = useGetProviderDetailsUsingSlugQuery(providerSlug as string);

  const selectedProvider = React.useMemo(
    () => resolveProviderServiceAreas(services, serviceAreas, rawProvider),
    [rawProvider, services],
  );
  return {
    ...props,
    selectedProvider: selectedProvider as (Provider & {serviceAreas: ServiceArea[]}),
    isProviderError: isError,
    isLoading: !services || isLoading,
  };
}

const RequestQuote: React.FC<RequestQuoteProps> = props => {
  const {
    selectedProvider,
    isProviderError,
    isLoading,
  } = useRequestQuoteProps(props);

  const filters = useAppSelector(state => state.providers.providerSearchParams.filters);

  const { serviceAreas, id, ...providerRest } = selectedProvider;

  const [createRequestForQuote] = useCreateRequestForQuoteFormMutation();

  const useInitialize = () => reduceQueries({
    ageGroups: useGetAllAgeGroupsQuery(),
    fundingTypes: useGetAllFundingTypesQuery()
  });
  
  const { ageGroups, fundingTypes } = useInitialize();
  const ageGroupsData = ageGroups.data;
  const fundingTypesData = fundingTypes.data

  const handleSubmit = React.useCallback((formData, cb?) => {
    const submitData = Object.assign(
      {},
      { providers: [selectedProvider.id] },
      { ...formData, ...filters }
    );
    return createRequestForQuote(submitData)
      .unwrap()
      .then(async (res) => {
        const service = serviceAreas.find(area => area.id === parseInt(formData.service_area));
        await trackQuoteSubmission(service ? service.name : undefined);
        return res;
      })
      .then((res) => {
        const fundingType = fundingTypesData && fundingTypesData[formData.funding_types_list]
        const fundingTypeCheck = fundingType ? fundingType.code : undefined
        if (fundingTypeCheck && ["HCP", "HCP1", "HCP2", "HCP3", "HCP4"].includes(fundingTypeCheck)) {
          props.history.push(`/providers/quote-detail/additional-questions/${res.id}`);
        }
        else {
          props.history.push(`/providers/quote-detail/${res.id}`);
        }
      });
  }, [selectedProvider]);

  if (isProviderError) {
    return <PageNotFoundInner />;
  }

  if (!serviceAreas || isLoading || !ageGroups || !fundingTypes) {
    return <div>Loading ...</div>;
  }

  const selectedServiceArea = serviceAreas.find(
    serviceArea =>
      serviceArea.name == filters['service_areas_list']
  );

  const selectedAgeGroup = filters.age_groups_list && ageGroupsData && ageGroupsData[filters.age_groups_list];
  const selectedFundingType = filters.funding_types_list && fundingTypesData && fundingTypesData[filters.funding_types_list];

  return (
    <div>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      {providerRest.name &&
        !!providerRest.name && (
          <div>
             {filters.service_areas_list && filters.postcodes_list && filters.age_groups_list && filters.funding_types_list ? 
             ( <Hero>
                  <BetaHeading>Connect with the best match</BetaHeading>
                    <p>You have selected <b>{providerRest.name}</b> for <b>{filters.service_areas_list}</b> in <b>{filters.postcodes_list}</b> for {" "}
                    <b>{selectedAgeGroup && selectedAgeGroup.description}</b> under <b>{selectedFundingType && selectedFundingType.name}</b>.
                    {" "}
                    <OrangeLink to="/">
                      Modify search
                    </OrangeLink> 
                    <br></br>
                  </p>
                </Hero>
            ) : (
              <Hero>
                <BetaHeading>Connect with the best match</BetaHeading>
                {providerRest.name}
              </Hero>
            )}
            <StyledSection>
            <p>
            Please complete the form below so we can ensure you find the <b>best match</b>.
              This information is completely <b>confidential</b> and will not be released to the service provider without your approval.
            </p>
                <RequestAQuoteForm
                selectedServiceArea={selectedServiceArea && selectedServiceArea.id}
                serviceAreasList={serviceAreas}
                serviceAreaCheck={filters.service_areas_list}
                providerId={id}
                onSubmit={handleSubmit}
                postcode={filters.postcodes_list}
                fundingType={filters.funding_types_list}
                ageGroup={filters.age_groups_list}
                fundingTypeList={fundingTypesData}
                ageGroupList={ageGroupsData}
                providerSlug={providerRest.url_slug}
              />
            </StyledSection>
          </div>
        )}
    </div>
  );
};

export default RequestQuote;
