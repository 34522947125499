import React from 'react';

import Footer from '../../components/Footer';
import Navigation from '../../components/Navigation';
import { OrangeLink } from '../../components/Link';
import Page from '../../components/Page';
import Section from '../../components/Section';
import Theme from '../../components/Theme';
import { themeSecondary } from '../../styles/constants';
import Socials from './components/Socials';

import { EnquireFormParagraph, EnquireFormHeading } from './style';

const FormRedirect = () => (
  <Theme theme={themeSecondary}>
    <Navigation>
      <OrangeLink border to="/">
        Back to search
      </OrangeLink>
    </Navigation>
    <Page>
      <Section>
        <EnquireFormHeading>
          Thank you!
          <b> Your enquiry has been received</b>
        </EnquireFormHeading>
        <br />
        <EnquireFormParagraph>
          A member of our Client Services team will contact you to discuss your
          options and our pricing.
        </EnquireFormParagraph>
      </Section>
      <Socials />
    </Page>
    <Footer />
  </Theme>
);

export default FormRedirect;
