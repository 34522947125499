import React from 'react';
import Theme from '../../../../../components/Theme';
import { themeSecondary } from '../../../../../styles/constants';
import { ListItem, List } from '../../../style';

import {
    NDISOtherText,
    NDISFAQSection,
    FAQInfoText,
    SupportCategorySection,
    NDISFAQParagraph
} from "../../../style"

const NDISFAQs = () => {

  return (
    <>
    <Theme theme={themeSecondary}>
        <SupportCategorySection>
            <NDISOtherText style={{ paddingBottom: '16px' }}>
                <b>FAQs</b>
            </NDISOtherText>
        </SupportCategorySection>
        <NDISFAQSection>
            <FAQInfoText>Am I eligible for NDIS funding?</FAQInfoText>
            <NDISFAQParagraph>If you're an Australian resident living with a disability, you may qualify for funding through the National Disability Insurance Scheme (NDIS). 
                To be eligible, you need to be an Australian citizen or permanent resident between the ages of seven and 64, and have a significant permanent 
                disability that affects various aspects of your life. </NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>Is the NDIS means tested?</FAQInfoText>
            <NDISFAQParagraph>No, your assets are not considered when determining eligibility for NDIS funding. Also, receiving NDIS support doesn’t affect other forms of income
                 support like the Disability Support Pension or Carer Allowance. </NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>How will my funding be managed?</FAQInfoText>
            <p>You have three options for managing your NDIS funding: </p>
            <List>
                <ListItem><b>Agency managed:</b> The NDIA handles your plan.</ListItem>
                <ListItem><b>Plan managed:</b> You use a Plan Manager.</ListItem>
                <ListItem><b>Self-managed:</b> You handle your plan on your own.</ListItem>
            </List>
            <NDISFAQParagraph>Karista can connect you to Plan Managers. <a href="/services/plan-management">Search now</a></NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>Can I change providers if I’m not happy?</FAQInfoText>
            <NDISFAQParagraph>Yes, you can switch providers if you're not satisfied. Different providers have different notice periods, so be sure to check your service agreement. 
                Inform your current provider of your decision, and they'll guide you through the process. If you've already chosen a new provider, get in touch with them for assistance.</NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>Can you lose NDIS funding?</FAQInfoText>
            <NDISFAQParagraph>Once your current NDIS plan ends, your funding stops. Any remaining funds don’t carry over to your new plan. During each plan review, 
                you'll receive a new plan outlining supports and funds for the next period. </NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>What doesn't the NDIS cover?</FAQInfoText>
            <NDISFAQParagraph>NDIS funding doesn’t cover typical daily expenses like train fares, rent, groceries, phone bills, or entertainment tickets. </NDISFAQParagraph>
        </NDISFAQSection>
        <NDISFAQSection>
            <FAQInfoText>What does reasonable and necessary mean?</FAQInfoText>
            <p>For a support to be considered reasonable and necessary, it must: </p>
            <List>
                <ListItem>Be related to your disability. </ListItem>
                <ListItem>Offer value for money. </ListItem>
                <ListItem>Have the potential to be effective and beneficial. </ListItem>
                <ListItem>Take into account any informal support from family, friends, or the community. Supports shouldn’t cover general living costs unrelated to your disability needs.</ListItem>
            </List>
        </NDISFAQSection>
    </Theme>
    </>
  );
}

export default NDISFAQs;