import styled from "styled-components";
import {colors} from "../../../../styles/constants";

const cardStackBreakpoint = '720px';

export const SubLabel = styled.text`
  color: ${colors.black};
  font-size: 16px;
`;

export const PrivacyLabel = styled.p`
  font-size: 12px;
  line-height: 24px;
  color: ${colors.black};

  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;

export const SubmitSection = styled.div`
  display: flex;
  flex-direction: row;
  text-align: left;
  
  @media (max-width: ${cardStackBreakpoint}) {
    flex-direction: column;
  }
`;