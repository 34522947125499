import React from 'react';
import Theme from '../../../../components/Theme';
import { themeLightGrey } from '../../../../styles/constants';
import IconKaristaSocial from "./icon-karista-socials.svg"
import IconFacebook from "./icon-facebook.svg"
import IconInstagram from "./icon-instagram.svg"
import IconLinkedin from "./icon-linkedin.svg"
import Breakpoint from '../../../../components/Breakpoint';

import {
  ProviderEnquirySuccessIcon,
  SuccessHeaderSection,
  SocialsSection,
  SocialIcon,
  SocialStatement
} from "../../style"

const facebookLink = "https://www.facebook.com/Karista-413403892326876/";
const instagramLink = "https://www.instagram.com/karistacare/";
const linkedinLink = "https://www.linkedin.com/company/18157963/";

const SocialContent = () => (
  <>
      <SocialStatement>In the meantime, checkout out our socials!</SocialStatement>
      <SocialsSection>
        <SocialIcon>
          <a href={facebookLink} target="_blank" rel="noopener noreferrer">
              <img src={IconFacebook} alt={"Karista Facebook"} />
            </a>
        </SocialIcon>
        <SocialIcon>
          <a href={instagramLink} target="_blank" rel="noopener noreferrer">
              <img src={IconInstagram} alt={"Karista Instagram"} />
            </a>
        </SocialIcon>
        <SocialIcon>
          <a href={linkedinLink} target="_blank" rel="noopener noreferrer">
              <img src={IconLinkedin} alt={"Karista Linkedin"} />
            </a>
        </SocialIcon>
      </SocialsSection>
  </>
);

const Socials = () => {

  const SocialDesktop = () => {
      return (
        <SuccessHeaderSection>
          <ProviderEnquirySuccessIcon>
              <img src={IconKaristaSocial} alt={"Karista provider enquiry success"} />
          </ProviderEnquirySuccessIcon>
          <div>
            <SocialContent />
          </div>
        </SuccessHeaderSection>
      );
  }
  const SocialMobile = () => {
      return (
        <SuccessHeaderSection>
          <SocialContent />
          <ProviderEnquirySuccessIcon>
              <img src={IconKaristaSocial} alt={"Karista provider enquiry success"} />
          </ProviderEnquirySuccessIcon>
        </SuccessHeaderSection>
      );
  }
  return (
    <Theme theme={themeLightGrey}>
       <Breakpoint>
            {breakpoint => {
                if (breakpoint === 'desktop' || breakpoint === 'tablet') return SocialDesktop();
                return SocialMobile();
            }}
        </Breakpoint>
    </Theme>
  );
}

export default Socials;
