import React from 'react';
import { RouteComponentProps } from 'react-router';

import LoginForm from '../components/LoginForm';
import { useGetAuthStateQuery, useLoginMutation } from '../../../services/karista';

export interface LoginProps extends RouteComponentProps {
};

const Login: React.FC<LoginProps> = props => {
  const { history } = props;
  const [login, { error }] = useLoginMutation();
  const { data: auth, isLoading } = useGetAuthStateQuery();

  console.log("YO: ", auth);

  React.useEffect(() => {
    if (auth && auth.authenticated) {
      history.push({
        pathname: `/admin/providers/welcome`
      });
    }
  }, [isLoading, auth]);

  function handleSubmit(credentials: any) {
    login({ auth: credentials })
      .unwrap()
      .then(() => {
        history.push({
          pathname: `/admin/providers/welcome`
        });
      })
      .catch(() => ({}));
  }

  if (isLoading || !auth || auth.authenticated) {
    return null;
  }
  return <LoginForm onSubmit={handleSubmit} isUserLogin={false} authError={error} />;
}

export default Login;
