import styled from "styled-components";
import Section from "../../../../components/Section";
import { AlphaHeading } from "../../../../components/Typography";
import {colors} from "../../../../styles/constants";
import mediaQueries from "../../../../styles/mediaQueries";
import DataProtection from "./icon-data-protect-guarantee.svg"

const cardStackBreakpoint = '720px';

export const InfoText = styled.span`
  line-height: 26px;
`;

export const BlackInfoText = styled(InfoText)`
  color: ${colors.black};
  display: block;
  font-size: 90%;
`;

export const HeaderSection = styled(Section)`
  text-align: center;
  ${mediaQueries.mobile`
    width: auto;
  `};
`;

export const HeaderHeading = styled(AlphaHeading)`
  margin-bottom: 12px;
`;

export const HeaderText = styled(BlackInfoText)`
  ${mediaQueries.mobile`
    padding: 0;
  `};
`;

export const ConfidentialitySection = styled(Section)`
  padding-top: 36px;
  padding-bottom: 56px;
  display: flex;
  @media (max-width: ${cardStackBreakpoint}) {    
    display: block;
    text-align: center;
  }
`;

export const DataProtectionIcon = styled.img.attrs({src: DataProtection})`
  padding-right: 16px;
  width: 120px;

  @media (max-width: ${cardStackBreakpoint}) {
    padding-right: 0;
    padding-bottom: 16px
  }
`;
