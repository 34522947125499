import React from 'react';

// Wraps children with additional props

const WrappedChildren = (children, props) => {
  const withHandler = React.Children.map(children, child =>
    React.cloneElement(child, props)
  );
  return <div>{withHandler[0]}</div>;
};

export default WrappedChildren;
