/* eslint-disable */
import React, {useEffect} from 'react';
import styled from 'styled-components';
import { isURL } from 'validator';
import FaEye from 'react-icons/lib/fa/eye';
import UploadSvg from './cloud-upload-svgrepo-com.svg'
import PlaceholderSvg from './Placeholder_view_vector.svg'
import MultiSelectList from '../MultiSelectList';
import Link, {PurpleExternalLink} from '../Link';
import { colors } from '../../styles/constants';
import calculateRem from '../../styles/calculateRem';
import Label from '../Label';
import Button from '../Button';
import { uniq } from 'lodash';
import SuccessMessage from '../SuccessMessage';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import "./style.css"
import {CenteredWithGutters} from "../Layout";

import {
  BtnContainer,
  InputContainer,
  SplitColumnHalfContainer
} from './styles';

import FormItem from '../FormItem';

import { AgeGroup, FundingType, Provider, Service } from '../../state/types';
import {Controller, useForm} from "react-hook-form";
import ValidationError from "../ValidationError";
import AccessibleReactSelect from "../AccessibleReactSelect";
import ImageUploadModal from "../ImageUploadModal";
import {emailPattern, phonePattern, urlPattern} from "../../util/patterns";

const StyledEditor = styled(ReactQuill)`
  position: relative;
  width: 100%;
  margin-top: ${calculateRem(5)};
  padding: ${calculateRem(12)} ${calculateRem(3)};
  vertical-align: top;
  p {
    padding: 0;
    margin: 0;
  }
`;
const quillConfig = {
  modules: {
    toolbar: [
      ['bold', 'italic', 'underline'],
      [{ list: 'bullet' }, { list: 'ordered' }]
    ]
  },
  formats: ['bold', 'italic', 'underline', 'list', 'bullet']
};

// TODO: I'm reluctant to formalize this as a named type just now. I
// think we're better off separating API types from transformed types
// first.

interface ProviderDetailsFormProps extends Provider {
  serviceOptions: Service[],
  ageGroupOptions: {[k: string]: AgeGroup},
  fundingTypeOptions: {[k: string]: FundingType},
  onSubmit: (...args: any[]) => any,
  onSubmitLogo: (file: File) => Promise<any>,
  stateName?: string,
};

const ProviderDetailsForm = (props: ProviderDetailsFormProps) => {
  const { register, formState, handleSubmit, setValue, watch, reset, control, setError } = useForm({
    defaultValues: {
      name: props.name,
      short_description: props.short_description,
      description: props.description,
      url: props.url,
      age_groups: props.age_groups,
      funding_types: props.funding_types,
      services: props.services,
    },
    mode: "all"
  });
  useEffect(() => {
    register("description");
    register("age_groups");
    register("funding_types");
    register("services");
  }, [register]);

  const description_watch = watch("description");
  const age_groups_watch = watch("age_groups");
  const funding_types_watch = watch("funding_types");

  const [isLogoModalOpen, setIsLogoModalOpen] = React.useState<boolean>(false);
  const [logoUploadStatus, setLogoUploadStatus] = React.useState<'' | 'success' | 'failure'>('');

  const ageGroupSelectItems = Object.entries(props.ageGroupOptions).map(([code, ag]) => ({
    label: ag.description,
    value: ag.code,
    isHeader: false,
    active: age_groups_watch.includes(ag.code)
  }));

  // Prepare data for display in multi-select component
  // `active` will be used as the filterKey
  const fundingTypeSelectItems = Object.entries(props.fundingTypeOptions).map(([code, ft]) => ({
    label: ft.name,
    value: ft.code,
    isHeader: false,
    active: funding_types_watch.includes(ft.code)
  }));

  const submitForm = (data) => {
    return props
      .onSubmit({id: props.id, ...data})
      .then(() => {reset({}, {keepValues: true})})
      // 'root' errors are special in that they are cleared by the library
      // upon each submission attempt. By setting a root error, you also
      // cause formState.isSubmitSuccessful to be false, even though this
      // promise is actually fulfilled because of the catch.
      .catch(() => {setError('root', {message: 'Unexpected error'});})
  }
  let onSubmitLogo = (file) => {
    return props.onSubmitLogo(file)
        .then(() => {
          setLogoUploadStatus('success');
        })
        .catch((error) => {
          setLogoUploadStatus('failure');
        })
        .finally(() => {
          setIsLogoModalOpen(false);
        });
  }

  const handleToggle = (item, stateName) => {
    const nameToList = {
      age_groups: age_groups_watch,
      funding_types: funding_types_watch,
    }
    const valueList = nameToList[stateName];
    if (valueList.includes(item.value)) {
      setValue(stateName, valueList.filter(val => val !== item.value), {shouldDirty: true, shouldValidate: true});
    }
    else {
      setValue(stateName, [item.value, ...valueList], {shouldDirty: true, shouldValidate: true});
    }
  }

  const stripHtmlTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    return doc.body.textContent || "";
  };

  const [validateLongDescription, setValidateLongDescription] = React.useState(true);
  const [validateLongDescriptionContent, setValidateLongDescriptionContent] = React.useState(true);

  const onLongDescriptionChange = (editorState) => {
    const longDescription = stripHtmlTags(editorState).trim()

    setValue("description", editorState, {shouldDirty: true, shouldValidate: true});
    if (!longDescription && longDescription.length === 0) {
      setValidateLongDescription(false)
    } else {
      setValidateLongDescription(true)
    }
    
    const hasEmail = emailPattern.test(editorState);
    const hasPhone = phonePattern.test(editorState);
    const hasUrl = urlPattern.test(editorState);

    if (hasEmail || hasPhone || hasUrl) {
      setValidateLongDescriptionContent(false)
    } else {
      setValidateLongDescriptionContent(true)
    }
  };

  const serviceOptions = props.serviceOptions.map((service) => ({value: service.id, label: service.name}));
  const {isSubmitting, isSubmitted, isSubmitSuccessful, isDirty, errors} = formState;
  return (
    <div>
      <CenteredWithGutters>
        <p>
          All of the information you enter or edit below will be visible on
          your public provider profile
        </p>
        <br/>
        <form className="full-width" onSubmit={handleSubmit(submitForm)}>
          <FormItem>
            <Label htmlFor="name" emphasis={true}>
              Provider name
            </Label>
            <InputContainer>
              <input
                className="text-input"
                placeholder="Provider name..."
                type="text"
                {...register("name", {required: true})}
              />
            </InputContainer>
            <ValidationError meta={errors.name? {submitFailed: true, error: "This field is required"} : {submitFailed: false, error: ""}}/>
          </FormItem>
          <FormItem>
            <Label htmlFor="logo" emphasis={true}>
              Logo
            </Label>
            <div>
              <div id="logo_container" className="logo-container" onClick={() => setIsLogoModalOpen(true)}>
                <img src={props.logo_url || PlaceholderSvg} className="logo-preview" />
                <img src={UploadSvg} className="logo-upload-icon" />
              </div>
            </div>
            {isLogoModalOpen &&
                <ImageUploadModal
                    fileInputName="logo"
                    onSubmit={onSubmitLogo}
                    onClose={() => setIsLogoModalOpen(false)}
                    position="top center"
                    headingText="Logo Upload"
                >
                  <p className="centered black">
                    Please note: for best results, use square
                    <br />
                    (equal width and height) images
                  </p>
                </ImageUploadModal>
            }
            {logoUploadStatus === 'success' && <SuccessMessage message="Logo updated successfully" />}
            {logoUploadStatus === 'failure' && <ValidationError meta={{submitFailed: true, error: 'Logo upload failed: unexpected error'}}/> }
          </FormItem>
          <FormItem>
            <Label htmlFor="short_description" emphasis={true}>
              Short description
            </Label>
            <InputContainer>
              <textarea
                className="text-input"
                placeholder="Type here"
                rows={2}
                {...register("short_description",
                {validate: short_description => {
                    if (!short_description || short_description.trim().length === 0) {
                      return "Please provide a short description. The short description will be displayed in search results.";
                    } else if (short_description.length > 200) {
                      return "Must be 200 characters or less";
                    }
                }})}
              ></textarea>
            </InputContainer>
            <ValidationError meta={errors.short_description? {submitFailed: true, error: errors.short_description.message || ""} : {submitFailed: false, error: ""}}/>
          </FormItem>
          <FormItem>
            <Label htmlFor="description" emphasis={true}>
              Long description
            </Label>
            <StyledEditor
              modules={quillConfig.modules}
              formats={quillConfig.formats}
              value={description_watch}
              onChange={onLongDescriptionChange}
            />
            {!validateLongDescription && <ValidationError meta={{submitFailed: true, error: 'Please provide a long description. The description will be displayed on your profile page.'}}/> }
            {!validateLongDescriptionContent && <ValidationError meta={{submitFailed: true, error: "Description cannot contain email addresses, phone numbers, or URLs."}}/> }
          </FormItem>
          <FormItem>
            <Label htmlFor="url" emphasis={true}>
              Website URL
            </Label>
            <InputContainer>
              <input
                className="text-input"
                style={{ marginBottom: '5px' }}
                placeholder="http://"
                type="text"
                {...register("url", {validate: val => isURL(val)? undefined : "Invalid URL"})}
              />
            </InputContainer>
            <ValidationError meta={errors.url? {submitFailed: true, error: "Invalid URL"} : {submitFailed: false, error: ""}}/>
          </FormItem>
          {props.is_using_advanced_availability && (
              <b>
                Your profile is set up using Karista's advanced availability system.
                To update your services, age groups, funding types and regions, please&nbsp;
                <PurpleExternalLink href="mailto:info@karista.com.au?Subject=I want to update my availability">contact us</PurpleExternalLink>.
              </b>
          )}
          { !props.is_using_advanced_availability && (
              <div>
                <SplitColumnHalfContainer>
                  <FormItem>
                    <Label htmlFor="ageGroups" emphasis={true}>
                      Age groups you service:
                    </Label>
                    <MultiSelectList
                      name="ageGroups"
                      listItems={uniq(ageGroupSelectItems)}
                      listItemIcon={<input type="checkbox" />}
                      handleClick={handleToggle}
                      stateName="age_groups"
                    />
                  </FormItem>
                  <FormItem>
                    <Label htmlFor="fundingTypes" emphasis={true}>
                      Funding types you accept:
                    </Label>
                    <MultiSelectList
                      name="fundingTypes"
                      listItems={uniq(fundingTypeSelectItems)}
                      listItemIcon={<input type="checkbox" />}
                      handleClick={handleToggle}
                      stateName="funding_types"
                    />
                  </FormItem>
                </SplitColumnHalfContainer>
                <FormItem>
                  <Label htmlFor="services" emphasis={true}>
                    Services you offer
                  </Label>
                  <Controller
                    control={control}
                    name="services"
                    render={({field}) => {
                      let onChangeServices = (newValue) => {
                        field.onChange(newValue.map(val => val.value))
                      };
                      return (
                          <AccessibleReactSelect
                              ref={field.ref}
                              name="services"
                              value={serviceOptions.filter(service => field.value.includes(service.value))}
                              options={serviceOptions}
                              isMulti
                              onChange={onChangeServices}
                              styles={{
                                multiValue: (baseStyles, state) => ({
                                  ...baseStyles,
                                  backgroundColor: colors.purple,
                                  borderRadius: "6px",
                                }),
                                multiValueLabel: (baseStyles, state) => ({
                                  ...baseStyles,
                                  color: colors.white,
                                  fontSize: calculateRem(18),
                                }),
                                multiValueRemove: (baseStyles, state) => ({
                                  ...baseStyles,
                                  color: colors.lightGrey,
                                }),
                                control: (baseStyles, state) => ({
                                  ...baseStyles,
                                  borderColor: colors.black,
                                }),
                              }}
                          />
                      );
                    }}
                  />
                </FormItem>
              </div>
          )}
          <CenteredWithGutters>
            <BtnContainer>
              <Button
                fullWidth
                type="submit"
                disabled={(isSubmitting || !isDirty) && logoUploadStatus !== 'success'}
              >
                {isSubmitting ? 'Saving changes' : 'Save changes'}
              </Button>
            </BtnContainer>
          </CenteredWithGutters>
          {!isSubmitting &&
            isSubmitted &&
            !isDirty &&
            isSubmitSuccessful && (
              <CenteredWithGutters>
                <div>
                  <SuccessMessage message={'Your changes have been saved'} />
                </div>
                <BtnContainer>
                  <Link
                    to={{
                      pathname: `/admin/providers/${props.url_slug}/${props.id}/preview`,
                      state: { previousUrl: `/admin/providers/${props.url_slug}/${props.id}/edit` }
                    }}
                  >
                    <FaEye style={{ padding: '5px' }} /> view your profile
                  </Link>
                </BtnContainer>
              </CenteredWithGutters>
            )}
          {formState.isDirty && formState.errors.root && (
              <CenteredWithGutters>
                <ValidationError meta={{submitFailed: true, error: 'Update failed: unexpected error'}}/>
              </CenteredWithGutters>
            )}
        </form>
      </CenteredWithGutters>
    </div>
  );
}

export default ProviderDetailsForm;
