import React from 'react';

import { getCampaign } from '../../../../util/local-storage';

interface CampaignProps {
  render: (...args: any[]) => React.ReactNode,
};

const Campaign: React.FC<CampaignProps> = ({ render }) => {
  return <div>{render(getCampaign())}</div>;
}

export default Campaign;
