import React from 'react';
import styled from 'styled-components';
import { Link } from 'react-router-dom';

import NavButtons, { Hamburger } from '../NavButtons';

import calculateRem from '../../styles/calculateRem';
import { LogoHorizontal, LogoResponsive } from '../Logo';
import { colors } from '../../styles/constants';
import { setNewCampaign } from '../../util/local-storage';

const Nav = styled.nav`
  position: fixed;
  top: 0;
  right: 0;
  left: 0;
  z-index: 10;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: ${calculateRem(60)};
  padding: 0 ${calculateRem(24)};
  background-color: ${colors.darkPurple};
`;

const HomeButton = styled(Link)`
  display: flex;
  align-items: center;
`;

const ActionsContainer = styled.article`
  margin-left: 24px;
  white-space: nowrap;
`;

const NavContainer = styled.section`
  display: flex;
  align-items: center;
`;

class Navigation extends React.Component {
  constructor(props) {
    super(props);
    this.toggleMenu = this.toggleMenu.bind(this);
    this.state = {
      isHidden: true
    };
  }

  toggleMenu() {
    const { isHidden } = this.state;
    this.setState({
      isHidden: !isHidden
    });
  }

  render() {
    const { children, isHome } = this.props;

    const handleHomeButtonClick = () => {
      setNewCampaign('');
    };
    return (
      <Nav>
        <HomeButton to="/" onClick={handleHomeButtonClick}>
          {isHome ? <LogoHorizontal /> : <LogoResponsive />}
        </HomeButton>
        <NavContainer>
          <NavButtons isHidden={this.state.isHidden} />
          <ActionsContainer>{children}</ActionsContainer>
          <Hamburger
            handleClick={this.toggleMenu}
            showBurger={this.state.isHidden}
          />
        </NavContainer>
      </Nav>
    );
  }
}

Navigation.defaultProps = {
  children: null
};

export default Navigation;
