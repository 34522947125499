import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

/* HACK: h2/h3 override - theme is breaking their styles */
const HeroContent = styled.div`
  max-width: ${calculateRem(720)};
  color: ${colors.white};
  width: 100%;
  h2,
  h3 {
    background: transparent;
    color: ${colors.white};
    padding: 0;
    margin: 0;
    text-overflow: ellipsis;
  }
  h2 {
    font-size: ${calculateRem(36)};
    margin-bottom: ${calculateRem(8)};
  }
  h3 {
    font-weight: 100;
    font-size: ${calculateRem(16)};
    text-decoration: underline;
  }
`;

const StyledHero = styled.section`
  background: ${colors.purple};
  padding-top: ${calculateRem(36)};
  padding-bottom: ${calculateRem(36)};
  padding-left: ${calculateRem(24)};
  padding-right: ${calculateRem(24)};
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-content: center;
`;

const Hero = ({ children }) => (
  <StyledHero>
    <HeroContent>{children}</HeroContent>
  </StyledHero>
);

export default Hero;
