import React from 'react';
import styled from 'styled-components';

import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';
import SelectableListItem from '../SelectableListItem';

const MultiSelectContainer = styled.div`
  width: ${calculateRem(306)};
  border-radius: 6px;
  border: 1px solid ${colors.darkPurple};
  margin-top: ${calculateRem(10)};
  margin-bottom: ${calculateRem(10)};
  min-height: 400px;
  background-color: #ffffff;
  overflow: hidden;
`;

const EmptyContainer = styled.div`
  align-items: center;
`;

const ItemContainer = styled.div`
  height: 45px;
`;

const makeLightPurple = {
  backgroundColor: `${colors.lightPurple}`
};

const makeWhite = {
  backgroundColor: '#fff'
};

const EmptyListItem = props => (
  <ItemContainer style={props.evenItem === 0 ? makeLightPurple : makeWhite} />
);

export const EmptyList = () => {
  const RowsContainer = [];
  for (let i = 0; i < 9; i += 1) {
    RowsContainer.push(
      <div key={i}>
        <EmptyListItem evenItem={i % 2} />
      </div>
    );
  }
  return <EmptyContainer>{RowsContainer}</EmptyContainer>;
};

EmptyListItem.defaultProps = {
  evenItem: 0
};

const MultiSelectList = props => (
  <MultiSelectContainer>
    {props.listItems &&
      props.listItems.map((item, i) => (
        <SelectableListItem
          key={item.label || ''}
          item={item || null}
          evenItem={i % 2}
          listItemIcon={props.listItemIcon || null}
          handleClick={props.handleClick}
          stateName={props.stateName || ''}
        />
      ))}
    {(!props.listItems || props.listItems.length < 1) && <EmptyList />}
  </MultiSelectContainer>
);

MultiSelectList.defaultProps = {
  handleClick: null,
  listItemIcon: null,
  stateName: '',
  listItems: []
};

export default MultiSelectList;
