import React from 'react';
import styled from 'styled-components';

import { default as StyledLink, ButtonLink } from '../../../components/Link'; // eslint-disable-line
import Section from '../../../components/Section';
import Hero from '../../../components/Hero';
import { BetaHeading } from '../../../components/Typography';
import { Centered } from '../../../components/Layout';
import mediaQueries from '../../../styles/mediaQueries';
import calculateRem from '../../../styles/calculateRem';
import { QuoteSuccessPixel } from '../../../components/Trackers';
import Button from '../../../components/Button';
import SuccessMessage from '../../../components/SuccessMessage';
import { Query } from '../../../state/types';

const ContentSection = styled(Section)`
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  padding-top: ${calculateRem(16)};
`;

const ButtonWrapper = styled.div`
  padding-top: ${calculateRem(24)};
  display: flex;
  justify-content: space-between;
`;

const WideLink = styled(Button)`
  width: 350px;
  ${mediaQueries.mobile`
    width: 100%;
    margin-right: 0;
    padding: 0;
  `};
`;

const MessageSection = styled(Centered)`
  max-width: ${calculateRem(720)};
  margin: ${calculateRem(16)} auto;
  padding: 0 ${calculateRem(16)};
  text-align: left;
  display: flex;
  align-items: center;
`;

const RightAligned = styled.div`
  margin: 0;
  text-align: right;
`;

const BackToHome = styled(StyledLink)`
  background: transparent;
  color: white;
`;

interface QuoteSuccessProps {
  query: Query,
};

const QuoteSuccess: React.FC<QuoteSuccessProps> = props => {
  const { query } = props;

  let search = '';
  if (!!query && query.query) {
    search += `&query=${query.query}`;
  }
  if (!!query && query.filters) {
    search += '&filters=';
    search += encodeURIComponent(query.filters as string);
  }

  return (
    <div>
      <QuoteSuccessPixel />
      <Hero>
        <RightAligned>
          <BackToHome
            to={{
              pathname: '/'
            }}
          >
            back to home
          </BackToHome>
        </RightAligned>
        <BetaHeading>Thank you for your request</BetaHeading>
      </Hero>
      <MessageSection>
        <SuccessMessage message="Your request and details has been received." />
      </MessageSection>
      <ContentSection>
        <p>
          We will request information and pricing from the service provider/s on
          your behalf.
          <br/>
          <br/>
          In case further information is required, we will be in touch very soon (generally same day).
        </p>
        <ButtonWrapper>
          <ButtonLink
            to={{
              pathname: '/providers/',
              search
            }}
          >
            <WideLink>Back to search</WideLink>
          </ButtonLink>
        </ButtonWrapper>
      </ContentSection>
    </div>
  );
}

export default QuoteSuccess;
