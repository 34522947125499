import React from 'react';
import styled from 'styled-components';

import ValidationError from '../ValidationError';
import calculateRem from '../../styles/calculateRem';
import { colors } from '../../styles/constants';

const Textarea = styled.textarea`
  background-color: white;
  border-radius: 6px;
  border: 1px solid ${colors.darkPurple};
  box-sizing: border-box;
  color: ${colors.darkPurple};
  font-size: ${calculateRem(18)};
  line-height: ${calculateRem(36)};
  padding: ${calculateRem(12)};
  width: 100%;

  &:disabled {
    pointer-events: none;
  }

  ${props => ({ ...props.theme })};
`;

export default (
  { className, input, ...props } // eslint-disable-line
) => (
  <div className={className}>
    <ValidationError {...props} />
    <Textarea {...props} {...input} />
  </div>
);
