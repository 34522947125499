import React from 'react';

import cross from './cross-white.png';
import { TagContainer, TagContent, SpanIcon } from './styles';

const Icon = <img src={cross} alt="white cross icon" />;

interface TagProps {
  label: string | null,
  handleRemove?: Function | null,
  item?: any,  // TODO: I wonder what this is?
  stateName?: string,
};

const Tag: React.FC<TagProps> = props => {
  const { label, handleRemove, item, stateName, ...restProps } = props;

  function handleDeleteItem() {
    if (handleRemove) {
      handleRemove(props.item, props.stateName)
    }
  }

  const isRemoveable = handleRemove !== null;

  return (
    <TagContainer>
      <TagContent {...restProps}>
        {label}
      </TagContent>
      <SpanIcon
        onClick={() => handleDeleteItem()}
        isRemoveable={isRemoveable}
        {...restProps}
      >
        {Icon}
      </SpanIcon>
    </TagContainer>
  );
}

Tag.defaultProps = {
  handleRemove: null,
};

export default Tag;

interface CareTypeTagProps extends Omit<TagProps, 'label'> {
  careType: string,
};

export const CareTypeTag: React.FC<CareTypeTagProps> = props => {
  const { careType, ...restProps } = props;
  return <Tag {...restProps} label={applyCareType(careType)} />;
}

export const FundingTypeTag: React.FC<TagProps> = props => {
  return <Tag {...props} />;
}

export const applyCareType = (careType: string): string | null => {
  if (careType.toLowerCase().trim() === 'd') {
    return 'Aged 0-65';
  } else if (careType.toLowerCase().trim() === 'a') {
    return 'Aged 65+';
  }
  return null;
};
