import React from 'react';

import Button from '../../../../components/Button';

import {
    StyledLink,
    ButtonSection,
    ButtonBetaText,
    ButtonPlacementSection,

  } from '../../style';


const handleClick = () => {
    // Scroll to the top of the page
    window.scrollTo(0, 0);
  };
  
  const SupportButton = () => (
    <ButtonPlacementSection>
      <ButtonSection>
        <Button>
          <StyledLink onClick={handleClick} to="/">
            <ButtonBetaText>Search for supports now</ButtonBetaText>
          </StyledLink>
        </Button>
      </ButtonSection>
    </ButtonPlacementSection>
  );
  
  export default SupportButton;