import { combineReducers } from 'redux';
import { reducer as form } from 'redux-form';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';

import karistaApi from '../services/karista';
import providers, { ProvidersState } from '../apps/Providers/providersSlice';
import admin, { AdminState } from '../apps/Admin/adminSlice';

const formPersistConfig = {
  key: 'form',
  storage,
  whitelist: ['basic_search', 'advanced_search'],
  debug: true
};

export interface RootState {
  providers: ProvidersState,
  admin: AdminState,
};

export default combineReducers({
  [karistaApi.reducerPath]: karistaApi.reducer,
  form: persistReducer(formPersistConfig, form as any),  // TODO: What's up with needing this `any`?
  providers,
  admin
});
