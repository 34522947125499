import React from 'react';

import Theme from '../../../../components/Theme';
import { themeSecondary } from '../../../../styles/constants';
import Breakpoint from '../../../../components/Breakpoint';
import IconClientServiceCircle from "./icon-client-service-circle.svg"

import {
    CallUsHeaderSection,
    CallUsHeading,
    CallUsPhoneNumber,
    CallUsSection,
    ClientServiceCircleIcon
} from "../../style";



const CallUs = () => {
    return (
        <Theme theme={themeSecondary}>
            <CallUsHeaderSection> 
                <ClientServiceCircleIcon>
                    <img src={IconClientServiceCircle} alt={'Karista Client Service'} />
                </ClientServiceCircleIcon>
                <CallUsSection>
                    <CallUsPhoneNumber><b>0485 972 676</b></CallUsPhoneNumber>
                    <Breakpoint>
                    {breakpoint => (
                        <CallUsHeading>
                            {
                                breakpoint === 'desktop'
                                ? (
                                <>
                                    <b>Call us Monday to Friday</b> <br />
                                    <b>9am to 5pm AEST</b>
                                </>
                                ) : (
                                <>
                                    <b>Call us</b>  <br />
                                    <b>Monday to Friday</b> <br />
                                    <b>9am to 5pm AEST</b>
                                </>
                                )
                            }
                        </CallUsHeading>
                        )}
                    </Breakpoint>
                </CallUsSection>
            </CallUsHeaderSection>
        </Theme>
    );
};

export default CallUs;