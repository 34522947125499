import React from 'react';
import { Field, reduxForm, InjectedFormProps } from 'redux-form';

import { BetaHeading } from '../../../../components/Typography';
import Hero from '../../../../components/Hero';
import {WhiteLink} from '../../../../components/Link';
import Label from '../../../../components/Label';
import Button from '../../../../components/Button';
import Input from '../../../../components/Input';

import FormItem from '../../../../components/FormItem';

import ValidationError from '../../../../components/ValidationError';

import {
  ResetPasswordFormContainer,
  ResetPasswordFormHeader,
  SuccessContainer,
  BackToSearchButton,
  ResetPasswordFormOuterContainer
} from './styles';
import SuccessMessage from '../../../../components/SuccessMessage/index'

export enum SubmitState {
  Unsubmitted,
  Submitting,
  Success,
  Failure
}

interface ResetPasswordConfirmFormProps {
  onSubmit: (...args: any[]) => any,
  submitState: SubmitState,
  responseErrors: string[]
}

const ResetPasswordConfirmForm: React.FC<InjectedFormProps<{}, ResetPasswordConfirmFormProps> & ResetPasswordConfirmFormProps> = ({ handleSubmit, onSubmit, submitState, responseErrors }) => {
  const isSubmitting = submitState === SubmitState.Submitting;
  const isSuccess = submitState === SubmitState.Success;
  const isFailure = submitState === SubmitState.Failure;

  const errors = responseErrors.map(err => <ValidationError meta={{submitFailed: isFailure, error: err}}/>);

  const form = (
      <ResetPasswordFormContainer>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormItem>
            <Label htmlFor="new_password1">New password</Label>
            <Field component={Input} type="password" name="new_password1" />
          </FormItem>
          <FormItem>
            {errors}
          </FormItem>
          <FormItem>
            <Label htmlFor="new_password2">New password (confirm)</Label>
            <Field component={Input} type="password" name="new_password2" />
          </FormItem>
          <Button disabled={isSubmitting} fullWidth>Reset password</Button>
        </form>
      </ResetPasswordFormContainer>
  );

  const successMessage = (
      <SuccessContainer>
        <SuccessMessage message="You have successfully set your new password." />
        <br />
        <BackToSearchButton
          to={{pathname: '/admin/login'}}
        >
          Back to login
        </BackToSearchButton>
      </SuccessContainer>
  )
  let body;
  if (isSuccess)
  {
    body = successMessage;
  }
  else
  {
    body = form;
  }

  return (
    <div>
      <Hero>
        <ResetPasswordFormHeader>
          <BetaHeading>Reset password</BetaHeading>
          <WhiteLink to="/admin/login" caretLeft>
            back
          </WhiteLink>
        </ResetPasswordFormHeader>
      </Hero>
      <ResetPasswordFormOuterContainer>
        {body}
      </ResetPasswordFormOuterContainer>
    </div>
  );
};

ResetPasswordConfirmForm.defaultProps = {
  submitState: SubmitState.Unsubmitted
};



interface ResetPasswordConfirmFormSubmissionData {
    new_password1: string, // eslint-disable-line
    new_password2: string // eslint-disable-line
}

export const validate = (values: ResetPasswordConfirmFormSubmissionData) => {
  const errors: any = {};
  if (!(values.new_password1 === values.new_password2)) {
    errors.new_password2 = 'Passwords must match';
  }
  return errors;
};

const formConfig = {
  fields: ['email'],
  form: 'admin-password-reset-confirm',
  validate
};

export default reduxForm<{}, ResetPasswordConfirmFormProps>(formConfig)(ResetPasswordConfirmForm);
